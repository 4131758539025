import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import {ControlledForm, PromiseButton, LinkButton} from 'components'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import LaybuyLogo from "./LaybuyLogo";

export class IdentityStep extends Component{
  state = {
    windowHeight: window.innerHeight
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowHeight);
  }

  updateWindowHeight = () => {
    this.setState({ windowHeight: window.innerHeight });
  }


  renderContent() {
    const {title, children, onGoBack, classes} = this.props
    return (
      <CardContent className={classes.cardContent}>
        <Box className={classes.logo}>
          <LaybuyLogo style={{ margin: 0 }} />
          <Typography variant="h6" className={classes.logoTitle}>
            IDENTITY VERIFICATION
          </Typography>
        </Box>
        <Box className={classes.stepHeader}>
          {onGoBack &&
          <LinkButton classes={{root: classes.backButton}} onClick={onGoBack}>&lt; Back</LinkButton>
          }
          <Typography variant='h4'>{title}</Typography>
        </Box>
        {children}
      </CardContent>
    )
  }

  renderActions() {
    const {classes, saveButtonText, formData, onSave, saveButtonDisabled} = this.props
    const handleClick = formData ? null : onSave

    return (
      <CardActions className={classes.cardActions}>
        <Box className={classes.cardActionsWrapper}>
          <PromiseButton
            color='secondary'
            variant='contained'
            type={formData ? 'submit' : null}
            className={classes.button}
            onClick={handleClick}
            disabled={Boolean(saveButtonDisabled)}
          >
            {saveButtonText || 'Next'} →
          </PromiseButton>
        </Box>
      </CardActions>
    )
  }

  render = () => {
    const {formData, errors, onFormDataChange, onSave, classes} = this.props
    return (
      <Card style={{ display: 'flex', flexDirection: 'column' }}>
        {formData
          ? <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave} className={classes.contentWrapper} style={{ minHeight: this.state.windowHeight}}>
            {this.renderContent()}
            {this.renderActions()}
          </ControlledForm>
          : <Box className={classes.contentWrapper} style={{ minHeight: this.state.windowHeight }}>
            {this.renderContent()}
            {this.renderActions()}
          </Box>
        }
      </Card>
    )
  }
}

const styles = {
  cardContent: {
    padding: '30px 25px 0 25px',
    margin: 0,
    flex: '1 0 auto',
    '@media(min-width: 600px)': {
      padding: '30px 50px 0 50px',
      margin: 'auto',
    }
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'all .2s ease-in-out',
  },
  cardActions: {
    padding: '35px 50px 25px 25px',
    backgroundColor: '#e0e7ef',
    marginTop: 30,
    flexShrink: 0,
    '@media(min-width: 600px)': {
      paddingLeft: 50
    }
  },
  cardActionsWrapper: {
    width: '100%',
    maxWidth: 900,
    margin: 'auto'
  },
  button: {
    borderRadius: 25,
    padding: '8px 36px 6px 35px',
  },
  backButton: {
    marginBottom: 10,
    textDecoration: 'unset'
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 20px 20px',
    '@media(min-width: 600px)': {
    }
  },
  logoTitle: {
    fontSize: '1.1rem',
  },
  stepHeader: {
    marginBottom: 45,
    fontWeight: 'bold'
  }
}

export default compose(
  withStyles(styles),
)(IdentityStep)