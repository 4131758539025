import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import {PageContainer} from 'components'
import { provide, UsersContext } from 'contexts'
import dependsOn from 'containers/shared/dependsOn'

export class Show extends Component{

  static fetchDependencies({users, match: { path, params: {id}}}){
    return users.actions.show(id, {include: 'author'})
  }

  get id(){
    return this.props.match.params.id
  }

  get user(){
    return this.props.users.selected
  }

  render = () =>
    <PageContainer>
      <Typography variant='h4'>User - {this.user.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/users/${this.user.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </PageContainer>
}

const fetchDependencies = ({users, match: { path, params: {id}}}) => {
  return users.actions.show(id, {include: 'author'})
}

export default provide(UsersContext)(dependsOn(fetchDependencies)(Show))