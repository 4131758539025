import React, {Component, useState} from 'react'
import withStyles from 'styles'
import { compose, errorStringsFromError, Authorization, humanize } from 'utils'
import { LinkButton, ClosableDialogButton, PromiseButton, ConfirmationDialog } from "components";
import TextField from "@material-ui/core/TextField";
import WarningIcon from '@material-ui/icons/Warning';
import ReplayIcon from '@material-ui/icons/Replay';
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as API from 'api'
import { consume, provide, SnackbarContext, BeneficialOwnersContext } from "contexts";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import BeneficialOwnerOnfidoDetailsDialog from "./BeneficialOwnerOnfidoDetailsDialog";
import BeneficialOwnerRapidIdentityDetailsDialog from "./BeneficialOwnerRapidIdentityDetailsDialog";
import BeneficialOwnerRapidDocumentDetailsDialog from "./BeneficialOwnerRapidDocumentDetailsDialog";
import BeneficialOwnerRapidAmlDetailsDialog from "./BeneficialOwnerRapidAmlDetailsDialog";


export class BeneficialOwnerFailInfoDialog extends Component {
  state = {
    email: this.props.beneficialOwner.email,
    open: false,
    confirmationOpen: false,
    overrideReason: "",
  }

  get name() {
    const beneficialOwner = this.beneficialOwner
    return `${beneficialOwner.firstName} ${beneficialOwner.lastName}`
  }

  get beneficialOwner() {
    return this.props.beneficialOwner
  }

  get isVerified() {
    return this.beneficialOwner.status === "done"
  }

  handleResend = async () => {
    try {
      await API.BeneficialOwners.resendInvite({
        id: this.props.beneficialOwner.id,
        email: this.state.email
      })
      this.setState({open: false})
      this.props.snackbar.actions.show(`Invite sent to ${this.name}`)
    } catch (error) {
      this.props.snackbar.actions.show(errorStringsFromError(error).join(', '))
    }
  }

  handleOverrideStatus = async (overrideReason) => {
    try {
      await this.props.beneficialOwners.actions.event({
        id: this.props.beneficialOwner.id,
        name: "override",
        overrideReason
      })
      this.props.onStatusChange()
      this.setState({open: false})
      this.setState({confirmationOpen: false})
      this.props.snackbar.actions.show(`${this.name} marked as verified`)
    } catch (error) {
      this.props.snackbar.actions.show(errorStringsFromError(error).join(', '))
    }
  }

  handleRestartFromScratch = async (comment) => {
    try {
      await API.BeneficialOwners.event({
        id: this.props.beneficialOwner.id,
        name: "restart",
        comment
      })
      this.props.onStatusChange()
      this.setState({open: false})
      this.props.snackbar.actions.show(`Verification for ${this.name} was restarted`)
    } catch (error) {
      this.props.snackbar.actions.show(errorStringsFromError(error).join(', '))
    }
  }

  handleStartRapidID = async (comment) => {
    try {
      await API.BeneficialOwners.event({
        id: this.props.beneficialOwner.id,
        name: "start_with_rapid_id",
        comment
      })
      this.props.onStatusChange()
      this.setState({open: false})
      this.props.snackbar.actions.show(`RapidID check was initiated for ${this.name}`)
    } catch (error) {
      this.props.snackbar.actions.show(errorStringsFromError(error).join(', '))
    }
  }

  handleFormChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  get overriddenBy() {
    return this.beneficialOwner.overriddenBy || {}
  }

  render = () => {
    const {classes, buttonTitle} = this.props

    return (
      <ClosableDialogButton title={this.name}
                            buttonTitle={buttonTitle || (this.isVerified ? 'Details' : 'Manage')}
                            buttonComponent={LinkButton}
                            classes={{paper: classes.paper}}
                            open={this.state.open}
                            onOpen={() => this.setState({open: true})}
                            onClose={() => this.setState({open: false})}>
        <Grid container item md={12} spacing={1} justify="flex-start" alignItems="center">
          {["invited", "in_progress"].includes(this.props.beneficialOwner.status) &&
            <>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  name='email'
                  className={classes.emailField}
                  label="Email"
                  value={this.state.email}
                  onChange={this.handleFormChange}
                />
              </Grid>
              <Grid item md={4}>
              <PromiseButton className={classes.sendButtton}
                             color='secondary'
                             variant='contained'
                             onClick={this.handleResend}
                             disabled={!this.state.email}>
                Resend Invite →
              </PromiseButton>
              </Grid>
            </>
          }
          {this.props.beneficialOwner.status === "failed" && Authorization.typeUser &&
            <RestartMenu className={classes.sendButtton}
                         onRestart={this.handleRestartFromScratch}
                         onStartOnfido={this.handleStartRapidID} />
          }
          {["failed", "partially_verified"].includes(this.props.beneficialOwner.status) && Authorization.typeUser &&
            <Grid item md={4}>
              <ConfirmWithReason title="Mark verified"
                                 prompt="Please provide a reason for changing status"
                                 fieldLabel="Reason"
                                 onSubmit={this.handleOverrideStatus}
                                 buttonComponent={(props) => (
                                   <Tooltip title="Manually change status to 'verified'">
                                     <Button className={classes.sendButtton}
                                             color='primary'
                                             variant='contained'
                                             endIcon={<WarningIcon />}
                                             onClick={props.onClick}>
                                       Mark verified
                                     </Button>
                                   </Tooltip>
                                 )} />
            </Grid>
          }
        </Grid>
        {["failed", "done", "partially_verified"].includes(this.beneficialOwner.status) && Authorization.typeUser &&
          <Box className={classes.results}>
            {!this.isVerified &&
              <Divider />
            }
            {this.beneficialOwner.status === "done" && this.beneficialOwner.overrideReason &&
              <>
                <Typography variant="subtitle1">
                  Manual override:
                </Typography>
                <Typography variant="subtitle2" color="secondary">
                  Overridden by: {this.overriddenBy.name}; reason: {this.beneficialOwner.overrideReason}
                </Typography>
              </>
            }
            <Typography variant="subtitle1" className={classes.resultsTitle}>
              Check results:
            </Typography>
            {this.beneficialOwner.onfidoCheck && Authorization.typeUser &&
              <OnfidoCheckPreview beneficialOwner={this.beneficialOwner} />
            }
            {this.beneficialOwner.rapidIdentityResponse && Authorization.typeUser &&
              <RapidIdentityCheckPreview beneficialOwner={this.beneficialOwner} />
            }
            {this.beneficialOwner.rapidDocumentResponse && Authorization.typeUser &&
              <RapidDocumentCheckPreview beneficialOwner={this.beneficialOwner} />
            }
            {this.beneficialOwner.rapidAmlResponse && Authorization.typeUser &&
              <RapidAMLCheckPreview beneficialOwner={this.beneficialOwner} />
            }
            {this.beneficialOwner.onfidoError &&
              <>
                <Typography variant="subtitle2" gutterBottom color="error">
                  Onfido check failed: {this.beneficialOwner.onfidoError.message}
                </Typography>
                {this.beneficialOwner.onfidoError.fields && Object.keys(this.beneficialOwner.onfidoError.fields).length > 0 &&
                  <Typography variant="subtitle2" gutterBottom color="error">
                    Details: {JSON.stringify(this.beneficialOwner.onfidoError.fields, null, 2)}
                  </Typography>
                }
              </>
            }
          </Box>
        }
        {this.beneficialOwner.restartComment &&
          <Typography variant="subtitle2" gutterBottom color="primary">
            Verification restart comment: {this.beneficialOwner.restartComment}
          </Typography>
        }
      </ClosableDialogButton>
    )
  }
}


function OnfidoCheckPreview({ beneficialOwner }) {
  const { onfidoCheck } = beneficialOwner

  let statusText
  if (["withdrawn", "reopened"].includes(onfidoCheck.status) || onfidoCheck.result === "consider") {
   statusText = (
      <Typography variant="subtitle2" gutterBottom color="error">
        Onfido check: {humanize(onfidoCheck.status)}, Result: {humanize(onfidoCheck.result)}
      </Typography>
    )
  } else {
    statusText = (
      <Typography variant="subtitle2" gutterBottom color="secondary">
        Onfido check: {humanize(onfidoCheck.status)}, Result: {humanize(onfidoCheck.result)}
      </Typography>
    )
  }

  return (
    <Box display="flex" alignItems="baseline">
      {statusText}
      <BeneficialOwnerOnfidoDetailsDialog beneficialOwner={beneficialOwner} style={{ marginLeft: 10 }} />
    </Box>
  )
}

const RapidIdentityCheckPreview = ({ beneficialOwner }) =>
  <Box display="flex" alignItems="baseline">
    <Typography variant="subtitle2" gutterBottom color={beneficialOwner.rapidIdentityResponse.verified ? "secondary" : "error"}>
      RapidID Global ID Verification (eidv/2+2): {beneficialOwner.rapidIdentityResponse.verified ? "Pass" : "Fail"}
    </Typography>
    <BeneficialOwnerRapidIdentityDetailsDialog beneficialOwner={beneficialOwner} style={{ marginLeft: 10 }} />
  </Box>

const rapidTypes = {
  'Rapid::AULicenceResponse': 'RapidID AU Drivers Licence Verification',
  'Rapid::NZLicenceResponse': 'RapidID NZ Drivers Licence Verification',
  'Rapid::AUPassportResponse': 'RapidID AU Passport Verification',
  'Rapid::NZPassportResponse': 'RapidID NZ Passport Verification',
}
const RapidDocumentCheckPreview = ({ beneficialOwner }) => {
  const { rapidDocumentResponse } = beneficialOwner
  return (
    <Box display="flex" alignItems="baseline">
      <Typography variant="subtitle2" gutterBottom color={rapidDocumentResponse.verified ? "secondary" : "error"}>
        {rapidTypes[rapidDocumentResponse.type]}: {rapidDocumentResponse.verified ? "Pass" : "Fail"}
      </Typography>
      <BeneficialOwnerRapidDocumentDetailsDialog beneficialOwner={beneficialOwner} style={{ marginLeft: 10 }} />
    </Box>
  )
}

const RapidAMLCheckPreview = ({ beneficialOwner }) => {
  const { rapidAmlResponse } = beneficialOwner
  return (
    <Box display="flex" alignItems="baseline">
      <Typography variant="subtitle2" gutterBottom color={rapidAmlResponse.verified ? "secondary" : "error"}>
        RapidID OFAC & PEP Check: {rapidAmlResponse.verified ? "Pass" : "Fail"}
      </Typography>
      <BeneficialOwnerRapidAmlDetailsDialog beneficialOwner={beneficialOwner} style={{ marginLeft: 10 }} />
    </Box>
  )
}

function RestartMenu({ className, onRestart, onStartOnfido }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [comment, setComment] = React.useState("")
  const [selectedConfirmation, setSelectedConfirmation] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = confirmationType => () => {
    handleClose()
    setSelectedConfirmation(confirmationType)
  }

  const handleSubmit = () => {
    selectedConfirmation === "restart" ? onRestart(comment) : onStartOnfido(comment)
    setSelectedConfirmation(null)
  }

  return (
    <>
      <Button color='secondary'
              variant='contained'
              endIcon={<ReplayIcon />}
              onClick={handleClick}
              className={className}>
        Restart Verification
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelect("restart")}>Restart verification from scratch</MenuItem>
        <MenuItem onClick={handleSelect("start_onfido")}>Start with RapidID check</MenuItem>
      </Menu>
      <ConfirmationDialog
        title="Restart verification"
        open={Boolean(selectedConfirmation)}
        onConfirm={handleSubmit}
        onCancel={() => setSelectedConfirmation(null)}
        confirmLabel={'Change'}
        cancelLabel={'Cancel'}
        canConfirm={Boolean(comment)}
      >
        <Typography variant="body1">Please provide a comment{selectedConfirmation === "restart" && <> for beneficial owner</>}:</Typography>
        <TextField fullWidth label="Comment" value={comment} onChange={e => setComment(e.target.value)} />
      </ConfirmationDialog>
    </>
  )
}

const ConfirmWithReason = (props) => {
  const [open, setOpen] = useState(false)
  const [reason, setReason] = useState("")
  const { buttonComponent: ButtonComponent, prompt, fieldLabel, title } = props

  return (
    <>
      <ButtonComponent onClick={() => setOpen(true)} />
      <ConfirmationDialog
        title={title}
        open={open}
        onConfirm={() => {
          props.onSubmit(reason)
          setOpen(false)
        }}
        onCancel={() => setOpen(false)}
        confirmLabel={'Change'}
        cancelLabel={'Cancel'}
        canConfirm={Boolean(reason)}
      >
        <Typography variant="body1">{prompt}:</Typography>
        <TextField fullWidth label={fieldLabel} value={reason} onChange={e => setReason(e.target.value)} />
      </ConfirmationDialog>
    </>
  )
}


const styles = theme => ({
  paragraph: {
    marginBottom: 15
  },
  sendButtton: {
    borderRadius: 25,
    padding: '8px 36px 6px 35px',
    maxHeight: 38
  },
  emailField: {
    maxWidth: 250,
    marginRight: 15,
  },
  paper: {
    maxWidth: 700
  },
  results: {
    marginTop: 15
  },
  resultsTitle: {
    marginTop: 15
  }
})

export default compose(
  withStyles(styles),
  consume(SnackbarContext),
  provide(BeneficialOwnersContext)
)(BeneficialOwnerFailInfoDialog)