import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, VerificationsContext } from 'contexts'
import {VerificationStep} from 'components'
import Divider from '@material-ui/core/Divider';
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid';
import { humanize } from 'utils'
import * as API from 'api'
import PrivacyPolicy from "containers/shared/PrivacyPolicy"

export class Organisation extends Component{
  state = {
    privacyPolicyAccepted: null
  }

  get verification() {
    return this.props.verifications.selected || {}
  }

  get beneficialOwners() {
    return this.verification.beneficialOwners || []
  }

  get verificationUser() {
    return this.verification.verificationUser || {}
  }

  ownershipInfo(beneficialOwner) {
    const statusName = humanize(beneficialOwner.ownershipStatus)
    if (beneficialOwner.holdings) {
      return `${statusName} (${beneficialOwner.holdings}%)`
    }

    return statusName
  }

  handleSubmit = async () => {
    const verification = this.props.verifications.selected
    if (verification.status === "in_progress") {
      await API.Verifications.event({
        name: 'submit_for_business_structure_review',
        id: verification.id
      })
    }
    this.props.history.push(this.nextStep)
  }

  get prevStep() {
    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verification-step/beneficial-owners/${verificationId}`
    }

    return "/verification-step/beneficial-owners"
  }

  get nextStep() {
    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verifications/status/${verificationId}`
    }

    return "/verification-step/status"
  }

  get privacyPolicyAlreadyAccepted() {
    return this.verificationUser.privacyPolicyAccepted || false
  }

  get privacyPolicyAccepted() {
    if (this.state.privacyPolicyAccepted !== null) {
      return this.state.privacyPolicyAccepted
    }

    return this.privacyPolicyAlreadyAccepted
  }

  render = () => {
    const {classes} = this.props
    return (
      <VerificationStep
        title='Verify Identity'
        onGoBack={() => this.props.history.push(this.prevStep)}
        saveButtonText="Submit for review"
        onSave={this.handleSubmit}
        saveButtonDisabled={!this.privacyPolicyAccepted}
      >
        <Box className={this.props.classes.body}>
          <Typography variant='body1' className={classes.paragraph}>Once our team has reviewed this information, we’ll send an email inviting each beneficial owner to complete an online identity check.</Typography>
          <Typography variant='body1' className={classes.paragraph}>They’ll need either their Driver’s License or Passport. They can complete the check at any time, the whole process should take them about 5 minutes.</Typography>
          <Typography variant='body1' className={classes.paragraph}>Everyone needs to complete their check before we can activate your merchant account.</Typography>
        </Box>
        <Divider />
        <Grid container spacing={1} className={classes.preview}>
          {this.beneficialOwners.map((beneficialOwner, idx) => (
            <Grid container item md={12} spacing={2} key={idx}>
              <Grid item md={3}>
                {`${beneficialOwner.firstName} ${beneficialOwner.lastName}`}
              </Grid>
              <Grid item md={3}>
                {beneficialOwner.email}
              </Grid>
              <Grid item md={3}>
                {this.ownershipInfo(beneficialOwner)}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <PrivacyPolicy onChange={value => this.setState({ privacyPolicyAccepted: value })}
                       checked={this.privacyPolicyAccepted}
                       style={{ maxWidth: 548 }} />
      </VerificationStep>
    )
  }
}

const styles = {
  body: {
    marginTop: 30,
    maxWidth: 548
  },
  paragraph: {
    marginBottom: 20
  },
  preview: {
    marginTop: 25,
    marginBottom: 50
  }
}

const fetchDependencies = async ({verifications, match: { params: { verificationId }}}) => {
  if (verificationId) {
    return verifications.actions.show(verificationId, {include: 'beneficialOwners,verificationUser'})
  } else {
    return verifications.actions.current({include: 'beneficialOwners,verificationUser'})
  }
}

export default compose(
  dependsOn(fetchDependencies),
  withStyles(styles),
  provide(VerificationsContext),
)(Organisation)