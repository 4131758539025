import React, { Component } from 'react'
import * as OnfidoSDK from 'onfido-sdk-ui'
import dependsOn from 'containers/shared/dependsOn'
import { compose } from 'utils'
import { provide, BeneficialOwnersContext, OnfidoChecksContext } from 'contexts'
import Redirect from "./Redirect";
import * as API from 'api'

export class Onfido extends Component {
  get beneficialOwner() {
    return this.props.beneficialOwners.selected
  }

  showSuccessPage = () => {
    this.props.history.push('/verify-identity/thank-you')
  }

  showFailurePage = () => {
    this.props.history.push('/verify-identity/failure')
  }

  get status() {
    return this.beneficialOwner.status
  }

  showResultPage = () => {
    if (this.props.onfidoChecks.current && (this.props.onfidoChecks.current.status === "ok" || this.status === "in_progress") && this.status !== 'failed') {
      this.showSuccessPage()
    } else {
      this.showFailurePage()
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.dependenciesMet && this.props.dependenciesMet) {

      if (!this.props.onfidoChecks.init) {
        // The check already exists or we could not obtain onfido token
        // Show result to the beneficial owner
        this.showResultPage()
        return
      }

      const { documentType, onfidoToken } = this.props.onfidoChecks.init
      this.onfido = OnfidoSDK.init({
        token: onfidoToken,
        containerId: 'onfido-mount',
        steps: [
          {
            type: "document",
            options: {
              documentTypes: {
                passport: documentType === "passport" || !documentType,
                driving_licence: documentType === "drivers_licence" || !documentType,
                national_identity_card: false,
                // configuration: (see: https://github.com/onfido/onfido-sdk-ui#customising-the-sdk)
                // default: false
                forceCrossDevice: process.env.REACT_APP_ONFIDO_DOCUMENT_FORCE_CROSS_DEVICE === "true",
                // default: false
                useLiveDocumentCapture: process.env.REACT_APP_ONFIDO_DOCUMENT_LIVE_DOCUMENT_CAPTURE === "true",
                // default: true (Only available when useLiveDocumentCapture is enabled)
                uploadFallback: process.env.REACT_APP_ONFIDO_DOCUMENT_LIVE_DOCUMENT_CAPTURE === "true" && process.env.REACT_APP_ONFIDO_DOCUMENT_UPLOAD_FALLBACK !== "false"
              }
            }
          },
          {
            type: "face",
            options: {
              // configuration: (see: https://github.com/onfido/onfido-sdk-ui#customising-the-sdk)
              // default: video
              requestedVariant: process.env.REACT_APP_ONFIDO_FACE_REQUESTED_VARIANT || "video",
              // default: true
              uploadFallback: process.env.REACT_APP_ONFIDO_FACE_UPLOAD_FALLBACK !== "false",
              // default: false
              useMultipleSelfieCapture: process.env.REACT_APP_ONFIDO_FACE_MULTIPLE_SELFIE_CAPTURE === "true"
            }
          }
        ],
        onComplete: async (data) => {
          const { face: { variant: faceVariant } = {} } = data
          const faceReport = faceVariant === "video" ? "facial_similarity_video" : "facial_similarity_photo"
          try {
            await API.OnfidoChecks.create({ faceReport })
            this.showSuccessPage()
          } catch (e) {
            console.error("Onfido check failed:", e)
            this.showFailurePage()
          }
        },
        onError: async (error) => {
          console.error("Onfido Web SDK upload failed:", error)
          await API.BeneficialOwners.event({
            id: this.beneficialOwner.id,
            name: 'fail',
            onfidoError: error
          })
          this.showFailurePage()
        }
      })
    }
  }

  componentWillUnmount() {
    if (this.onfido) {
      this.onfido.tearDown()
    }
  }

  render() {
    if (!this.props.dependenciesMet) {
      return <Redirect />
    }

    return (
      <div id="onfido-mount" />
    );
  }
}

const fetchDependencies = async ({ beneficialOwners, onfidoChecks, history }) => {
  await beneficialOwners.actions.current()
  try {
    await onfidoChecks.actions.init()
  } catch (e) {
    if (e.status === 409 || e.code === "ERR_UNAUTHORIZED") {
      try {
        await onfidoChecks.actions.current()
      } catch (e) {
        if (e.status !== 404) {
          throw e
        }
      }
    }
  }
}

export default compose(
  dependsOn(fetchDependencies),
  provide(BeneficialOwnersContext, OnfidoChecksContext),
)(Onfido)