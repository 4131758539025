import React, { Component } from 'react'
import withStyles from 'styles'
import { compose } from 'utils'
import Typography from "@material-ui/core/Typography";
import { LinkButton, ClosableDialogButton } from "components";



export class BeneficialOwnerFailInfoDialog extends Component {
  get name() {
    const {beneficialOwner} = this.props
    return `${beneficialOwner.firstName} ${beneficialOwner.lastName}`
  }

  get possessiveName() {
    const {beneficialOwner} = this.props
    if (beneficialOwner.lastName.endsWith("s")) {
      return `${beneficialOwner.firstName} ${beneficialOwner.lastName}'`
    }
    return `${beneficialOwner.firstName} ${beneficialOwner.lastName}'s`
  }

  render = () => {
    const {classes} = this.props

    return (
      <ClosableDialogButton title={this.name} buttonTitle="Info" buttonComponent={LinkButton}>
        <Typography variant="body1" className={classes.paragraph}>
          We were unable to securely verify {this.possessiveName} identity.
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          A member of our team will contact you directly with next steps.
        </Typography>
      </ClosableDialogButton>
    )
  }
}


const styles = theme => ({
  paragraph: {
    marginBottom: 15
  },
})

export default compose(
  withStyles(styles),
)(BeneficialOwnerFailInfoDialog)