import React, { Component } from 'react'
import withQueryString from './withQueryString'

const indexView = resourceName => Type => withQueryString(
  class extends Component {
    get urlState(){
      return this.props.urlState[resourceName] || {}
    }

    setUrlState = (state, callback) => {
      this.props.setUrlState({[resourceName]: state}, callback)
    }

    get indexState() {
      const {page, order, ...filter} = this.urlState
      return {
        page: Number(page) || 1,
        order: order || null,
        filter: filter || {}
      }
    }

    setIndexState = (state) => {
      const {page, order, filter} = {...this.indexState, ...state}
      const updatedState = {}

      if (Number(page) > 1)
        updatedState.page = page
      if (order)
        updatedState.order = order

      Object.keys(filter || {}).forEach(key => {
        if (filter[key] || filter[key] === 0)
          updatedState[key] = filter[key]
      })

      this.setUrlState(updatedState)
    }

    handleOrderChange = (order, callback) => {
      this.setIndexState({order, page: 1}, callback)
    }

    handleFilterChange = (filter, callback) => {
      this.setIndexState({filter, page: 1}, callback)
    }

    handlePageChange = (page, callback) => {
      this.setIndexState({page}, callback)
    }

    render = () =>
      <Type
        {...this.props}
        {...this.indexState}
        onOrderChange={this.handleOrderChange}
        onFilterChange={this.handleFilterChange}
        onPageChange={this.handlePageChange}
      />
  }
)

export default indexView

export const hasIndexChanged = (props, prevProps) => {
  return props.page !== prevProps.page ||
    props.order !== prevProps.order ||
    JSON.stringify(props.filter) !== JSON.stringify(prevProps.filter)
}