import BaseContext from 'contexts/base/BaseContext'

export default class SnackbarContext extends BaseContext {

  static contextName = 'snackbar'

  static initialState = {
    ...super.initialState,
    message: '',
  }

  show = (message) => {
    this.setState({message})
  }

  clear = () => {
    this.setState({message: ''})
  }
}
