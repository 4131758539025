import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, BeneficialOwnersContext } from 'contexts'
import instanceForm from 'containers/shared/instanceForm'
import {ErrorBanner, IdentityStep} from 'components'
import TextField from '@material-ui/core/TextField'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import PrivacyPolicy from "containers/shared/PrivacyPolicy"

export class Name extends Component{
  get beneficialOwner() {
    return this.props.beneficialOwners.selected
  }

  get nextStep() {
    return "/verify-identity/date-of-birth"
  }

  handleSave = () => {
    this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      mapFormData: (data) => ({...data, currentStep: "date-of-birth", status: "in_progress"})
    })
  }

  render = () => {
    const {formData, onFormDataChange, errors, errorStrings, classes} = this.props
    return (
      <IdentityStep
        title='Confirm your name'
        errors={errors}
        formData={formData}
        onSave={this.handleSave}
        onFormDataChange={onFormDataChange}
        saveButtonDisabled={!formData.firstName || !formData.lastName || !formData.privacyPolicyAccepted}
      >
        <Box mb={2}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          {this.beneficialOwner.restartComment &&
          <>
            <Typography variant='subtitle1' className={classes.reviewFeedback} gutterBottom>
              Comment from onboarding specialist: {this.beneficialOwner.restartComment}
            </Typography>
          </>
          }
          <TextField fullWidth name='firstName'/>
          <TextField fullWidth name='lastName'/>
        </Box>
        <PrivacyPolicy formData={formData} onFormDataChange={onFormDataChange} />
      </IdentityStep>
    )
  }
}

const styles = {
  reviewFeedback: {
    color: '#ff0800'
  }
}

const fetchDependencies = ({ beneficialOwners }) => {
  return beneficialOwners.actions.current()
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('beneficialOwners'),
  withStyles(styles),
  provide(BeneficialOwnersContext),
)(Name)