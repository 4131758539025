import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, BeneficialOwnersContext } from 'contexts'
import instanceForm from 'containers/shared/instanceForm'
import {ErrorBanner, IdentityStep, DatePickerSelect} from 'components'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'

export class DateOfBirth extends Component{

  get beneficialOwner() {
    return this.props.beneficialOwners.selected
  }

  get nextStep() {
    return "/verify-identity/address"
  }

  get prevStep() {
    return "/verify-identity/confirm-name"
  }

  handleSave = () => {
    this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      mapFormData: (data) => ({...data, currentStep: "address"})
    })
  }

  render = () => {
    const {formData, onFormDataChange, errors, errorStrings, classes} = this.props
    return (
      <IdentityStep
        title='Enter date of birth'
        errors={errors}
        formData={formData}
        onGoBack={() => this.props.history.push(this.prevStep)}
        onSave={this.handleSave}
        onFormDataChange={onFormDataChange}
        saveButtonDisabled={!formData.dateOfBirth}
      >
        <Box>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          {this.beneficialOwner.restartComment &&
          <>
            <Typography variant='subtitle1' className={classes.reviewFeedback} gutterBottom>
              Comment from onboarding specialist: {this.beneficialOwner.restartComment}
            </Typography>
          </>
          }
          <DatePickerSelect name='dateOfBirth' yearsOrder="desc" label={null} maxYear={new Date().getFullYear()} />
        </Box>
      </IdentityStep>
    )
  }
}

const styles = {
  reviewFeedback: {
    color: '#ff0800'
  }
}

const fetchDependencies = ({ beneficialOwners }) => {
  return beneficialOwners.actions.current()
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('beneficialOwners'),
  withStyles(styles),
  provide(BeneficialOwnersContext),
)(DateOfBirth)