import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import HttpsIcon from '@material-ui/icons/Https';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { provide, consume, SnackbarContext, WebhooksContext } from 'contexts'

export class List extends Component{

  state = {
    page: 1
  }

  get tenantId() {
    return this.props.match.params.tenantId
  }

  showNewTenantPage = () => {
    this.props.history.push(`/tenants/${this.tenantId}/webhooks/new`)
  }

  showWebhook = id => () => {
    this.props.history.push(`/webhooks/${id}`)
  }

  editWebhook = id => event => {
    this.props.history.push(`/webhooks/${id}/edit`)
    event.stopPropagation()
  }

  deleteWebhook = id => event => {
    this.props.webhooks.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get webhooks(){
    return this.props.webhooks.list
  }

  get errors(){
    let errors = []
    if(this.props.webhooks.errors.index){
      errors = errors.concat(this.props.webhooks.errors.index)
    }
    if(this.props.webhooks.errors.destroy){
      errors = errors.concat(this.props.webhooks.errors.destroy)
    }
    return errors
  }

  renderWebhookListItem = ({id, url, enabled}) =>
    <ListItem button onClick={this.showWebhook(id)} key={id}>
      <ListItemIcon>
        <HttpsIcon />
      </ListItemIcon>
      <ListItemText primary={url} secondary={enabled ? null : 'disabled'}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editWebhook(id)}><EditIcon/></IconButton>
        <IconButton onClick={this.deleteWebhook(id)}><DeleteIcon/></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.webhooks.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.webhooks.map(this.renderWebhookListItem)}
      </MuiList>
      <Pagination totalPages={this.props.webhooks.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <FABFixed color='secondary' onClick={this.showNewTenantPage}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
}

const fetchDependencies = ({webhooks, page, match: { params: {tenantId} } = {}}) => {
  return webhooks.actions.index({
    page: page,
    fields: {webhooks: 'url,enabled'},
    params: {tenantId},
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('webhooks'),
  provide(WebhooksContext),
  consume(SnackbarContext),
)(List)