import React from 'react'
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import { ClosableDialogButton, LinkButton } from "components"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import { humanize } from "utils"
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import AuStates from 'constants/AuStates'

function TableEntry({ title, children }) {
    return (
        <TableRow>
            <TableCell variant='head'>{title}</TableCell>
            <TableCell>{children}</TableCell>
        </TableRow>
    )
}

const stateName = code => {
    if (AuStates.hasOwnProperty(code)) {
        return AuStates[code]
    }

    return code
}

const ownershipInfo = beneficialOwner => {
    const statusName = humanize(beneficialOwner.ownershipStatus)
    if (beneficialOwner.holdings) {
        return `${statusName} (${beneficialOwner.holdings}%)`
    }

    return statusName
}

function BeneficialOwnerInfoDialog({ beneficialOwner }) {
    const renderPassport = (passport) => (
        <>
            <TableEntry title="Passport">
                <Typography variant="subtitle2">
                    {passport.passportNumber}
                </Typography>
                <Typography variant="body2">
                    Expires: {moment(passport.expiryDate).format("DD/MM/YYYY")}
                </Typography>
                {passport.gender &&
                  <Typography variant="body2">
                    Gender: {passport.gender}
                  </Typography>
                }
            </TableEntry>
        </>
    )

    const renderDriversLicence = licence => (
      <TableEntry title="Drivers licence">
        <Typography variant="subtitle2">
          {licence.licenceNumber}
        </Typography>
        {licence.versionNumber &&
          <Typography variant="body2">
            Version: {licence.versionNumber}
          </Typography>
        }
        {licence.issuingState &&
          <Typography variant="body2">
            State: {stateName(licence.issuingState)}
          </Typography>
        }
      </TableEntry>
    )

    const renderAddress = address => (
        <TableEntry title="Address">
            <Typography variant="body2">{address.streetAddress}</Typography>
            <Typography variant="body2">{address.city}, {address.postcode}</Typography>
            {address.region && <Typography variant="body2">{stateName(address.region)}</Typography>}
        </TableEntry>
    )

    return (
        <ClosableDialogButton title={`${beneficialOwner.firstName} ${beneficialOwner.lastName}`}
                              buttonComponent={LinkButton}
                              buttonTitle="Full Info" >
            <Table>
                <TableBody>
                    <TableEntry title="Email">
                        {beneficialOwner.email}
                    </TableEntry>
                    <TableEntry title="Ownership status">
                        {ownershipInfo(beneficialOwner)}
                    </TableEntry>
                    <TableEntry title="Date of birth">
                        {moment(beneficialOwner.dateOfBirth).format("DD/MM/YYYY")}
                    </TableEntry>
                    {beneficialOwner.passport && renderPassport(beneficialOwner.passport)}
                    {beneficialOwner.auPassport && renderPassport(beneficialOwner.auPassport)}
                    {beneficialOwner.driversLicence && renderDriversLicence(beneficialOwner.driversLicence)}
                    {beneficialOwner.auDriversLicence && renderDriversLicence(beneficialOwner.auDriversLicence)}
                    {beneficialOwner.nzDriversLicence && renderDriversLicence(beneficialOwner.nzDriversLicence)}
                    {beneficialOwner.address && renderAddress(beneficialOwner.address)}
                </TableBody>
            </Table>
        </ClosableDialogButton>
    )
}

export default BeneficialOwnerInfoDialog;