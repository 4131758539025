import React, { Component } from 'react'
import instanceForm from 'containers/shared/instanceForm'
import dependsOn from 'containers/shared/dependsOn'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { compose } from 'utils'
import withStyles from 'styles'
import { provide, TenantsContext } from 'contexts'

export class Form extends Component {

  render = () => {
    const {formData, errors, errorStrings, onFormDataChange, onSave, editMode} = this.props
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant='h5'>{editMode ? `Edit Tenant - ${formData.name}` : 'Create a new Tenant'}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name='name'/>
          <TextField fullWidth name='tokenIssuer'/>
          <TextField fullWidth multiline rows="15" name='publicKey'/>
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </ControlledForm>
      </PageContainer>
    )
  }

}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

const fetchDependencies = ({tenants, id, editMode}) => {
  if (editMode) {
    return tenants.actions.show(id)
  }
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('tenants'),
  withStyles(styles),
  provide(TenantsContext),
)(Form)