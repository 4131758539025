import APIContext from 'contexts/base/APIContext'

export default class NotificationsContext extends APIContext{
  static contextName = 'notifications'

  static initialState = {
    ...this.initialState,
    mine: {},
    minePage: 1,
    unread: 0
  }

  // This method handles data fetch for both the explicitly requested page (e.g. by Mine component)
  // and for the first page implicitly polled by the popup notifications component.
  // It may request two pages at the same time to keep them in sync.
  mine = async({page = this.state.minePage, ...options }={}) => {
    const {data: firstPage, meta: { totalPages, unread }} = await this.performRequest('mine')({options: {...options, page: { number: 1 }}})

    const newMine = {1: firstPage}

    if (page !== 1) {
      const { data: requestedPage } = await this.performRequest('mine')({options: {page: { number: page }}})
      newMine[page] = requestedPage
    }

    this.setState({mine: newMine, unread, minePage: page, totalPages})
    return newMine
  }

  resetMinePage = () => {
    this.setState({minePage: 1})
  }

  create = async(item, {...options}={}) => {
    const { data:selected } = await this.performRequest('create')({...item, options})
    this.setState({selected})
    return selected
  }

  resend = async(item, {...options}={}) => {
    const { data:newNotification } = await this.performRequest('resend')({...item, options})
    return newNotification
  }
}
