import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer, SearchCombo, Autocomplete, LabeledSelect, Select } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip'
import VerificationIcon from '@material-ui/icons/Policy';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EmailIcon from '@material-ui/icons/Email';
import { compose, errorStringsFromError, Authorization } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { provide, consume, SnackbarContext, VerificationsContext } from 'contexts'
import withStyles from 'styles'
import * as API from 'api'
import VerificationStatuses from 'constants/VerificationStatuses'

export class List extends Component{

  state = {
    page: 1
  }

  showVerification = id => () => {
    this.props.history.push(`/verifications/${id}`)
  }

  fillInVerification = verification => event => {
    let url
    if (verification.status === "pending_review_business_structure") {
      url = `/verifications/status/${verification.id}`
    } else {
      url = `/verification-step/${verification.currentStep}/${verification.id}`
    }

    this.props.history.push(url)
    event.stopPropagation()
  }

  editVerification = id => event => {
    this.props.history.push(`/verifications/${id}/edit`)
    event.stopPropagation()
  }

  deleteVerification = id => event => {
    this.props.verifications.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  reviewBusinessStructure = id => event => {
    this.props.history.push(`/verifications/${id}/business-structure-review`)
    event.stopPropagation()
  }

  reviewFinal = id => event => {
    this.props.history.push(`/verifications/${id}/final-review`)
    event.stopPropagation()
  }

  showVerificationUserStatusPage = id => event => {
    this.props.history.push(`/verifications/status/${id}`)
    event.stopPropagation()
  }

  inviteVerificationUser = id => event => {
    API.Verifications.event({
      'name': 'invite',
      'id': id
    }).then((...args) => {
      this.props.onDependencyUpdate(...args)
      this.props.snackbar.actions.show("Invite sent")
    }).catch(error => this.props.snackbar.actions.show("ERROR: " + errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  onSuggestionsFetchRequestedCountries =  async (text, callback) => {
    const { data: namesAndIds } = await API.Countries.index({
      options: {
        fields: {asset_type: 'id,name'},
        filter: {name: text},
        page: { number: 1, size: 5 }
      }
    })
    callback(namesAndIds)
  }

  onSuggestionsFetchRequestedUsers =  async (text, callback) => {
    const { data: namesAndIds } = await API.Users.index({
      options: {
        fields: {asset_type: 'id,name'},
        filter: {name: text, role: 'onboarding_specialist'},
        page: { number: 1, size: 5 }
      }
    })
    callback(namesAndIds)
  }

  onSuggestionsFetchRequestedTenants =  async (text, callback) => {
    const { data: namesAndIds } = await API.Tenants.index({
      options: {
        fields: {asset_type: 'id,name'},
        filter: {name: text },
        page: { number: 1, size: 5 }
      }
    })
    callback(namesAndIds)
  }

  handleFilterChange = (filter) => {
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  handleStatusesChange = statuses => {
    if (statuses.length > 0) {
      this.handleFilterChange({...this.filter, statuses })
    } else {
      this.handleFilterChange({...this.filter, statuses: undefined })
    }
  }

  get statuses() {
    if (!this.filter.statuses) {
      return []
    }

    return this.filter.statuses.split(',')
  }

  statusName(enumCode) {
    if (VerificationStatuses.hasOwnProperty(enumCode)) {
      return VerificationStatuses[enumCode]
    }

    return enumCode
  }


  get verifications(){
    return this.props.verifications.list
  }

  get errors(){
    let errors = []
    if(this.props.verifications.errors.index){
      errors = errors.concat(this.props.verifications.errors.index)
    }
    if(this.props.verifications.errors.destroy){
      errors = errors.concat(this.props.verifications.errors.destroy)
    }
    return errors
  }

  get filter() {
    const { filter, filter: { event } } = this.props
    return { ...filter, event: (!event || Array.isArray(event)) ? event : [event] }
  }


  renderVerificationListItem = (verification) => {
    const {id, name} = verification
    return (
      <ListItem button onClick={this.showVerification(id)} key={id}>
        <ListItemIcon>
          <VerificationIcon />
        </ListItemIcon>
        <ListItemText primary={name} secondary={this.statusName(verification.status)} />
        <ListItemSecondaryAction>
          {["draft", "invited", "in_progress", "pending_id_checks", "pending_verification_details"].includes(verification.status) &&
            <Tooltip title="Invite Merchant">
              <IconButton onClick={this.inviteVerificationUser(id)}
                          className={this.props.classes({ inviteNotSentIcon: verification.status === "draft" })}>
                <EmailIcon />
              </IconButton>
            </Tooltip>
          }
          {["draft", "invited", "in_progress"].includes(verification.status) &&
            <Tooltip title="Fill in">
              <IconButton onClick={this.fillInVerification(verification)}><PlayCircleFilledIcon/></IconButton>
            </Tooltip>
          }
          {verification.status === "pending_review_business_structure" &&
            <Tooltip title="Review">
              <IconButton onClick={this.reviewBusinessStructure(id)}><FindInPageIcon/></IconButton>
             </Tooltip>
          }
          {verification.status === "pending_review_final" &&
            <Tooltip title="Review">
              <IconButton onClick={this.reviewFinal(id)}><FindInPageIcon/></IconButton>
            </Tooltip>
          }
          {["pending_id_checks", "pending_verification_details"].includes(verification.status) &&
            <Tooltip title="Verification Status">
              <IconButton onClick={this.showVerificationUserStatusPage(id)}><NavigateNextIcon/></IconButton>
            </Tooltip>
          }
          {verification.status !== "done" &&
            <Tooltip title="Edit">
              <IconButton onClick={this.editVerification(id)}><EditIcon/></IconButton>
            </Tooltip>
          }
          <Tooltip title="Delete">
            <IconButton onClick={this.deleteVerification(id)}><DeleteIcon/></IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>


  renderFilters = () =>
    <Box display="flex" justifyContent="space-between">
      <Box>
        <SearchCombo searchTextMember='search' onFilterChange={this.handleFilterChange} filter={this.filter}>
          <TextField name="name" fullWidth />
          <Autocomplete name="user" fullWidth onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedUsers} label="Onboarding Specialist" />
          <Autocomplete name="country" fullWidth onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCountries} />
          {
            Authorization.admin &&
            <Autocomplete name="tenant" fullWidth onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedTenants} />
          }
          <LabeledSelect name="ownershipStructure" fullWidth options={{"company": "Company", "partnership": "Partnership", "sole_trader_individual": "Sole Trader / Individual", "trust": "Trust"}} />
          <TextField name="nationalBusinessNumber" fullWidth />
          <TextField name="merchantReference" fullWidth label="Merchant Reference (ID)" />
          <TextField name="primaryContactEmail" fullWidth />
          <LabeledSelect name="sortBy"
                         label="Sort By"
                         style={{width: "50%"}}
                         options={{
                           "name": "Name",
                           "createdAt": "Create Date",
                           "updatedAt": "Last Update"
                         }} />
          <LabeledSelect name="sortDir" label="Sort Direction" style={{width: "50%"}} options={{"asc": "Ascending", "desc": "Descending"}} />
        </SearchCombo>
      </Box>
      <Select
        name='status'
        label='Status'
        value={this.statuses}
        multiple
        onChange={e => this.handleStatusesChange(e.target.value)}
        classes={{ root: this.props.classes.status, select: this.props.classes.statusSelect }}
      >
        {Object.keys(VerificationStatuses).map(code => (
          <MenuItem value={code} key={code}>{VerificationStatuses[code]}</MenuItem>
        ))}
      </Select>
    </Box>

  render = () =>
    <PageContainer>
      {this.renderErrorMessages()}
      <Pagination
        totalPages={this.props.verifications.totalPages}
        page={this.props.page}
        onPageSelected={this.props.onPageChange}
        style={{}}
        linkStyle={{}}
        startAdornment={this.renderFilters()}
      />
      <MuiList dense>
        {this.verifications.map(this.renderVerificationListItem)}
      </MuiList>
      <Pagination totalPages={this.props.verifications.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <FABFixed color='secondary' onClick={() => this.props.history.push('/verifications/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
}

const styles = {
  status: {
    marginBottom: 0,
    top: -12
  },
  statusSelect: {
    minWidth: 100,
    marginRight: 10,
  },
  inviteNotSentIcon: {
    background: 'rgba(35, 204, 26, 0.43)',
    boxShadow: '0 0px 12px 3px rgba(35,204,26,0.66)',
    width: 35,
    height: 35,
    margin: '0 7px',
    '&:hover': {
      boxShadow: '0 0px 12px 3px rgba(114,179,111,0.66)',
      background: 'rgba(114, 179, 111, 0.43)',
    }
  }
}


const fetchDependencies = ({verifications, filter, page}) => {
  let sortDir = filter.sortDir
  let sortBy = filter.sortBy
  if (!sortBy) {
    sortBy = "updatedAt"
    sortDir = sortDir || "desc"
  }

  return verifications.actions.index({
    page: page,
    fields: {verifications: 'name,beneficialOwners,status,currentStep'},
    filter,
    sort: `${sortDir === "desc" ? "-" : ""}${sortBy}`,
    include: 'beneficialOwners'
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('verifications'),
  provide(VerificationsContext),
  withStyles(styles),
  consume(SnackbarContext),
)(List)