import React, { Component } from 'react'
import cloneDeep from 'clone-deep'
import dependsOn from 'containers/shared/dependsOn'
import instanceForm from 'containers/shared/instanceForm'
import Typography from '@material-ui/core/Typography'
import { provide, consume, VerificationsContext, CountriesContext, SnackbarContext } from 'contexts'
import { VerificationStep, FileUpload, ErrorBanner, ControlledForm, ClosableDialogButton } from 'components'
import { compose, deepSet } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid';
import { humanize } from 'utils'
import TextFieldMui from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const KEY_CONTACTS_DEPARTMENTS = ["marketing", "customerSupport", "tech", "accounts"]

const TextField = props => <TextFieldMui InputLabelProps={{ shrink: true }} {...props} />

const FileUploadWithHelp = ({HelpButtonDialog, ...props}) => {
  return (
    <Box style={{ position: "relative" }}>
      <HelpButtonDialog />
      <FileUpload {...props} />
    </Box>
  )
}

function HelpButton({ onClick }) {
  return(
      <HelpOutlineOutlinedIcon style={{ position: "absolute", left: 255, top: -3, cursor: 'pointer' }}
                               color="action"
                               onClick={onClick}
                               fontSize="small" />
  )
}

function TextWithError({error, helperText, ...props}) {
  const hasError = error && typeof helperText === "string"
  const newProps = hasError ? {...props, color: 'error', gutterBottom: true} : props
  return (
    <>
      <Typography {...newProps} />
      {hasError &&
        <Typography variant="subtitle2" color="error" style={{ fontSize: 10 }}>
          {helperText}
        </Typography>
      }
    </>
  )
}

function CompanyLogoHelpDialog() {
  return (
    <ClosableDialogButton title="Your company logos" buttonComponent={HelpButton} >
      <Typography variant="body1">We require each of the following:</Typography>
      <Typography variant="body1">- High resolution primary logo</Typography>
      <Typography variant="body1">- High resolution logo completely white</Typography>
      <Typography variant="body1">
        Please make sure there is no white space surrounding the logo & have the canvas push against
        each edge.
      </Typography>
      <Typography variant="body1">Preferred file types: ai, eps, svg, png</Typography>
    </ClosableDialogButton>
  )
}

function LifestyleImageHelpDialog() {
  return (
      <ClosableDialogButton title="Your Directory Image" buttonComponent={HelpButton} >
        <Typography variant="body1">
          To be included in our directory of merchants we require a
          lifestyle/brand image in addition to your logo.
          This image can be any file format, but must be high resolution (jpg, jpeg or png files preferred).
          Your white logo mentioned above will be automatically centred on the image for you.
        </Typography>
        <Typography variant="body1">Minimum Image Size:</Typography>
        <Typography variant="body1">- 1080px wide x 774px high</Typography>
      </ClosableDialogButton>
  )
}

export class LaybuyAddon extends Component{
  expectedContentType = "image/*, application/postscript, application/eps, application/x-eps, application/pdf"

  get verification() {
    return this.props.verifications.selected || {}
  }

  get verificationDetails() {
    return this.verification.verificationDetails || {}
  }

  get currentVerificationDetails() {
    return this.props.formData.verificationDetails || {}
  }

  get country() {
    return this.verification.country || {}
  }

  get countries() {
    return this.props.countries.list || []
  }

  get nationalBusinessNumberName() {
    switch (this.country.code) {
      case "AU":
        return "ABN Number"
      case "NZ":
        return "NZBN Number"
      default:
        return "National Business Number"
    }
  }

  handleSave = submit => async () => {
    const result = await this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      showSnackbarSuccess: false,
      mapFormData: (data) => {
        const newData = cloneDeep(data)
        const laybuyGlobalCountries = data.relationships.verificationDetails.data.laybuyGlobalCountries
        if (laybuyGlobalCountries) {
          const countryCodes = Object.entries(laybuyGlobalCountries).filter( ([_, v]) => v ).map( ([k]) => k )
          newData.relationships.verificationDetails.data.laybuyGlobalCountries = countryCodes
        }
        if (submit) {
          newData.relationships.verificationDetails.data.completed = true
        }
        return newData
      }
    })

    if (result.id) {
      let message = "Additional information was saved"
      if (submit) {
        message += " and submitted for review"
      }
      this.props.snackbar.actions.show(message)
    }
  }

  handleFileSelect = name => file => {
    let newFormData = {...this.props.formData}
    newFormData = deepSet(file, name, newFormData)
    newFormData = deepSet(file.name, `${name}Name`, newFormData)
    newFormData = deepSet(undefined, `${name}UrlPath`, newFormData)
    this.props.onFormDataChange(newFormData)
  }

  get prevStep() {
    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verifications/status/${verificationId}`
    }

    return "/verification-step/status"
  }

  renderAccountSection = () => {
    const {classes} = this.props
    return (
      <Box className={classes({section: true, sectionDark: true})}>
        <Box className={classes.limitWidth}>
          <Typography variant="h5" style={{ marginBottom: 15 }}>Account</Typography>
          <Typography variant="subtitle2">
            Enable Laybuy Global for customers in these countries:
          </Typography>
          <Box className={classes.formGroup}>
            {this.countries.filter(country => country.code !== this.country.code).map(country => (
              <FormControlLabel
                key={country.id}
                control={
                  <ControlledForm data={this.props.formData} onChange={this.props.onFormDataChange}>
                    <Checkbox
                      type="checkbox"
                      name={`verificationDetails.laybuyGlobalCountries.${country.code}`}
                      color="secondary"
                    />
                  </ControlledForm>
                }
                label={country.name}
              />
            ))}
          </Box>
          <Grid container spacing={6} style={{ marginBottom: 10 }}>
            <Grid item md={4} xs={12}>
              <TextField fullWidth name="verificationDetails.bankAccountNumber" />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField fullWidth name="verificationDetails.taxFileNumber" />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField fullWidth name="nationalBusinessNumber" label={this.nationalBusinessNumberName}  />
            </Grid>
          </Grid>
          <FileUpload name="verificationDetails.bankAccountScreenshot"
                      onFileSelect={this.handleFileSelect("verificationDetails.bankAccountScreenshot")}
                      helperText="We require a screenshot of your bank account number,
                                including account name, to make payments into your account for each transaction.
                                The screenshot is to avoid any human error."
                      fileName={this.currentVerificationDetails.bankAccountScreenshotName}
                      urlPath={this.currentVerificationDetails.bankAccountScreenshotUrlPath}
                      accept={this.expectedContentType}
                      style={{ maxWidth: 230 }}
          />
        </Box>
      </Box>
    )
  }

  renderContactsSection = () => {
    const {classes} = this.props
    return (
      <Box className={classes({section: true, limitWidth: true})} style={{ paddingBottom: 0 }}>
        <Typography variant="h5" style={{ marginBottom: 15 }}>Key contacts</Typography>
        <Typography variant="subtitle2">
          To keep thing running smoothly, we need a point of contact for each of these departments.
        </Typography>
        <Grid container spacing={1} className={classes.preview}>
          {KEY_CONTACTS_DEPARTMENTS.map((department, idx) => (
            <Grid container item md={12} xs={12} spacing={2} key={idx} alignItems="center">
              <Grid item md={3} xs={12}>
                <TextWithError
                    name={`verificationDetails.keyContacts.${department}`}
                    variant="subtitle2">
                  {humanize(department)}
                </TextWithError>
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField fullWidth name={`verificationDetails.keyContacts.${department}.firstName`} />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField fullWidth name={`verificationDetails.keyContacts.${department}.lastName`} />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField fullWidth name={`verificationDetails.keyContacts.${department}.email`} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }

  render = () => {
    const {classes, formData, onFormDataChange, errors, errorStrings, history} = this.props

    if (this.verificationDetails.completed) {
      history.push("/")
      return null
    }

    return (
      <VerificationStep
        title='Store Directory'
        onGoBack={() => this.props.history.push(this.prevStep)}
        saveButtonText="Submit for review"
        onSave={this.handleSave(true)}
        saveForLater
        onSaveForLater={this.handleSave(false)}
        formData={formData}
        onFormDataChange={onFormDataChange}
        errors={errors}
        classes={{ cardContent: classes.cardContent, cardActions: classes.cardActions, header: classes.header }}
      >
        <Box className={classes({section: true, limitWidth: true})} style={{ paddingTop: 0 }}>
          <Box className={this.props.classes.body}>
            <Typography variant='body1' className={classes.paragraph}>
              To be included in our directory of merchants we require a lifestyle/brand image in addition to your logo.
              This image can be any file format, but must be high resolution (jpg, jpeg or png files preferred)
            </Typography>
            <ErrorBanner>{errorStrings}</ErrorBanner>
          </Box>
          <Box className={classes.formGroup}>
            <Grid container style={{ marginBottom: 30 }}>
              <Grid item md={6} xs={12}>
                <FileUploadWithHelp onFileSelect={this.handleFileSelect("verificationDetails.companyLogo")}
                                    fileName={this.currentVerificationDetails.companyLogoName}
                                    urlPath={this.currentVerificationDetails.companyLogoUrlPath}
                                    accept={this.expectedContentType}
                                    name="verificationDetails.companyLogo"
                                    label="Company logo"
                                    classes={{textField: classes.imageUploadTextField}}
                                    HelpButtonDialog={CompanyLogoHelpDialog} />
              </Grid>
              <Grid item md={6} xs={12}>
                <FileUploadWithHelp onFileSelect={this.handleFileSelect("verificationDetails.companyLogoWhite")}
                                    fileName={this.currentVerificationDetails.companyLogoWhiteName}
                                    urlPath={this.currentVerificationDetails.companyLogoWhiteUrlPath}
                                    accept={this.expectedContentType}
                                    name="verificationDetails.companyLogoWhite"
                                    label="Company logo (white)"
                                    classes={{textField: classes.imageUploadTextField}}
                                    HelpButtonDialog={CompanyLogoHelpDialog} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={6} xs={12}>
                <FileUploadWithHelp onFileSelect={this.handleFileSelect("verificationDetails.lifestyleImage")}
                                    fileName={this.currentVerificationDetails.lifestyleImageName}
                                    urlPath={this.currentVerificationDetails.lifestyleImageUrlPath}
                                    accept={this.expectedContentType}
                                    name="verificationDetails.lifestyleImage"
                                    label="Lifestyle image"
                                    classes={{textField: classes.imageUploadTextField}}
                                    HelpButtonDialog={LifestyleImageHelpDialog} />
              </Grid>
            </Grid>
          </Box>
          <TextField name="verificationDetails.directoryDisplayName" style={{ width: 250 }}
                     helperText="Your store name, as displayed to customers (eg. Starbucks)" />
        </Box>
        {this.renderAccountSection()}
        {this.renderContactsSection()}
      </VerificationStep>
    )
  }
}

const styles = {
  body: {
    marginTop: 10,
    marginBottom: 50,
    maxWidth: 548
  },
  paragraph: {
    marginBottom: 20
  },
  preview: {
    marginTop: 25,
    marginBottom: 50
  },
  cardContent: {
    padding: 0,
    maxWidth: 'unset',
  },
  cardActions: {
    padding: '35px 25px 25px 25px',
    '@media(min-width: 600px)': {
      padding: '35px 90px 25px 90px',
    }
  },
  section: {
    padding: '25px 30px 20px 30px',
    '@media(min-width: 600px)': {
      padding: '55px 90px 40px 90px',
    }
  },
  limitWidth: {
    maxWidth: 850,
  },
  sectionDark: {
    background: '#F5F5F5',
  },
  header: {
    padding: '10px 30px 0 30px',
    '@media(min-width: 600px)': {
      padding: '30px 90px 0 90px',
    }
  },
  formGroup: {
    marginBottom: 30
  },
  imageUploadTextField: {
    width: 168
  }
}

const fetchDependencies = ({verifications, countries, match: { params: { verificationId }}}) => {
  const countriesPromise = countries.actions.index()
  let verificationsPromise
  if (verificationId) {
    verificationsPromise = verifications.actions.show(verificationId, {include: 'country,verification_details'})
  } else {
    verificationsPromise = verifications.actions.current({include: 'country,verification_details'})
  }
  return Promise.all([countriesPromise, verificationsPromise])
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('verifications', {nested: ['verificationDetails']}),
  withStyles(styles),
  provide(VerificationsContext, CountriesContext, SnackbarContext),
  consume(SnackbarContext),
)(LaybuyAddon)