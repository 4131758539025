import Nonce from './Nonce'

class GoogleAuthService {
  constructor() {
    this.authPopup = null
    this.callbacks = new Set()
  }

  handleAuthResponse = (event) => {
    if(event.source !== this.authPopup) {
      return
    }

    let { state } = event.data
    let nonce = global.localStorage.googleOAuthNonce

    if(nonce !== state) {
      this.replyToListeners({ error: 'state_mismmatch', errorDescription: 'Potential CSRF attack' })
      return
    }

    let authHash = this.authHashFromReply(event.data)
    this.replyToListeners(authHash)
    this.authPopup.close()
  }

  authHashFromReply = (data) => {
    let { error, error_description, code } = data
    if(error) {
      return { error, errorDescription: error_description }
  } else {
      return { authCode: code, redirectUri: this.redirectUri() }
    }
  }

  replyToListeners = (payload) => {
    this.callbacks.forEach((callback) => { callback(payload) })
    this.callbacks.clear()
    window.removeEventListener('message', this.handleAuthResponse)
  }

  authorize = (callback, popup = false) => {
    const nonce = Nonce.generate()
    if(popup) {
      this.callbacks.add(callback)
      if(!this.authPopup || this.authPopup.closed) {
        window.addEventListener('message', this.handleAuthResponse, false)
        global.localStorage.googleOAuthNonce = nonce
        this.authPopup = window.open(this.authUrl(nonce), 'GoogleAuth')
      }
      this.authPopup.focus()
    } else {
      global.localStorage.googleOAuthNonce = nonce
      window.location = this.authUrl(nonce)
    }
  }

  authUrl = (state) => {
    let authorizationUrl = process.env.REACT_APP_GOOGLE_AUTHORIZATION_URL
    let clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    let scope = "profile email openid"
    let authUrl = `${authorizationUrl}?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(this.redirectUri())}&state=${state}&scope=${scope}&access_type=offline&include_granted_scopes=true`
    return authUrl
  }

  redirectUri = () => {
    let url = new URL(window.location)
    return `${url.origin}/googleauth/callback`
  }
}

const GoogleAuthServiceInstance = new GoogleAuthService()
export { GoogleAuthServiceInstance as GoogleAuthService }