import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { PageContainer } from 'components'
import { Box } from '@material-ui/core'
import withStyles from 'styles'
import { compose } from 'utils'
import { provide, VerificationsContext } from "contexts";
import dependsOn from 'containers/shared/dependsOn'

export class Status extends Component{

  get verification() {
    return this.props.verifications.selected
  }

  get beneficialOwners() {
    return this.verification.beneficialOwners || []
  }

  get verificationDetails() {
    return this.verification.verificationDetails
  }


  get numberOfVerifiedIdentities() {
    return this.beneficialOwners.filter(({ status }) => status === "done").length
  }

  get numberOfIdentities() {
    return this.beneficialOwners.length
  }

  get manageIdentitiesPage() {
    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verifications/ids-status/${verificationId}`
    }

    return "/verifications/ids-status"
  }

  get completePage() {
    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verification-step/complete-laybuy/${verificationId}`
    }

    return "/verification-step/complete-laybuy"
  }

  render = () => {
    const {classes} = this.props
    return (
      <PageContainer>
        {this.verificationDetails && !this.verificationDetails.completed &&
          <Box className={classes.merchantBox}>
            <Typography variant='h5'>While you’re waiting on Identity checks</Typography>
            <Typography variant='h5'>let’s sort your branding and account info</Typography>

            <Button
                color='secondary'
                variant='contained'
                className={classes.button}
                onClick={() => this.props.history.push(this.completePage)}
            >
              Finish Merchant setup →
            </Button>
          </Box>
        }
        <Box className={classes.verificationBox}>
          <Typography variant='h5'>
            {this.numberOfVerifiedIdentities}/{this.numberOfIdentities} Identity checks completed
          </Typography>

          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={() => this.props.history.push(this.manageIdentitiesPage)}
          >
            Manage →
          </Button>
        </Box>
      </PageContainer>
    )
  }
}

const styles = {
  button: {
    borderRadius: 25,
    padding: '8px 36px 6px 35px',
    marginTop: 20
  },
  merchantBox: {
    padding: '35px 50px 25px 50px',
    backgroundColor: '#bec9d6',
    marginTop: 10
  },
  verificationBox: {
    padding: '35px 50px 25px 50px',
    backgroundColor: '#e0e7ef',
    marginTop: 10
  }
}

const fetchDependencies = ({verifications, match: { params: { verificationId }}}) => {
  if (verificationId) {
    return verifications.actions.show(verificationId, {include: 'beneficialOwners,verificationDetails'})
  } else {
    return verifications.actions.current({include: 'beneficialOwners,verificationDetails'})
  }
}

export default compose(
  dependsOn(fetchDependencies),
  withStyles(styles),
  provide(VerificationsContext),
)(Status)