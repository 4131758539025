import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import { provide, consume, VerificationsContext, SnackbarContext } from 'contexts'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import * as API from 'api'
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Button from "@material-ui/core/Button"
import CheckIcon from '@material-ui/icons/Check'
import RejectReviewButton from "./RejectReviewButton"
import ClearIcon from "@material-ui/icons/Clear"
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import Show from './Show'


export class FinalReview extends Component{
  get verification() {
    return this.props.verifications.selected || {}
  }

  get verificationDetails() {
    return this.verification.verificationDetails || {}
  }

  get country() {
    return this.verification.country || {}
  }

  get beneficialOwners() {
    return this.verification.beneficialOwners || []
  }

  handleApprove = async () => {
    const verification = this.props.verifications.selected
    await API.Verifications.event({
      name: 'accept_verification',
      id: verification.id
    })
    this.props.snackbar.actions.show("Verification approved")
    this.props.history.push("/verifications")
  }

  handleCancel = async () => {
    const verification = this.props.verifications.selected
    await API.Verifications.event({
      name: 'cancel_verification',
      id: verification.id
    })
    this.props.snackbar.actions.show("Verification Canceled")
    this.props.history.push("/verifications")
  }

  render = () => {
    const {classes, match} = this.props
    return (
      <Show match={match} title="Final Review" hideStatus>
        <TableRow>
          <TableCell colSpan={2}>
            <Box>
              <Button
                  variant="contained"
                  startIcon={<ClearIcon />}
                  onClick={this.handleCancel}
                  className={classes.cancelButton}>
                Cancel
              </Button>
              <RejectReviewButton verificationId={this.verification.id}
                                  eventName="restart_verification"
                                  icon={<RotateLeftIcon />}
                                  actionName="restart" />
              <Button color="secondary" variant="contained" startIcon={<CheckIcon />} onClick={this.handleApprove}>
                Approve
              </Button>
            </Box>
          </TableCell>
        </TableRow>
      </Show>
    )
  }
}

const styles = theme => ({
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  }
})

const fetchDependencies = async ({verifications, match: { params: { id }}, history}) => {
  const verification = await verifications.actions.show(id)
  if (verification.status !== "pending_review_final") {
    history.push(`/verifications/${id}`)
  }

  return verification
}

export default compose(
  dependsOn(fetchDependencies),
  withStyles(styles),
  provide(VerificationsContext),
  consume(SnackbarContext),
)(FinalReview)