import React, { Component } from 'react'
import { CenteredCard, ErrorBanner } from 'components'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { SnackbarContext, TokensContext, consume } from 'contexts'

export class Confirm extends Component{

  componentDidMount = () => {
    this.submit()
  }

  get token(){
    return window.location.search.replace(/^.*?token=([^$&]+).*/,'$1')
  }

  submit = async () => {
    await this.props.tokens.actions.confirm({token: this.token})
    await this.props.tokens.actions.verify()
    this.props.snackbar.actions.show("Your account has been confirmed")
    this.props.history.push('/')
  }

  render = () =>
    <CenteredCard >
      <CardContent>
        {this.props.tokens.errors.confirm && <ErrorBanner>{this.props.errors.tokens.confirm.title}</ErrorBanner>}
        <Typography type='h5'>Confirming your account...</Typography>
      </CardContent>
    </CenteredCard>
}

export default consume(TokensContext, SnackbarContext)(Confirm)