import React, {Component} from 'react'
import Typography from "@material-ui/core/Typography";
import { LinkButton, ClosableDialogButton } from "components";

export class BeneficialOwnerRapidIdentityDetailsDialog extends Component {
  get name() {
    const { beneficialOwner } = this.props
    return `${beneficialOwner.firstName} ${beneficialOwner.lastName}`
  }

  render = () => {
    const {style, beneficialOwner: { rapidIdentityResponse }} = this.props
    const responseBody = JSON.parse(rapidIdentityResponse.body)
    const reliability = responseBody?.codes?.reliability
    const reliabilityHash = { 0: 'Error', 10: 'Full Match', 20: 'Partial Match', 30: 'No Match' }
    const reliabilityText = reliabilityHash[reliability] || ''

    return (
      <ClosableDialogButton title={`RapidID identity check result for ${this.name}`} buttonTitle="See more" buttonComponent={LinkButton} buttonProps={{ style }}>
        <Typography variant="h5">Check:</Typography>
        <Typography variant="subtitle2" color={rapidIdentityResponse.verified ? "secondary" : "error"}>
          Reliability: {reliability} ({reliabilityText})
        </Typography>
        <Typography variant="subtitle1">Original response from RapidID:</Typography>
        <pre>{JSON.stringify(responseBody, null, 2)}</pre>
      </ClosableDialogButton>
    )
  }
}

export default BeneficialOwnerRapidIdentityDetailsDialog