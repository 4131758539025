export function compact(collection){
  return collection.filter(x => x)
}

export function flatten(collection){
  return collection.reduce(function(prev, inner) {
    inner = Array.isArray(inner) ? inner : [inner]
    const  multiLevel = (inner).some(Array.isArray);
    return prev.concat(multiLevel ? flatten(inner) : inner);
  },[]);
}

export function unique(collection){
  return collection.filter((x, i, a) => a.indexOf(x) === i)
}

export function collectionPresent(collection){
  return collection != null && !!collection.length
}

export function any(collection){
  return collection != null && collectionPresent(Object.values(collection).filter(x => x))
}

export function groupCollectionBy(collection, ...by){
  const grouped = {}
  collection.forEach(item => {
    const key = by.map(byValue => `${item[byValue]}`).join('::')
    grouped[key] = grouped[key] || []
    grouped[key].push(item)
  })
  return grouped
}
