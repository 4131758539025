import React, { Component } from 'react'
import instanceForm from 'containers/shared/instanceForm'
import dependsOn from 'containers/shared/dependsOn'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { compose } from 'utils'
import withStyles from 'styles'
import { provide, WebhooksContext } from 'contexts'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export class Form extends Component {

  handleSave = () => {
    return this.props.onSave(null, {
      mapFormData: (data) => ({...data, tenantId: this.props.match.params.tenantId})
    })
  }

  render = () => {
    const {formData, errors, errorStrings, onFormDataChange, editMode} = this.props
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant='h5'>{editMode ? `Edit Webhook - ${formData.url}` : 'Create a new Webhook'}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={this.handleSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name='url'/>
          <FormControlLabel
            control={
              <ControlledForm data={this.props.formData} onChange={this.props.onFormDataChange}>
                <Checkbox
                    name='enabled'
                    type='checkbox'
                    color="secondary"
                />
              </ControlledForm>
            }
            label="Enabled"
          />
          <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </ControlledForm>
      </PageContainer>
    )
  }

}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

const fetchDependencies = ({webhooks, id, editMode}) => {
  if (editMode) {
    return webhooks.actions.show(id)
  }
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('webhooks'),
  withStyles(styles),
  provide(WebhooksContext),
)(Form)