import React, { Component } from 'react'
import instanceForm    from 'containers/shared/instanceForm'
import dependsOn            from 'containers/shared/dependsOn'
import {ErrorBanner, ControlledForm, PageContainer, Select, Autocomplete} from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { compose } from 'utils'
import withStyles from 'styles'
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Authorization } from 'utils'
import { provide, UsersContext }          from 'contexts'
import Box from '@material-ui/core/Box'
import * as API from 'api'

export class Form extends Component {

  onSuggestionsFetchRequestedTenants = async (text, callback) => {
    const { data } = await API.Tenants.index({
      options: {
        fields: {authors: 'id,name'},
        filter: {name: text},
        page: { number: 1, size: 5 }
      }
    })
    callback(data)
  }

  get formData(){
    const formData = {...this.props.formData}
    if (!this.props.editMode && !Authorization.admin) {
      formData.role = 'onboarding_specialist'
    }

    return formData
  }

  render = () => {
    const formData = this.formData
    const {onFormDataChange, onSave, errors, editMode, errorStrings} = this.props
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant='h5'>{editMode ? `Edit User - ${formData.name}` : 'Create a new User'}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name='name'/>
          <TextField fullWidth name='email'/>
          <Select disabled={!Authorization.admin} fullWidth name='role'>
            <MenuItem value='onboarding_specialist'>Onboarding Specialist</MenuItem>
            <MenuItem value='admin'>Admin</MenuItem>
          </Select>
          {
            (formData.role === 'onboarding_specialist' && Authorization.admin) &&
            <Autocomplete
              fullWidth
              name='tenant'
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedTenants}
            />
          }
          {
            (!editMode) &&
            <FormControlLabel control={
              <ControlledForm onChange={onFormDataChange} data={formData}>
                <Checkbox name='skipInvite'/>
              </ControlledForm>} label="Skip Invite"
            />
          }
          <br/>
          {
            ((editMode && Authorization.user.id === this.props.users.selected.id) || formData.skipInvite) &&
            <Box p={2} bgcolor="background.default">
              <Typography variant='body1'>Password {editMode && '(Optional)'}</Typography>
              {
                Authorization.user.id === this.props.users.selected.id &&
                <TextField color='background.paper'  type="password" fullWidth name="oldPassword"/>
              }
              <TextField color='background.paper' type='password' fullWidth name='password'/>
              <TextField color='background.paper' disabled={!formData.password} type='password' fullWidth name='passwordConfirmation'/>
            </Box>
          }

          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </ControlledForm>
      </PageContainer>
    )
  }
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
})

const fetchDependencies = ({users, id, editMode}) => {
  if(editMode){
    return users.actions.show(id, {include: 'tenants'})
  }
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('users', {linked: ['tenant']}),
  withStyles(styles),
  provide(UsersContext),
)(Form)