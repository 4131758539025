import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import dependsOn from 'containers/shared/dependsOn'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { provide, WebhooksContext } from 'contexts'

export class Show extends Component{

  get webhook() {
    return this.props.webhooks.selected
  }

  get tenant() {
    return this.webhook.tenant || {}
  }

  render = () =>
    <PageContainer>
      <Typography variant='h4'>Webhook - {this.webhook.url}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/webhooks/${this.webhook.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Url</TableCell>
              <TableCell>{String(this.webhook.url || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Enabled</TableCell>
              <TableCell>{this.webhook.enabled ? "yes" : "no"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Token</TableCell>
              <TableCell>{String(this.webhook.token || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Tenant</TableCell>
              <TableCell>{String(this.tenant.name || '')}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
}

const fetchDependencies = ({webhooks,  match: { params: { id }}}) => {
  return webhooks.actions.show(id, {
    include: 'tenant'
  })
}

export default provide(WebhooksContext)(dependsOn(fetchDependencies)(Show))