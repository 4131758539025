import APIContext from 'contexts/base/APIContext'

export default class OnfidoChecksContext extends APIContext{
  static contextName = 'onfidoChecks'

  init = async() => {
    const { data } = await this.performRequest('init')()
    this.setState({ init: data })
    return data
  }

  current = async() => {
    const { data } = await this.performRequest('current')()
    this.setState({ current: data })
    return data
  }
}
