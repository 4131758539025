import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {ControlledForm, CenteredCard, ErrorBanner, PromiseButton} from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { TokensContext, SnackbarContext, consume } from 'contexts'

export class Forgot extends Component{

  state = {}

  submit = async () => {
    this.setState({submitting: true})
    await this.props.tokens.actions.forgot(this.state)
    this.props.snackbar.actions.show("Request Sent")
    this.setState({submitting: false})
  }

  renderErrorTitle = () => this.props.tokens.error.map(({title}) => title).join(', ')

  render = () =>
    <CenteredCard>
      <ControlledForm data={this.state} onChange={state => this.setState(state)} onSubmit={this.submit}>
        <CardContent>
          <Typography variant='h6'>Forgot your password?</Typography>
          {this.props.tokens.error && <ErrorBanner>{this.props.tokens.error.title}</ErrorBanner>}
          <FormGroup>
            <TextField fullWidth name='email' autoFocus/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <PromiseButton variant='contained' color='secondary' fullWidth disabled={this.state.submitting} type='submit'>Submit</PromiseButton>
        </CardActions>
        <Link className='link-small' to='/tokens/sign_in'>Sign in</Link>
      </ControlledForm>
    </CenteredCard>
}

export default consume(TokensContext, SnackbarContext)(Forgot)