import React, { Component } from 'react'
import instanceForm from 'containers/shared/instanceForm'
import dependsOn from 'containers/shared/dependsOn'
import { ControlledForm, Select }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Radio                from '@material-ui/core/Radio'
import RadioGroup           from '@material-ui/core/RadioGroup'
import FormControlLabel     from '@material-ui/core/FormControlLabel';
import FormLabel            from '@material-ui/core/FormLabel'
import MenuItem             from '@material-ui/core/MenuItem'
import { compose, humanize } from 'utils'
import withStyles from 'styles'
import { consume, provide, NotificationsContext, UsersContext } from 'contexts'

const recipientGroupSelections = ['all']

export class Form extends Component {

  state = {
    recipientsOpen: false,
  }

  recipientsSelected() {
    const recipients = this.props.formData.recipients
    return recipients && recipients.length > 0
  }

  handleRecipientChange = (event) => {
    const {onFormDataChange, formData} = this.props
    let recipients = event.target.value
    let newFormData;
    if(recipientGroupSelections.includes(recipients[recipients.length - 1])){
      newFormData = {...formData, recipients: [recipients.pop()]}
      this.setState({recipientsOpen: false})
    } else {
      newFormData = {...formData, recipients: recipients.filter((r) => !recipientGroupSelections.includes(r))}
    }
    onFormDataChange(newFormData)
  }

  render = () => {
    const {formData, onFormDataChange, onSave, errors} = this.props;
    return <Card className={this.props.classes.card}>
      <Typography variant='h5'>New Notification</Typography>
      <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
        <CardContent>
          <TextField name='title' fullWidth/>
          <TextField name='message' multiline={true} rows={5} fullWidth/>
          <FormLabel component="legend">Delivery</FormLabel>
          <RadioGroup name='delivery' label='Delivery'>
            <FormControlLabel value="app" control={<Radio />} label="In App" />
            <FormControlLabel value="email" control={<Radio />} label="In App + Email" />
          </RadioGroup>
          <Select
            multiple
            fullWidth
            onChange={this.handleRecipientChange}
            value={formData.recipients || []}
            name='recipients'
            label='Recipients'
            open={this.state.recipientsOpen}
            onOpen={() => this.setState({recipientsOpen: true})}
            onClose={() => this.setState({recipientsOpen: false})}
          >
            {recipientGroupSelections.map((recipient) =>
              <MenuItem key={recipient} value={recipient}>{humanize(recipient)}</MenuItem>
            )}
            {this.props.users.list.map((user) => <MenuItem key={user.id} value={String(user.id)}>{user.email}</MenuItem>)}
          </Select>
        </CardContent>
        <CardActions>
          <Button disabled={!this.recipientsSelected()} color='secondary' fullWidth variant='contained' type='submit'>Send</Button>
        </CardActions>
      </ControlledForm>
    </Card>
  }
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

const fetchDependencies = ({ users }) => {
  return users.actions.index()
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('notifications'),
  withStyles(styles),
  consume(NotificationsContext),
  provide(UsersContext),
)(Form)