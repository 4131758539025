export function deepGet(name, object) {
  return name.split(/(?:\[(?=\d)|(?!=\d)\]\.?|\.)/).filter(x => x !== "").reduce((memo, part) => memo ? memo[part] : null, object)
}

export function deepSet(value, name, object) {
  const attributeChain = name.split(/\[(?=\d)|(?!=\d)\]\.?|\./).filter(x => x !== "")
  const newObject = {...object}
  const valueObject = attributeChain.slice(0, -1).reduce((result, att) => (result[att] = Array.isArray(result[att]) ? [...result[att]] : {...result[att]}), newObject)
  valueObject[attributeChain[attributeChain.length-1]] = value
  return newObject
}
