import React, {Component, Fragment} from 'react'
import { humanize } from 'utils'
import Typography from "@material-ui/core/Typography";
import { LinkButton, ClosableDialogButton } from "components";



export class BeneficialOwnerOnfidoDetailsDialog extends Component {
  get name() {
    const {beneficialOwner} = this.props
    return `${beneficialOwner.firstName} ${beneficialOwner.lastName}`
  }

  colorByStatus = status => {
    return status === "complete" ? "secondary" : "error"
  }

  colorByResult = result => {
    return result === "clear" ? "secondary" : "error"
  }

  render = () => {
    const {style, beneficialOwner: { onfidoCheck }} = this.props

    return (
      <ClosableDialogButton title={`Onfido check result for ${this.name}`} buttonTitle="See more" buttonComponent={LinkButton} buttonProps={{ style }}>
        <Typography variant="h5">Check:</Typography>
        <Typography variant="subtitle2" color={this.colorByStatus(onfidoCheck.status)}>
          Status: {onfidoCheck.status}
        </Typography>
        <Typography variant="subtitle2" color={this.colorByResult(onfidoCheck.result)}>
          Result: {onfidoCheck.result}
        </Typography>
        <Typography variant="h5">Reports:</Typography>
        {onfidoCheck.reports && onfidoCheck.reports.map(report => (
          <Fragment key={report.id}>
            <Typography variant="subtitle1">{humanize(report.name)}</Typography>
            <Typography variant="subtitle2" color={this.colorByStatus(report.status)}>Status: {report.status}</Typography>
            <Typography variant="subtitle2" color={this.colorByResult(report.result)}>Result: {report.result}</Typography>
            {report.sub_result &&
              <Typography variant="subtitle2" color={this.colorByResult(report.sub_result)}>Subresult: {report.sub_result}</Typography>
            }
          </Fragment>
        ))}
        <Typography variant="subtitle1">Original reports response from onfido:</Typography>
        <pre>{JSON.stringify(onfidoCheck.reports, null, 2)}</pre>
      </ClosableDialogButton>
    )
  }
}


export default BeneficialOwnerOnfidoDetailsDialog