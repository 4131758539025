import APIContext from 'contexts/base/APIContext'

export default class BeneficialOwnersContext extends APIContext{
  static contextName = 'beneficialOwners'

  event = async(item, options={}) => {
    const { data } = await this.performRequest('event')({...item, options})
    this.setState({data, list: this.replace(this.state.list, data)})
    return data
  }

  current = async({ fields=null, include=null }={}) => {
    const { data: selected } = await this.performRequest('current')({options: { ...fields && {fields}, ...include && {include} }})
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }
}
