import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, consume, VerificationsContext, SnackbarContext } from 'contexts'
import { LinkButton, PageContainer } from 'components'
import { compose, snakeCased } from 'utils'
import withStyles from 'styles'
import Grid from '@material-ui/core/Grid'
import { humanize } from 'utils'
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import OwnershipStructureNames from "constants/OwnershipStructureNames"
import TableBody from "@material-ui/core/TableBody"
import Link from '@material-ui/core/Link'
import { BeneficialOwnerInfoDialog, BeneficialOwnerManageDialog } from "./dialogs"
import VerificationStatuses from 'constants/VerificationStatuses'


function TableEntry({ title, children }) {
  return (
      <TableRow>
        <TableCell variant='head'>{title}</TableCell>
        <TableCell>{children}</TableCell>
      </TableRow>
  )
}

const DOCUMENT_TYPES = ['nzDriversLicence', 'auDriversLicence', 'auPassport', 'passport', 'driversLicence']

export class Show extends Component{
  get verification() {
    return this.props.verifications.selected || {}
  }

  get verificationDetails() {
    return this.verification.verificationDetails || {}
  }

  get country() {
    return this.verification.country || {}
  }

  get beneficialOwners() {
    return this.verification.beneficialOwners || []
  }

  ownershipStructureName(enumCode) {
    return OwnershipStructureNames[enumCode] || enumCode
  }

  statusName(enumCode) {
    if (VerificationStatuses.hasOwnProperty(enumCode)) {
      return VerificationStatuses[enumCode]
    }

    return enumCode
  }

  renderContacts = () => {
    const { classes } = this.props

    return (
        <>
          <Typography variant="h6" className={classes.tableTitle} gutterBottom>
            Key contacts:
          </Typography>
          {this.verificationDetails.keyContacts && Object.entries(this.verificationDetails.keyContacts).map(([name, contact]) => (
            <Grid container item sm={12} key={name} className={classes.beneficialOwner}>
              <Grid item sm={4} xs={12}>
                <Typography variant="subtitle2">{humanize(name)}:</Typography>
              </Grid>
              <Grid item sm={4} xs={12}>
                {contact.firstName} {contact.lastName}
              </Grid>
              <Grid item sm={4} xs={12}>
                {contact.email}
              </Grid>
            </Grid>
          ))}
        </>
    )
  }

  renderBeneficialOwners = () => {
    const { classes } = this.props

    const hasChecks = beneficialOwner => {
      return beneficialOwner.onfidoCheck || beneficialOwner.rapidIdentityResponse
                                         || beneficialOwner.rapidDocumentResponse
                                         || beneficialOwner.onfidoError
    }

    return (
        <>
          <Typography variant="h6" className={classes.tableTitle} gutterBottom>
            Beneficial Owners:
          </Typography>
          {this.beneficialOwners.map((beneficialOwner, idx) => (
              <Grid container item sm={12} key={idx} className={classes.beneficialOwner}>
                <Grid item sm={4} xs={12}>
                  {`${beneficialOwner.firstName} ${beneficialOwner.lastName}`}
                </Grid>
                <Grid item sm={4} xs={12}>
                  <BeneficialOwnerInfoDialog beneficialOwner={beneficialOwner} />
                </Grid>
                {hasChecks(beneficialOwner) &&
                  <Grid item sm={4} xs={12}>
                    <BeneficialOwnerManageDialog beneficialOwner={beneficialOwner} buttonTitle="Checks details" />
                  </Grid>
                }
              </Grid>
          ))}
        </>
    )
  }

  render = () => {
    const {classes, title, hideStatus, children} = this.props
    return (
        <PageContainer>
          <Typography variant="h4" gutterBottom>
            {title || 'Verification'} - {this.verification.name}
          </Typography>
          <Table className={classes.table}>
            <TableBody>
              {!hideStatus &&
                <TableEntry title="Verification status">
                  {this.statusName(this.verification.status)}
                </TableEntry>
              }
              <TableEntry title="National Business Number">
                {this.verification.nationalBusinessNumber}
              </TableEntry>
              <TableEntry title="Ownership Structure">
                {this.ownershipStructureName(this.verification.ownershipStructure || '')}
              </TableEntry>
              <TableEntry title="Country">
                {this.country.name}
              </TableEntry>
              <TableEntry title="Merchant reference">
                {this.verification.reference}
              </TableEntry>
              <TableEntry title="Primary contact email">
                {this.verification.primaryContactEmail}
              </TableEntry>
              {this.verification.ownershipStructure === "trust" &&
                <TableEntry title="Trust Deed">
                  <LinkButton>
                    <Link href={this.verification.trustDeedUrlPath} target="_blank">
                      {this.verification.trustDeedName}
                    </Link>
                  </LinkButton>
                </TableEntry>
              }
              {Object.keys(this.verificationDetails).length > 0 &&
                <>
                  {this.verificationDetails.directoryDisplayName &&
                    <TableEntry title="Directory display name">
                      {this.verificationDetails.directoryDisplayName}
                    </TableEntry>
                  }
                  {this.verificationDetails.taxFileNumber &&
                    <TableEntry title="Tax file number">
                      {this.verificationDetails.taxFileNumber}
                    </TableEntry>
                  }
                  {this.verificationDetails.bankAccountNumber &&
                    <TableEntry title="Bank account number">
                      {this.verificationDetails.bankAccountNumber}
                      &nbsp;
                      <LinkButton onClick={() => window.open(this.verificationDetails.bankAccountScreenshotUrlPath)}>
                        (Screenshot)
                      </LinkButton>
                    </TableEntry>
                  }
                  {this.verificationDetails.companyLogoUrlPath &&
                    <TableEntry title="Company logo">
                      <Link href={this.verificationDetails.companyLogoUrlPath} target="_blank">
                        {this.verificationDetails.companyLogoName}
                      </Link>
                    </TableEntry>
                  }
                  {this.verificationDetails.companyLogoWhiteUrlPath &&
                    <TableEntry title="Company logo (white)">
                      <Link href={this.verificationDetails.companyLogoWhiteUrlPath} target="_blank">
                        {this.verificationDetails.companyLogoWhiteName}
                      </Link>
                    </TableEntry>
                  }
                  {this.verificationDetails.lifestyleImageUrlPath &&
                    <TableEntry title="Lifestyle image">
                      <Link href={this.verificationDetails.lifestyleImageUrlPath} target="_blank">
                        {this.verificationDetails.lifestyleImageName}
                      </Link>
                    </TableEntry>
                  }
                  {Object.keys(this.verificationDetails).length > 0 &&
                    <>
                      <TableEntry title="Commission rate">
                        {this.verificationDetails.commissionRate}
                      </TableEntry>
                      <TableEntry title="Refund commission rate">
                        {this.verificationDetails.refundCommissionRate}
                      </TableEntry>
                      <TableEntry title="Payment currency margin">
                        {this.verificationDetails.paymentCurrencyMargin}
                      </TableEntry>
                    </>
                  }
                  {this.verificationDetails.keyContacts &&
                    <TableRow>
                      <TableCell colSpan={2}>
                        {this.renderContacts()}
                      </TableCell>
                    </TableRow>
                  }
                </>
              }
              {this.beneficialOwners.length > 0 &&
                <TableRow>
                  <TableCell colSpan={2}>
                    {this.renderBeneficialOwners()}
                  </TableCell>
                </TableRow>
              }
              {children}
            </TableBody>
          </Table>
        </PageContainer>
    )
  }
}

const styles = theme => ({
  table: {
    maxWidth: 700
  },
  tableTitle: {
    fontSize: 16
  },
  beneficialOwner: {
    marginBottom: 20,
    '@media(min-width: 600px)': {
      marginBottom: 0
    }
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  }
})

const fetchDependencies = async ({verifications, match: { params: { id }}}) => {
  const documentRelationships = DOCUMENT_TYPES.map(snakeCased).map(name => `beneficialOwners.${name}`).join(",")
  return await verifications.actions.show(id, {include: `country,beneficialOwners,verificationDetails,beneficialOwners.onfidoCheck,beneficialOwners.rapidIdentityResponse,beneficialOwners.rapidDocumentResponse,beneficialOwners.address,beneficialOwners.overriddenBy,${documentRelationships}`})
}

export default compose(
    dependsOn(fetchDependencies),
    withStyles(styles),
    provide(VerificationsContext),
    consume(SnackbarContext),
)(Show)