import Box from "@material-ui/core/Box";
import React, {Component} from "react";
import PropTypes from 'prop-types'
import Select from "./Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from 'styles'
import { compose } from 'utils'
import moment from "moment";
import Typography from "@material-ui/core/Typography";


export class DatePickerSelect extends Component{
  static propTypes = {
    yearsOrder: PropTypes.oneOf(["asc", "desc"]),
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    yearsOrder: "asc",
    fullWidth: false
  }

  state = {
    day: '',
    month: '',
    year: ''
  }

  formatDate = (day, month, year) => {
    if (!day || !month || !year) {
      return null
    }

    const daysInMonth = moment({month: month - 1, year}).daysInMonth()
    if (day > daysInMonth) {
      day = daysInMonth
    }

    return new moment({ year, month: month - 1, date: day }).format('YYYY-MM-DD')
  }

  get date() {
    return moment(this.props.value, "YYYY-MM-DD")
  }

  get day() {
    return this.date.date() || this.state.day
  }

  get month() {
    return this.date.month() + 1 || this.state.month
  }

  get year() {
    return this.date.year() || this.state.year
  }

  get maxDays() {
    if (!this.year || !this.month) {
      return 31
    }

    return moment({month: this.month - 1, year: this.year}).daysInMonth()
  }

  handleChange = (date) => {
    this.props.onChange && date && this.props.onChange({
      target: {
        value: date
      }
    })
  }

  handleDayChange = ({ target: { value: day } }) => {
    this.setState({ day })
    this.handleChange(this.formatDate(day, this.month, this.year))
  }

  handleMonthChange = ({ target: { value: month } }) => {
    this.setState({ month })
    this.handleChange(this.formatDate(this.day, month, this.year))
  }

  handleYearChange = ({ target: { value: year } }) => {
    this.setState({ year })
    this.handleChange(this.formatDate(this.day, this.month, year))
  }

  integers = (from, to, reverseOrder=false) => {
    const arr = []
    while (from <= to) {
      arr.push(from)
      from++
    }

    if (reverseOrder) {
      arr.reverse()
    }

    return arr
  }

  render() {
    const currentYear = new Date().getFullYear()
    const { classes, label, fullWidth, yearsOrder } = this.props
    const minYear = this.props.minYear || 1900
    const maxYear = this.props.maxYear || currentYear + 100

    return (
      <Box className={classes.root}>
        {label &&
          <Typography variant="subtitle2" className={classes.label}>{label}</Typography>
        }
        <Box className={classes.dateWrapper} display={fullWidth ? "flex" : "box"}>
          <Select fullWidth={fullWidth} label={this.day ? '' : 'Day'} classes={{ root: classes.daySelect }} value={this.day} onChange={this.handleDayChange}>
            {this.integers(1, this.maxDays).map((e,i) =>
              <MenuItem value={i+1} key={i+1}>{i+1}</MenuItem>
            )}
          </Select>
          <Select fullWidth={fullWidth} label={this.month ? '' : 'Month'} classes={{ root: classes.monthSelect }} value={this.month} onChange={this.handleMonthChange}>
            <MenuItem value={1}>January</MenuItem>
            <MenuItem value={2}>February</MenuItem>
            <MenuItem value={3}>March</MenuItem>
            <MenuItem value={4}>April</MenuItem>
            <MenuItem value={5}>May</MenuItem>
            <MenuItem value={6}>June</MenuItem>
            <MenuItem value={7}>July</MenuItem>
            <MenuItem value={8}>August</MenuItem>
            <MenuItem value={9}>September</MenuItem>
            <MenuItem value={10}>October</MenuItem>
            <MenuItem value={11}>November</MenuItem>
            <MenuItem value={12}>December</MenuItem>
          </Select>
          <Select fullWidth={fullWidth} label={this.year ? '' : 'Year'} classes={{ root: classes.yearSelect }} value={this.year} onChange={this.handleYearChange}>
            {this.integers(minYear, maxYear, yearsOrder === "desc").map((y) =>
              <MenuItem value={y} key={y}>{y}</MenuItem>
            )}
          </Select>
        </Box>
      </Box>
    )
  }
}

const styles = theme => ({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    "&:focus-within": {
      color: theme.palette.primary.main
    }
  },
  daySelect: {
    minWidth: 70,
    maxWidth: 80,
    marginRight: 10,
    '@media(min-width: 600px)': {
      maxWidth: 'unset',
    }
  },
  monthSelect: {
    minWidth: 100,
    marginRight: 10,
    flexGrow: 2
  },
  yearSelect: {
    minWidth: 80,
    maxWidth: 90,
    '@media(min-width: 600px)': {
      maxWidth: 'unset',
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
  },
  dateWrapper: {
    margin: '-15px 0 0 0',
  }
})

export default compose(
    withStyles(styles),
)(DatePickerSelect)