import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, BeneficialOwnersContext, VerificationsContext } from 'contexts'
import instanceForm from 'containers/shared/instanceForm'
import {ErrorBanner, VerificationStep, Select, LinkButton, DestroyButton} from 'components'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid';
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import {errorStringsFromError} from 'utils'

export class BeneficialOwners extends Component{

  get verification() {
    return this.props.verifications.selected
  }

  handleNewRow = () => {
    this.props.onFormDataChange({...this.props.formAttributes, beneficialOwners: [...this.beneficialOwners, {}]})
  }

  handleRemoveRow = (idx) => () => {
    this.props.verifications.actions.clearErrors()

    const beneficialOwners = [...this.props.formData.beneficialOwners]
    const beneficialOwner = beneficialOwners[idx]

    if(beneficialOwner.id){
      beneficialOwners[idx] = {...beneficialOwner, _destroy: true}
    }else{
      beneficialOwners.splice(idx, 1)
    }

    this.props.onFormDataChange({...this.props.formAttributes, beneficialOwners})
  }

  get errorStrings() {
    return errorStringsFromError(this.props.verifications.errors['update'])
  }

  get prevStep() {
    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verification-step/organisation/${verificationId}`
    }

    return "/verification-step/organisation"
  }

  get unacceptableBusinessStructure() {
    return this.beneficialOwners.some(({ownershipStatus}) => ["trust", "business_entity"].includes(ownershipStatus))
  }

  get nextStep() {
    if (this.unacceptableBusinessStructure) {
      return "/manual-verification"
    }
    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verification-step/review-submission/${verificationId}`
    }

    return "/verification-step/review-submission"
  }

  get beneficialOwners() {
    if (!this.props.formData.beneficialOwners || this.props.formData.beneficialOwners.length === 0) {
      return [{}]
    }
    return this.props.formData.beneficialOwners
  }

  handleSave = () => {
    const nextStep = this.unacceptableBusinessStructure ? undefined : "review-submission"
    this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      params: {verificationId: this.verification.id},
      mapFormData: (data) => ({...data, currentStep: nextStep})
    })
  }

  render = () => {
    const {formData, onFormDataChange, classes} = this.props

    return (
      <VerificationStep
        title='Beneficial Owners'
        formData={formData}
        errors={(this.props.verifications.errors['update'] || {}).meta}
        onFormDataChange={onFormDataChange}
        onSave={this.handleSave}
        onGoBack={() => this.props.history.push(this.prevStep)}
      >
        <Box className={this.props.classes.body}>
          <Typography variant='body1'>The law requires that we verify beneficial owners.</Typography>
          <Typography variant='body1'>That means every individual with more than a 25% shareholding,</Typography>
          <Typography variant='body1'>or individuals with effective control (eg. Directors) of the company.</Typography>
        </Box>
        <ErrorBanner>{this.errorStrings}</ErrorBanner>
        {this.verification.reviewFeedback &&
          <>
            <Typography variant='subtitle1' className={classes.reviewFeedback} gutterBottom>
              Comment from onboarding specialist: {this.verification.reviewFeedback}
            </Typography>
          </>
        }
        <Grid container spacing={1}>
          {this.beneficialOwners.map((bo, idx) => (
            bo._destroy ? false :
            <Grid container item md={12} spacing={2} key={idx} className={classes.row}>
              <Grid item md={2} xs={12}>
                <TextField fullWidth name={`beneficialOwners[${idx}].firstName`}/>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField fullWidth name={`beneficialOwners[${idx}].lastName`}/>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField fullWidth name={`beneficialOwners[${idx}].email`}/>
              </Grid>
              <Grid item md={4} xs={12}>
                <Select fullWidth name={`beneficialOwners[${idx}].ownershipStatus`}>
                  <MenuItem value='shareholder'>Shareholder (more than 25%)</MenuItem>
                  <MenuItem value='director'>Director</MenuItem>
                  {this.props.verifications.selected.ownershipStructure === "trust" &&
                    <MenuItem value='trustee'>Trustee</MenuItem>
                  }
                  <MenuItem value='other'>Other person with effective control</MenuItem>
                  <MenuItem value='trust'>Trust</MenuItem>
                  <MenuItem value='business_entity'>Business Entity</MenuItem>
                </Select>
              </Grid>
              <Grid item md={1} xs={12}>
                <TextField fullWidth name={`beneficialOwners[${idx}].holdings`} label="Holdings"/>
              </Grid>
              <Grid item md={1} xs={12} className={classes.destroyButton}>
                <DestroyButton onDestroy={this.handleRemoveRow(idx)} name={`books[${idx}].destroy`}/>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <LinkButton classes={{ root: classes.addRowButton }} onClick={this.handleNewRow}>
          + Add another Owner
        </LinkButton>
      </VerificationStep>
    )
  }
}

const styles = {
  body: {
    marginBottom: 45,
  },
  addRowButton: {
    textDecoration: 'unset'
  },
  destroyButton: {
    marginTop: 10
  },
  row: {
    marginBottom: 50,
    borderBotton: 'solid black 1px',
    '@media(min-width: 600px)': {
      marginBottom: 'unset'
    }
  },
  reviewFeedback: {
    color: '#ff0800'
  }
}

const fetchDependencies = ({verifications,  match: { params: { verificationId }}}) => {
  if (verificationId) {
    return verifications.actions.show(verificationId, { include: 'beneficialOwners' })    
  }

  return verifications.actions.current({include: 'beneficialOwners'})
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('verifications', {nested: ['beneficialOwners']}),
  withStyles(styles),
  provide(BeneficialOwnersContext, VerificationsContext),
)(BeneficialOwners)