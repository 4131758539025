import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AuditIcon from '@material-ui/icons/Security'
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import DocumentIcon from '@material-ui/icons/Description'
import AppsIcon from '@material-ui/icons/Apps';
import NotificationIcon from '@material-ui/icons/Inbox'
import VerificationIcon from '@material-ui/icons/Policy';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose, Authorization  } from 'utils'
import withStyles from 'styles'
import { consume, provide, TokensContext, VersionsContext, NotificationsContext } from 'contexts'
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'
import { NotificationsPopup } from 'containers/notifications'
import { LaybuyMono } from 'components'

export class Nav extends Component{

  state = { drawerOpen: false }

  componentDidMount(){
    this.props.versions.actions.getTag()
  }

  handleRequestOpen = itemName => event => {
    this.setState({ [`${itemName}Open`]: !this.open(itemName), anchorEl: event.currentTarget });
  }

  handleRequestClose = itemName => () => {
    this.setState({ [`${itemName}Open`]: false});
  }

  open = itemName => !!this.state[`${itemName}Open`]

  get unreadNotifications() {
    return this.props.notifications.unread
  }

  render = () =>
    <Fragment>
      <NotificationsPopup show={this.open('notifications')} onClose={this.handleRequestOpen('notifications')} />
      <AppBar position="sticky">
        <Toolbar>
          <Typography className={this.props.classes.title} variant="h6" color="inherit">
            <LaybuyMono />
            <Link to='/'>Onboarding</Link>
          </Typography>
          <Tooltip title="Notifications">
            <IconButton color="inherit" onClick={this.handleRequestOpen('notifications')}>
              <Badge badgeContent={this.unreadNotifications} invisible={this.unreadNotifications < 1} color="error">
                <NotificationIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          {Authorization.typeUser &&
            <>
              <IconButton color="inherit" aria-label="Menu" onClick={() => this.setState({drawerOpen: true})}>
                <MenuIcon/>
              </IconButton>
              <IconButton color="inherit" onClick={this.handleRequestOpen('userMenu')}>
                <PersonIcon/>
              </IconButton>
            </>
          }
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={this.open('userMenu')}
        onClose={this.handleRequestClose('userMenu')}
      >
        <Link to="/profile"><MenuItem>My Profile</MenuItem></Link>
        <MenuItem onClick={this.props.tokens.actions.destroy}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{paper: this.props.classes.drawer}}
        open={this.state.drawerOpen}
        onClose={() => this.setState({drawerOpen: false})}
        onClick={() => this.setState({drawerOpen: false})}
      >
        <Divider />
        {
          Authorization.admin &&
          <Link to='/tenants'>
            <ListItem button>
              <ListItemIcon>
                <AppsIcon/>
              </ListItemIcon>
              <ListItemText primary="Tenants" />
            </ListItem>
          </Link>
        }
        <Link to='/verifications'>
          <ListItem button>
            <ListItemIcon>
              <VerificationIcon/>
            </ListItemIcon>
            <ListItemText primary="Verifications" />
          </ListItem>
        </Link>
        <Link to='/users'>
          <ListItem button>
            <ListItemIcon>
              <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </Link>
        {
          Authorization.admin &&
          <Link to='/notifications'>
            <ListItem button>
              <ListItemIcon>
                <NotificationIcon/>
              </ListItemIcon>
              <ListItemText primary="Notifications" />
            </ListItem>
          </Link>
        }
        {
          Authorization.admin &&
          <Link to='/apidocs'>
            <ListItem button>
              <ListItemIcon>
                <DocumentIcon/>
              </ListItemIcon>
              <ListItemText primary="Api Docs" />
            </ListItem>
          </Link>
        }
        { Authorization.admin &&
          <Link to='/audits'>
            <ListItem button>
              <ListItemIcon>
                <AuditIcon />
              </ListItemIcon>
              <ListItemText primary="Audits" />
            </ListItem>
          </Link>
        }
        <div className={this.props.classes.version}>
          API {this.props.versions.tag} / CLIENT {process.env.REACT_APP_VERSION}
        </div>
      </Drawer>
    </Fragment>
}

const styles = theme => ({
  title: {
    flex: 1,
    '& > div': {
      top: 3,
      position: 'relative',
      display: 'inline-block',
      marginTop: 5,
      marginRight: 15
    }
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  },
  drawer: {
    background: theme.palette.primary.dark,
    '& a, & svg': {
      color: 'white',
      fill: 'white !important'
    }
  }
})
export default compose(
  consume(TokensContext, NotificationsContext),
  provide(VersionsContext),
  withStyles(styles)
)(Nav)