// DO NOT edit this file, add extentions in index.js.
// Changes to this file will be overwritten when regenerating

import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export class ApiPathError extends Error {
  constructor(action, params) {
    super(`No route for ${action} matches the provided params: ${JSON.stringify(params)}`)
    this.action = action
    this.params = params
  }
}

export const API = ProtectedJsonAPI.extend(
  '',
  {
    routeNotFound: endpoint => endpoint.path(() => {
      return '/'
    }),
    tag: endpoint => endpoint.path(() => {
      return '/tag'
    }),
  }
)

export const Tokens = ProtectedJsonAPI.extend(
  '',
  {
    forgot: endpoint => endpoint.method('post').path(() => {
      return '/tokens/forgot'
    }),
    reset: endpoint => endpoint.method('post').path(() => {
      return '/tokens/reset'
    }),
    confirm: endpoint => endpoint.method('post').path(() => {
      return '/tokens/confirm'
    }),
    resendConfirmation: endpoint => endpoint.method('post').path(() => {
      return '/tokens/resend_confirmation'
    }),
    acceptInvite: endpoint => endpoint.method('post').path(() => {
      return '/tokens/accept_invite'
    }),
    update: endpoint => endpoint.method('put').path(() => {
      return '/tokens'
    }),
    destroy: endpoint => endpoint.method('delete').path(() => {
      return '/tokens'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tokens'
    }),
  }
)

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/users'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/users'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/users/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const BeneficialOwners = ProtectedJsonAPI.extend(
  '',
  {
    resendInvite: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/beneficial_owners/${id}/resend_invite`
      }
      throw new ApiPathError('resend_invite', {id})
    }),
    event: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/beneficial_owners/${id}/event`
      }
      throw new ApiPathError('event', {id})
    }),
    current: endpoint => endpoint.path(() => {
      return '/beneficial_owners/current'
    }),
    index: endpoint => endpoint.path(() => {
      return '/beneficial_owners'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/beneficial_owners'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/beneficial_owners/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/beneficial_owners/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/beneficial_owners/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Verifications = ProtectedJsonAPI.extend(
  '',
  {
    event: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/verifications/${id}/event`
      }
      throw new ApiPathError('event', {id})
    }),
    current: endpoint => endpoint.path(() => {
      return '/verifications/current'
    }),
    index: endpoint => endpoint.path(() => {
      return '/verifications'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/verifications'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/verifications/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/verifications/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/verifications/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Countries = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/countries'
    }),
  }
)

export const Webhooks = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(({tenantId}) => {
      if (tenantId) {
        return `/tenants/${tenantId}/webhooks`
      }
      throw new ApiPathError('index', {tenantId})
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/webhooks'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/webhooks/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/webhooks/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/webhooks/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Tenants = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/tenants'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/tenants'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/tenants/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/tenants/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/tenants/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const Audits = ProtectedJsonAPI.extend(
  '',
  {
    index: endpoint => endpoint.path(() => {
      return '/audits'
    }),
  }
)

export const Notifications = ProtectedJsonAPI.extend(
  '',
  {
    resend: endpoint => endpoint.method('post').path(({id}) => {
      if (id) {
        return `/notifications/${id}/resend`
      }
      throw new ApiPathError('resend', {id})
    }),
    mine: endpoint => endpoint.path(() => {
      return '/notifications/mine'
    }),
    index: endpoint => endpoint.path(() => {
      return '/notifications'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/notifications'
    }),
    show: endpoint => endpoint.path(({id}) => {
      if (id) {
        return `/notifications/${id}`
      }
      throw new ApiPathError('show', {id})
    }),
    update: endpoint => endpoint.method('put').path(({id}) => {
      if (id) {
        return `/notifications/${id}`
      }
      throw new ApiPathError('update', {id})
    }),
    destroy: endpoint => endpoint.method('delete').path(({id}) => {
      if (id) {
        return `/notifications/${id}`
      }
      throw new ApiPathError('destroy', {id})
    }),
  }
)

export const OnfidoChecks = ProtectedJsonAPI.extend(
  '',
  {
    current: endpoint => endpoint.path(() => {
      return '/onfido_checks/current'
    }),
    init: endpoint => endpoint.method('post').path(() => {
      return '/onfido_checks/init'
    }),
    create: endpoint => endpoint.method('post').path(() => {
      return '/onfido_checks'
    }),
  }
)

export const Onfido = ProtectedJsonAPI.extend(
  '',
  {
    webhook: endpoint => endpoint.method('post').path(() => {
      return '/onfido/webhook'
    }),
  }
)
