import React, {Component} from 'react'
import Typography from "@material-ui/core/Typography";
import { LinkButton, ClosableDialogButton } from "components";

export class BeneficialOwnerRapidDocumentDetailsDialog extends Component {
  get name() {
    const { beneficialOwner } = this.props
    return `${beneficialOwner.firstName} ${beneficialOwner.lastName}`
  }

  render = () => {
    const {style, beneficialOwner: { rapidDocumentResponse }} = this.props
    const responseBody = JSON.parse(rapidDocumentResponse.body)
    const verified = rapidDocumentResponse.verified

    let responseCode = '', responseText = '', message = '';
    if (rapidDocumentResponse.type.startsWith("Rapid::AU")) {
      const responseCodeHash = { 'Y': 'Yes', 'N': 'No', 'S': 'Server Error' }
      responseCode = responseBody?.VerifyDocumentResult?.VerificationResultCode
      responseText = responseCodeHash[responseCode]
    }
    else if (rapidDocumentResponse.type.startsWith("Rapid::NZ")) {
      const responseCodeHash = { 0: 'Full Match', 1: 'Partial Match', 2: 'No Match', 3: 'Error' }
      message = responseBody?.Message
      responseCode = responseBody?.Status
      responseText = responseCodeHash[responseCode]
    }

    return (
      <ClosableDialogButton title={`RapidID document check result for ${this.name}`} buttonTitle="See more" buttonComponent={LinkButton} buttonProps={{ style }}>
        <Typography variant="h5">Check:</Typography>
        <Typography variant="subtitle2" color={verified ? "secondary" : "error"}>
          Response: {responseCode} ({responseText})
        </Typography>
        {message && <Typography variant="subtitle2" color={verified ? "secondary" : "error"}>
          Message: {message}
        </Typography>}
        <Typography variant="subtitle1">Original response from RapidID:</Typography>
        <pre>{JSON.stringify(responseBody, null, 2)}</pre>
      </ClosableDialogButton>
    )
  }
}

export default BeneficialOwnerRapidDocumentDetailsDialog