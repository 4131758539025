import React, { Component } from 'react'
import { Pagination, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import NotificationIcon from '@material-ui/icons/Message'
import { compose, userFriendlyDate } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { consume, SnackbarContext, NotificationsContext } from 'contexts'
import withStyles from 'styles'

export class List extends Component{

  state = {
    page: 1
  }

  get notifications() {
    return this.props.notifications.mine[this.props.notifications.minePage] || []
  }

  linkToAction = notification => () => {
    this.props.notifications.actions.update({...notification, read: true})
                                    .then(this.props.onDependencyUpdate)
    if(notification.linkTo) {
      this.props.history.push('/' + notification.linkTo)
    }
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  componentWillUnmount = () => {
    // reset current page to avoid fetching it in the background (triggered by popup updates)
    this.props.notifications.actions.resetMinePage()
  }

  renderNotificationListItem = (notification) => {
    const {id, title, message, createdAt, read, typeDisplayName} = notification
    return (
      <ListItem button onClick={this.linkToAction(notification)} key={id} className={this.props.classes({read})}>
        <ListItemIcon>
          <NotificationIcon />
        </ListItemIcon>
        <ListItemText primary={
            <div className={this.props.classes.notifcationTextPrimary}>
              <span className={this.props.classes.notificationContent}>
                {title} ({userFriendlyDate(createdAt)})
              </span>
              <span className={this.props.classes.notificationTypeContent}>
                {typeDisplayName}
              </span>
            </div>
          }
          secondary={message}
        />
      </ListItem>
    )
  }

  render = () =>
    <PageContainer>
      <Typography variant='h4'>My Notifications</Typography>
      <Pagination totalPages={this.props.notifications.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.notifications.map(this.renderNotificationListItem)}
      </MuiList>
      <Pagination totalPages={this.props.notifications.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

const fetchDependencies = ({notifications, page}) => {
  return notifications.actions.mine({page, filter: {read: false}})
}


const styles = {
  read: {
    opacity: 0.6
  },
  notifcationTextPrimary: {
    display: 'flex',
    flexDirection: 'row'
  },
  notificationContent: {
    flex: 1
  },
  notificationTypeContent: {
    color: "#283888",
    fontSize: "0.9em",
    textAlign: "right",
    marginLeft: 50,
    marginRight: -15
  }
}


export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('notifications'),
  withStyles(styles),
  consume(SnackbarContext, NotificationsContext),
)(List)