import React, { Component } from 'react'
import { PageContainer } from "components"
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography"
import { compose } from "utils"
import withStyles from 'styles'
import ResultIcon from "../../../components/ResultIcon";

class OnfidoComplete extends Component {
  render() {
    const { classes } = this.props
    return (
      <PageContainer classes={ {content: classes.cardContent} }>
        <Box className={classes.wrapper}>
          <ResultIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 56 56">
              <title>complete-tick</title>
              <g fill="none" fill-rule="evenodd">
                <circle fill="#353FF4" cx="28" cy="28" r="28"/>
                <path d="M37.723 16.373l-13.69 14.53-3.423-3.633c-1.512-1.6-3.964-1.6-5.476 0-1.512 1.608-1.512 4.214 0 5.813l6.16 6.544c1.513 1.61 3.964 1.61 5.477 0l16.43-17.44c1.51-1.61 1.51-4.215 0-5.814-1.513-1.61-3.964-1.61-5.477 0z" fill="#FFF"/>
              </g>
            </svg>
          </ResultIcon>
          <Box className={classes.text}>
            <Typography variant="h4" gutterBottom>Verification complete</Typography>
            <Typography variant="subtitle2" gutterBottom>
              Thanks for getting that sorted.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              We’ll be in touch once your merchant account is ready to launch.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              You can close or exit this page.
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    );
  }
}


const styles = {
  cardContent: {
    height: '90%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
    height: '100%'
  },
  text: {
    marginTop: 15
  }
}

export default compose(
  withStyles(styles),
)(OnfidoComplete)

