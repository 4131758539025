import React from "react"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { ControlledForm } from "components"

export function PrivacyPolicy({ style, checked, onChange, formData, onFormDataChange }) {
  const controlledForm = Boolean(formData)
  const handleChange = onChange ? (e) => onChange(e.target.checked) : undefined
  let uncontrolledProps = {}
  if (!controlledForm) {
    uncontrolledProps = {
      onChange: handleChange,
      checked: checked
    }
  }
  const checkbox = (
    <Checkbox label="accept"
              type="checkbox"
              name="privacyPolicyAccepted"
              color="secondary"
              {...uncontrolledProps} />
  )
  let formControl
  if (controlledForm) {
    formControl = (
      <ControlledForm data={formData} onChange={onFormDataChange}>
        {checkbox}
      </ControlledForm>
    )
  } else {
    formControl = checkbox
  }

  return (
    <Box style={style}>
      <Typography variant="h6" gutterBottom>
        Accept Privacy Policy
      </Typography>
      <Typography variant="body1">
        I understand that Laybuy is relying on the accurate
        provision of the information contained herein to initiate
        payments to me, and to comply with their own regulatory requirements.
        This includes consenting to making enquiries on and verifying the
        identity information provided in the "Beneficial Owners" section above.
        This may include calls to third party identity verification services
        which may then check the supplied details with the document issuer
        or official record holder on any database (public or otherwise)
        and may hold a record of such checks. These checks are for the
        purpose of identity verification and I confirm that I am authorised
        to provide the personal details presented and have obtained the consent
        of the individuals for this purpose. Laybuy does not accept responsibility
        for any incorrect information provided above, including lost or untraceable payments.
      </Typography>
      <Box mt={1}>
        <FormControlLabel
          control={formControl}
          label="I Accept" />
      </Box>
    </Box>
  )
}

export default PrivacyPolicy