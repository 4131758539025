import React, {Component} from 'react'
import Typography from "@material-ui/core/Typography"
import { LinkButton, ClosableDialogButton } from "components"
import { humanize } from "utils"

export class BeneficialOwnerRapidAmlDetailsDialog extends Component {
  get name() {
    const { beneficialOwner } = this.props
    return `${beneficialOwner.firstName} ${beneficialOwner.lastName}`
  }

  render = () => {
    const {style, beneficialOwner: { rapidAmlResponse }} = this.props
    const responseBody = JSON.parse(rapidAmlResponse.body)
    const matchStatus = responseBody?.content?.data?.match_status
    const riskLevel = responseBody?.content?.data?.risk_level

    return (
      <ClosableDialogButton title={`RapidID AML OFAC & PEP check result for ${this.name}`} buttonTitle="See more" buttonComponent={LinkButton} buttonProps={{ style }}>
        <Typography variant="h5">Check:</Typography>
        <Typography variant="subtitle2" color={rapidAmlResponse.verified ? "secondary" : "error"}>
          Match Status: {humanize(matchStatus)}
        </Typography>
        <Typography variant="subtitle2" color={rapidAmlResponse.verified ? "secondary" : "error"}>
          Risk Level: {humanize(riskLevel)}
        </Typography>
        <Typography variant="subtitle1">Original response from RapidID:</Typography>
        <pre>{JSON.stringify(responseBody, null, 2)}</pre>
      </ClosableDialogButton>
    )
  }
}

export default BeneficialOwnerRapidAmlDetailsDialog