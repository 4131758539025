import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, VerificationsContext } from 'contexts'
import instanceForm from 'containers/shared/instanceForm'
import {ErrorBanner, VerificationStep, Select, FileUpload} from 'components'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import * as API from 'api'

export class Organisation extends Component{
  state = {
    acceptableBusinessStructure: null
  }

  get verification() {
    return this.props.verifications.selected
  }

  get nextStep() {
    if (!this.state.acceptableBusinessStructure) {
      return "/manual-verification"
    }

    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verification-step/beneficial-owners/${verificationId}`
    }

    return "/verification-step/beneficial-owners"
  }

  handleFileSelect = name => file => {
    this.props.onFormDataChange({ ...this.props.formData, [`${name}Name`]: file.name, [name]: file, [`${name}UrlPath`]: undefined })
  }

  handleFormDataChange = ({ownershipStructure, ...updatedData}) => {
    switch(ownershipStructure){
    case 'trust':
      break;
    default:
      updatedData.trustDeed        = null
      updatedData.trustDeedName    = null
      updatedData.trustDeedUrlPath = null
      break;
    }

    this.props.onFormDataChange({ownershipStructure, ...updatedData})
  }

  setInProgressStatus = async () => {
    if (this.verification.status === "draft" || this.verification.status === "invited") {
      await API.Verifications.event({
        name: 'start',
        id: this.verification.id
      })
    }
  }

  handleSave = () => {
    const nextStep = this.state.acceptableBusinessStructure ? "beneficial-owners" : undefined
    this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      afterSave: this.setInProgressStatus,
      mapFormData: (data) => ({...data, currentStep: nextStep})
    })
  }

  render = () => {
    const {formData, errors, errorStrings, classes} = this.props
    return (
      <VerificationStep
        title='Welcome to Your Merchant Dashboard'
        errors={errors}
        formData={formData}
        onFormDataChange={this.handleFormDataChange}
        onSave={this.handleSave}
        saveButtonDisabled={this.state.acceptableBusinessStructure === null}
      >
        <Box className={this.props.classes.body}>
          <Typography variant='body1'>We are required by law to verify the identity of our partners</Typography>
          <Typography variant='body1'>in accordance with Anti-Money Laundering legislation.</Typography>
          <Typography variant='body1'>This means we need to confirm a few details.</Typography>
        </Box>
        <Box style={{ maxWidth: 330 }}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          {this.verification.reviewFeedback &&
            <>
              <Typography variant='subtitle1' className={classes.reviewFeedback} gutterBottom>
                Comment from onboarding specialist: {this.verification.reviewFeedback}
              </Typography>
            </>
          }
          <TextField fullWidth name='nationalBusinessNumber'/>
          <Select fullWidth name='ownershipStructure'>
            <MenuItem value='company'>Company</MenuItem>
            <MenuItem value='partnership'>Partnership</MenuItem>
            <MenuItem value='sole_trader_individual'>Sole Trader / Individual</MenuItem>
            <MenuItem value='trust'>Trust</MenuItem>
          </Select>
          {formData.ownershipStructure === "trust" &&
            <FileUpload onFileSelect={this.handleFileSelect("trustDeed")}
                        fileName={formData.trustDeedName}
                        urlPath={formData.trustDeedUrlPath}
                        accept="application/pdf"
                        fullWidth
                        name="trustDeed" />

          }
          <Box mt={1}>
            <Typography variant="body1">
              Are any of Your Beneficial Owners a Trust or Business Entity?
            </Typography>
            <Select fullWidth
                    label=""
                    onChange={e => this.setState({acceptableBusinessStructure: e.target.value === "no"})}>
              <MenuItem value='yes'>Yes</MenuItem>
              <MenuItem value='no'>No</MenuItem>
            </Select>
          </Box>
        </Box>
      </VerificationStep>
    )
  }
}

const styles = {
  body: {
    marginBottom: 45,
    marginTop: 30
  },
  uploadInput: {
    display: 'none'
  },
  uploadButton: {
    margin: "-6px 0 3px 0"
  },
  reviewFeedback: {
    color: '#ff0800'
  }
}

const fetchDependencies = ({verifications,  match: { params: { verificationId }}}) => {
  if (verificationId) {
    return verifications.actions.show(verificationId)
  }

  return verifications.actions.current()
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('verifications'),
  withStyles(styles),
  provide(VerificationsContext),
)(Organisation)