import React, { Component, Fragment } from 'react'
import { SessionStatus } from '../../constants'
import * as Containers from 'containers'
import { withRouter } from 'react-router-dom'
import { throttle, compose, Authorization } from 'utils'
import { Routes, Nav } from '.'
import CssBaseline from '@material-ui/core/CssBaseline'
import { provide, SnackbarContext, TokensContext, NotificationsContext } from 'contexts'
import withStyles from 'styles'

export class App extends Component{

  static activityEventTypes = ['mousemove', 'click', 'keydown', 'resize']

  componentDidMount(){
    this.props.tokens.actions.registerActivity()
    this.props.tokens.actions.verify(true)
  }

  renderRoutes = () => {
    switch(this.props.tokens.loginState){
    case SessionStatus.UNKNOWN:         { return <Routes.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Routes.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Routes.Unauthenticated/> }
    default: { return false }
    }
  }

  renderNav = () => {
    if (this.props.tokens.loginState === SessionStatus.AUTHENTICATED && Authorization.typeBeneficialOwner) {
      return false
    }

    switch(this.props.tokens.loginState){
    case SessionStatus.UNKNOWN:         { return <Nav.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Nav.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Nav.Unauthenticated/> }
    default: { return false }
    }
  }

  registerActivity = throttle(() => {
    this.props.tokens.actions.registerActivity()
  })

  monitorActivity = container => {
    if(this.container){
      App.activityEventTypes.forEach(type => this.container.removeEventListener(type, this.registerActivity))
    }
    this.container = container
    if(this.container){
      App.activityEventTypes.forEach(type => this.container.addEventListener(type, this.registerActivity))
    }
  }

  render = () =>
    <Fragment>
      <CssBaseline />
      {this.renderNav()}
      <div className={this.props.classes.contentWrapper} ref={this.monitorActivity}>
        {this.renderRoutes()}
      </div>
      <Containers.Shared.Snackbar/>
    </Fragment>
}

const styles = theme => ({
  contentWrapper: {
    flex: '1 0 auto',
    display: 'flex',
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      //IE ONLY
      minHeight: 'calc(100vh - 130px)'
    },
    '& > div': {
      flex: '1 0 auto',
      maxWidth: '100%'
    }
  }
})

export default compose(
  provide(TokensContext, SnackbarContext, NotificationsContext),
  withStyles(styles),
  withRouter
)(App)