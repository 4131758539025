import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'

import Button from '@material-ui/core/Button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

export class ConfirmationDialog extends PureComponent {
  static propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool,
    title: PropTypes.node.isRequired,
    value: PropTypes.any,
    canCancel:  PropTypes.bool.isRequired,
    canConfirm: PropTypes.bool.isRequired,
    theme: PropTypes.string,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = { value: this.defaultValue }
  }

  static defaultProps = {
    onConfirm: () => {},
    canCancel: true,
    canConfirm: true,
    confirmLabel: 'OK',
    cancelLabel: 'CANCEL',
    theme: 'light'
  }

  componentDidUpdate(prevProps) {
    if(this.props.open && !prevProps.open) {
      this.resetValue()
    }
  }

  resetValue() {
    this.setState({ value: this.defaultValue })
  }

  get defaultValue() {
    let { value } = this.props
    return (value ? value : '')
  }

  get value() {
    return this.state.value ? this.state.value.trim() : ''
  }

  handleCancel = () => {
    this.props.onCancel(this.value)
  }

  handleConfirm = e => {
    if (this.buttonRef.form.checkValidity()) {
      e.preventDefault()
      this.props.onConfirm(this.value)
    }
  }

  canCancel(){
    return !!this.props.canCancel
  }

  canConfirm(){
    return !!this.props.canConfirm
  }

  setValue(value) {
    this.setState({ value })
  }

  renderBody() {
    return this.props.children
  }

  get hasCancelHandler() {
    return !!this.props.onCancel
  }

  render = () => {
    const dialogClassname = this.props.className || ''
    const paperClassname = `${styles.paper} ${dialogClassname}`
    const {classes} = this.props

    return (
      <Dialog open={this.props.open} classes={{paper: paperClassname}} className={classes.root}>
        <form onSubmit={this.handleConfirm}>
          <DialogTitle>
            {this.props.title}
          </DialogTitle>
          <DialogContent className={classes.content}>
            { this.renderBody() }
          </DialogContent>
          <DialogActions>
            { this.hasCancelHandler &&
              <Button className={classes.button}
                variant='contained' onClick={this.handleCancel}
                color='primary' disabled={ !this.canCancel() }>{this.props.cancelLabel}</Button>
            }
            <Button className={classes.button} buttonRef={ref => this.buttonRef = ref} type='submit'
                    color='secondary' variant='contained' disabled={ !this.canConfirm() }>{this.props.confirmLabel}</Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

const styles = {
  root: {
  },
  content: {
  },
  paper: {
    maxWidth: '400px',
    flex: 1
  },
  button: {
    width: '100%',
    height: '45px'
  }
}

export default withStyles(styles)(ConfirmationDialog)