import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {ControlledForm, CenteredCard, ErrorBanner} from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { TokensContext, SnackbarContext, consume } from 'contexts'
import Button from '@material-ui/core/Button'
import { LaybuyLogo } from 'components'
import { GoogleSignInButton } from 'containers/auth'
import { compose } from 'utils'
import withStyles from 'styles'

export class SignIn extends Component{

  signIn = async () => {
    try{
      await this.props.tokens.actions.create(this.state)
      const location = (this.props.savedLocation && !this.props.savedLocation.match(/^\/?tokens\//)) ?
        this.props.savedLocation :
        '/'
      this.props.history.push(location)
      this.props.snackbar.actions.show('Signed in succesfully')
    }catch(err){
      this.props.snackbar.actions.show('Signed in failed')
    }
  }

  signInWithPassword = async () => {
    this.signIn({...this.state, auth_type: 'default'})
  }

  signInWithGoogle = (credentials) => {
    this.signIn({...credentials, auth_type: 'google'})
  }

   errorFor = key => {
    try{
      return this.props.tokens.errors.create.meta[key].join(', ')
    }catch(err){}
  }

  render = () =>
    <CenteredCard>
      <ControlledForm
        data={this.state} errors={{email: this.errorFor('email'), password: this.errorFor('password')}} onChange={state => this.setState(state)} onSubmit={this.signInWithPassword}>
        <CardContent>
          <Typography variant='h4' style={{textAlign: 'center'}}>
            <LaybuyLogo/>
            Onboarding
          </Typography>
          {this.props.tokens.errors.create && <ErrorBanner>{this.props.tokens.errors.create.title}</ErrorBanner>}
          <div className={this.props.classes.signInWithGoogle}>
            <GoogleSignInButton className={this.props.classes.actionBtn} label='' onAuthorized={this.signInWithGoogle}/>
            <hr/>
            <span>OR</span>
          </div>
          <FormGroup>
            <TextField name='email' type='text'/>
            <TextField name='password'type='password'/>
              <FormControlLabel
                control={
                  <ControlledForm data={this.state} onChange={state => this.setState(state)}>
                  <Checkbox name="rememberMe" value="checkedA"/>
                  </ControlledForm>
                }
                label="Remember Me"
              />
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button fullWidth variant='contained' color='primary' type='submit'>Submit</Button>
        </CardActions>
        <Link className='link-small' to='/tokens/forgot'>Forgot password</Link>
      </ControlledForm>
    </CenteredCard>
}

const styles = (theme) =>({
  signInWithGoogle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 15,
    marginBottom: "-20px",
    textAlign: 'center',
    '& > img': {
      cursor: 'pointer',
      margin: '0 auto',
      '&:hover': {
        opacity: 0.7
      }
    },
    '& hr': {
      flex: "1",
      border: "1px dashed #d2d2d2",
      width: "100%",
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'column'
  },
  actionBtn: {
    width: '100%',
    marginTop: '10px'
  }
})

export default compose(
  withStyles(styles),
  consume(TokensContext, SnackbarContext)
)(SignIn)