import React, { Component } from 'react'
import { PageContainer } from "components"
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography"
import { compose } from "utils"
import withStyles from 'styles'
import ResultIcon from "../../../components/ResultIcon";

class OnfidoFail extends Component {
  render() {
    const { classes } = this.props
    return (
      <PageContainer classes={ {content: classes.cardContent} }>
        <Box className={classes.wrapper}>
          <ResultIcon>
            <Box className={classes.icon}>
              <Box className={classes({iconCross: true, iconCrossLeft: true})} />
              <Box className={classes({iconCross: true, iconCrossRight: true})} />
            </Box>
          </ResultIcon>
          <Box className={classes.text}>
            <Typography variant="h5" gutterBottom>
              There was an issue verifying your identity.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Our team will be in touch.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              You can close or exit this page.
            </Typography>
          </Box>
        </Box>
      </PageContainer>
    );
  }
}


const styles = {
  cardContent: {
    height: '90%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 10,
    height: '100%'
  },
  icon: {
    width: 40,
    height: 40,
    background: '#C5CDD7',
    borderRadius: '50%',
    position: 'relative'
  },
  iconCross: {
    width: 26,
    height: 7,
    left: 7,
    top: 16,
    background: '#fff',
    borderRadius: 20,
    position: 'absolute'
  },
  iconCrossLeft: {
    transform: 'rotate(-45deg)',
  },
  iconCrossRight: {
    transform: 'rotate(45deg)',
  },
  text: {
    marginTop: 30
  }
}

export default compose(
  withStyles(styles),
)(OnfidoFail)

