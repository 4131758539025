import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { PageContainer } from 'components'


export class Redirect extends Component{
  render = () => {
    const { classes } = this.props
    return (
      <PageContainer>
        <Box className={classes.message}>
          <Typography variant="h6" className={classes.messageTitle}>
            We’re sending you to our secure identity verification platform...
          </Typography>
          <CircularProgress />
        </Box>
      </PageContainer>
    )
  }
}

const styles = {
  message: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    margin: '100px 10px',
  },
  messageTitle: {
    marginBottom: 40
  }
}

export default compose(
  withStyles(styles),
)(Redirect)