import React from "react"
import { PageContainer, LinkButton } from "components"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import withStyles from 'styles'
import { compose } from 'utils'

function ManualVerification({ classes, history }) {
  const handleClose = () => {
    window.close()
    // Fallback in case window was opened manually
    window.location = "about:blank"
  }

  return (
    <PageContainer classes={{ content: classes.cardContent }}>
      <LinkButton classes={{root: classes.backButton}} onClick={() => history.goBack()}>
        &lt; Back
      </LinkButton>
      <Typography variant="h5" style={{ marginBottom: 25 }}>
        Thank You for Confirming Your Beneficial Ownership Structure
      </Typography>
      <Typography variant="body1">
        Your onboarding specialist will be in touch shortly with next steps for confirming your Beneficial Owners.
      </Typography>
      <Typography variant="body1" style={{ margin: "20px 0" }}>
        Because your Beneficial Ownership structure includes a Trust or Business Entity, there are a few additional pieces  of information we will require from you. We are currently working on adding these to our automated onboarding platform, but unfortunately this service is not available at this time.
      </Typography>
      <Typography variant="body1">
        Thanks,
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 40 }}>
        Laybuy Onboarding Team
      </Typography>
      <Button variant="contained" color="primary" className={classes.button} onClick={handleClose}>
        Close
      </Button>
    </PageContainer>
  )
}

const styles = {
  cardContent: {
    padding: '30px 50px 0 50px',
    margin: 0,
    minHeight: 'calc(100vh - 201px)',
  },
  button: {
    borderRadius: 25,
    padding: '7px 22px 5px 23px',
    '@media(min-width: 600px)': {
      padding: '8px 36px 6px 35px',
    }
  },
  backButton: {
    marginBottom: 10,
    textDecoration: 'unset'
  },
}

export default compose(
    withStyles(styles),
)(ManualVerification)