import APIContext from 'contexts/base/APIContext'

export default class VerificationsContext extends APIContext{
  static contextName = 'verifications'

  current = async({ fields=null, include=null }={}) => {
    const { data: selected } = await this.performRequest('current')({options: { ...fields && {fields}, ...include && {include} }})
    this.convertLaybuyGlobalCountries(selected)
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  show = async(id, {fields=null, include=null }={}) => {
    const { data: selected } = await this.performRequest('show')({id, options: { ...fields && {fields}, ...include && {include} }})
    this.convertLaybuyGlobalCountries(selected)
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  convertLaybuyGlobalCountries = selected => {
    if (selected.verificationDetails && selected.verificationDetails.laybuyGlobalCountries) {
      const laybuyGlobalCountries = selected.verificationDetails.laybuyGlobalCountries.reduce((acc, code) => ({ ...acc, [code]: true }), {})
      selected.verificationDetails.laybuyGlobalCountries = laybuyGlobalCountries
    }

    return selected
  }
}
