import React, { Component } from 'react'
import { LoadedContext } from 'contexts'

const dependsOn = (resolver, shouldUpdate = () => false) => Type =>
  class extends Component {
    static displayName = Type.displayName || Type.name || 'Component'

    state = {
      dependenciesMet: false,
      loading: false
    }

    async componentDidMount(){
      this._mounted = true
      this.resolveDependencies()
    }

    componentWillUnmount(){
      this._mounted = false
    }

    componentDidUpdate(prevProps){
      if(shouldUpdate(this.props, prevProps))
        this.resolveDependencies()
    }

    setState = (state) => {
      this._mounted && super.setState(state)
    }

    resolveDependencies = async(...opts) => {
      this.setState({loading: true})
      try{
        await resolver(this.props, ...opts)
        this.setState({dependenciesMet: true})
      }
      catch(error){
        this.handleDependenciesFailed(error)
      }
      finally{
        this.setState({loading: false})
      }
    }

    redirect = (path) => {
      if (this.props.history)
        this.props.history.replace(path)
    }

    handleDependenciesFailed(error){
      error = error.length ? error[0] : error
      switch(error.status){
      case 403: break
      case 408: {
        this.redirect('/timed_out')
        break
      }
      case 504: {
        this.redirect('/timed_out')
        break
      }
      case 404: {
        this.redirect('/not_found')
        break
      }
      case undefined:
      case null: {
        console.error("Error ", arguments[0])
        break
      }
      default: {
        console.error("Redirecting due to error", error)
        this.redirect('/not_found')
        break
      }}
    }

    render = () =>
      <LoadedContext.Provider value={{
        loading:         this.state.loading,
        dependenciesMet: this.state.dependenciesMet
      }}>
        <Type
          onDependencyUpdate={this.resolveDependencies}
          loading={this.state.loading}
          dependenciesMet={this.state.dependenciesMet}
          {...this.props}
        />
      </LoadedContext.Provider>

  }

export default dependsOn

