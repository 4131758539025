import {Tokens as TokensBase} from './generated'
import {Verifications as VerificationsBase, ApiPathError} from './generated'
export const Tokens = TokensBase.extend(
  '',
  {
    refresh: endpoint => endpoint.method('put').path('/tokens').paramsHandler(() => {}).headers((headers, auth) => {return {...headers, ...(auth ? {Authorization: auth, "X-Authorization": auth} : {}) }}),
  }
)

export const Verifications = VerificationsBase.extend(
  '',
  {
    update: endpoint => endpoint.method('put').useFormData(true).path(({id}) => {
      if (id) {
        return `/verifications/${id}`
      }
      throw new ApiPathError('update', {id})
    })
  }
)

export * from './generated'