class Nonce {
  // From https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
  static generate = (length = 8)  => {
    let text = []
    for (var i = 0; i < length; i++) {
      let nextChar = POSSIBLE.charAt(Math.floor(Math.random() * POSSIBLE.length))
      text.push(nextChar)
    }

    return text.join('')
  }
}

const POSSIBLE = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

export default Nonce