import React, { Component } from 'react'
import Box from "@material-ui/core/Box";
import { compose } from "utils"
import withStyles from 'styles'
import PropTypes from "prop-types";

class ResultIcon extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  }

  render() {
    const { classes } = this.props
    return (
      <Box className={classes.root}>
        <Box className={classes.phone}>
          <Box className={classes.speaker} />
          <Box className={classes.result}>{this.props.children}</Box>
          <Box className={classes.homeButton} />
        </Box>
      </Box>
    );
  }
}


const styles = {
  root: {
    width: 150,
    height: 150,
    background: '#c5ccd6',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  phone: {
    border: 'solid black 7px',
    height: 133,
    width: 80,
    borderRadius: '15%',
    position: 'relative',
    top: -15,
    background: '#fefefe'
  },
  speaker: {
    width: 30,
    height: 3,
    background: 'black',
    position: 'absolute',
    top: 10,
    left: 18
  },
  homeButton: {
    width: 15,
    height: 15,
    border: 'solid black 2px',
    borderRadius: '50%',
    position: 'absolute',
    bottom: 7,
    left: 25
  },
  result: {
    width: 40,
    position: 'absolute',
    top: 38,
    left: 13
  }
}

export default compose(
  withStyles(styles),
)(ResultIcon)
