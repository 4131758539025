import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { withRouter } from 'react-router-dom'
import { Authorization  } from 'utils'
import { BeneficialOwnersContext, VerificationsContext, provide } from "contexts";
import dependsOn from 'containers/shared/dependsOn'
import { compose } from 'utils'


export class Routes extends Component{

  get defaultPath() {
    if (Authorization.typeVerificationUser) {
      const currentStep = this.props.verifications.selected.currentStep
      return `/verification-step/${currentStep || 'organisation'}`
    } else if (Authorization.typeBeneficialOwner) {
      const currentStep = Authorization.store.currentActor.currentStep
      return `/verify-identity/${currentStep}`
    }

    return "/verifications"
  }

  render = () => {
    if (!this.props.dependenciesMet) {
      return false
    }
    return (
      <Switch>
        <Route exact path="/tenants/:tenantId/webhooks"      component={Containers.Webhook.List}/>
        <Route exact path="/tenants/:tenantId/webhooks/new"  component={Containers.Webhook.Form}/>
        <Route exact path="/webhooks/:id"                     component={Containers.Webhook.Show}/>
        <Route exact path="/webhooks/:id/edit"                component={Containers.Webhook.Form}/>
        <Route exact path="/verifications"                    component={Containers.Verification.List}/>
        <Route exact path="/verifications/new"                component={Containers.Verification.Form}/>
        <Route exact path="/verifications/status/:verificationId"  component={Containers.Verification.Status}/>
        <Route exact path="/verifications/ids-status/:verificationId?"  component={Containers.Verification.BeneficialOwnersStatus}/>
        <Route exact path="/verifications/:id"                component={Containers.Verification.Show}/>
        <Route exact path="/verifications/:id/business-structure-review"  component={Containers.Verification.BusinessStructureReview}/>
        <Route exact path="/verifications/:id/final-review"   component={Containers.Verification.FinalReview}/>
        <Route exact path="/verifications/:id/edit"           component={Containers.Verification.Form}/>
        <Route exact path="/verification-step/organisation/:verificationId?"  component={Containers.Verification.Steps.Organisation}/>
        <Route exact path="/verification-step/beneficial-owners/:verificationId?"  component={Containers.Verification.Steps.BeneficialOwners}/>
        <Route exact path="/verification-step/review-submission/:verificationId?"  component={Containers.Verification.Steps.ReviewSubmission}/>
        <Route exact path="/verification-step/complete-laybuy/:verificationId?"  component={Containers.Verification.Steps.LaybuyAddon}/>
        <Route exact path="/verification-step/status"   component={Containers.Verification.Status}/>
        <Route exact path="/manual-verification"        component={Containers.Verification.ManualVerification}/>
        <Route exact path="/notifications"              component={Containers.Notification.List} requires={Authorization.admin}/>
        <Route exact path="/notifications/new"          component={Containers.Notification.Form} requires={Authorization.admin}/>
        <Route exact path="/notifications/mine"         component={Containers.Notification.Mine}/>
        <Route exact path="/tenants"                    component={Containers.Tenant.List}/>
        <Route exact path="/tenants/new"                component={Containers.Tenant.Form}/>
        <Route exact path="/tenants/:id"                component={Containers.Tenant.Show}/>
        <Route exact path="/tenants/:id/edit"           component={Containers.Tenant.Form}/>
        <Route exact path="/profile"                    component={Containers.User.Profile}/>
        <Route exact path="/users"                      component={Containers.User.List}/>
        <Route exact path="/users/new"                  component={Containers.User.Form}/>
        <Route exact path="/users/:id"                  component={Containers.User.Show}/>
        <Route exact path="/users/:id/edit"             component={Containers.User.Form}/>
        <Route exact path="/audits"                     component={Containers.Audit.List} requires={Authorization.admin}/>
        <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
        <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
        <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
        <Route exact path="/tokens/accept_invitation"   component={Containers.Token.AcceptInvitation}/>
        <Route exact path="/tokens/confirm"             component={Containers.Token.Confirm}/>
        <Route exact path="/tokens/resend_confirmation" component={Containers.Token.ResendConfirmation}/>
        <Route exact path="/"                           component={() =>  <Redirect to={this.defaultPath}/>}/>
        <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
        <Route exact path="/timed_out"                  component={Containers.Status.TimedOut} />
        <Route exact path="/inactive"                   component={Containers.Status.Inactive} />
        <Route exact path="/confirmation_resent"        component={Containers.Status.ConfirmationResent} />
        <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
        <Route exact path="/apidocs"                    component={Containers.Docs.ApiDocs} />
        <Route exact path="/googleauth/callback"        component={Containers.Auth.GoogleCallbackSink} />
        <Route exact path="/verify-identity/confirm-name"       component={Containers.BeneficialOwners.Steps.Name} />
        <Route exact path="/verify-identity/date-of-birth"  component={Containers.BeneficialOwners.Steps.DateOfBirth} />
        <Route exact path="/verify-identity/address"   component={Containers.BeneficialOwners.Steps.Address} />
        <Route exact path="/verify-identity/document"   component={Containers.BeneficialOwners.Steps.Document} />
        <Route exact path="/verify-identity/submit"   component={Containers.BeneficialOwners.Steps.Submission} />
        <Route exact path="/verify-identity/redirect"   component={Containers.BeneficialOwners.Redirect} />
        <Route exact path="/verification-user-auth/:token" component={Containers.Verification.Auth}/>
        <Route exact path="/verify-identity/onfido"        component={Containers.BeneficialOwners.Onfido} />
        <Route exact path="/verify-identity/thank-you"  component={Containers.BeneficialOwners.Steps.OnfidoComplete} />
        <Route exact path="/verify-identity/failure"  component={Containers.BeneficialOwners.Steps.OnfidoFail} />
        <Route exact path="/verify-identity/onfido-welcome" component={Containers.BeneficialOwners.Steps.OnfidoWelcome} />
        <Route component={Containers.Status.NotFound} />
      </Switch>
    )
  }


}

const fetchDependencies = async ({ verifications }) => {
  if (Authorization.typeVerificationUser) {
    return await verifications.actions.current()
  }
}

export default compose(
  dependsOn(fetchDependencies),
  withRouter,
  provide(BeneficialOwnersContext, VerificationsContext),
)(Routes)