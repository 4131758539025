import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, BeneficialOwnersContext } from 'contexts'
import instanceForm from 'containers/shared/instanceForm'
import {ErrorBanner, IdentityStep} from 'components'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'

export class Submission extends Component{

  get beneficialOwner() {
    return this.props.beneficialOwners.selected
  }

  get nextStep() {
    return "/verify-identity/onfido-welcome"
  }

  get prevStep() {
    return "/verify-identity/document"
  }

  handleSave = () => {
    this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      mapFormData: (data) => ({...data, currentStep: "onfido-welcome"})
    })
  }

  render = () => {
    const {formData, onFormDataChange, errors, errorStrings} = this.props
    return (
      <IdentityStep
        title='Nearly done'
        errors={errors}
        formData={formData}
        onGoBack={() => this.props.history.push(this.prevStep)}
        onSave={this.handleSave}
        onFormDataChange={onFormDataChange}
        saveButtonText="Continue"
      >
        <Box>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <Typography variant="h6">
            We need you to complete a quick check on our secure identity verification platform
          </Typography>
        </Box>
      </IdentityStep>
    )
  }
}

const styles = {
  reviewFeedback: {
    color: '#ff0800'
  }
}

const fetchDependencies = ({ beneficialOwners }) => {
  return beneficialOwners.actions.current()
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('beneficialOwners'),
  withStyles(styles),
  provide(BeneficialOwnersContext),
)(Submission)