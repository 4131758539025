import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, consume, VerificationsContext, SnackbarContext } from 'contexts'
import { LinkButton, PageContainer } from 'components'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid';
import { humanize } from 'utils'
import * as API from 'api'
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import OwnershipStructureNames from "constants/OwnershipStructureNames";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check';
import TableBody from "@material-ui/core/TableBody";
import RejectReviewButton from "./RejectReviewButton";


export class BusinessStructureReview extends Component{

  get verification() {
    return this.props.verifications.selected || {}
  }

  get beneficialOwners() {
    return this.verification.beneficialOwners || []
  }

  ownershipInfo(beneficialOwner) {
    const statusName = humanize(beneficialOwner.ownershipStatus)
    if (beneficialOwner.holdings) {
      return `${statusName} (${beneficialOwner.holdings}%)`
    }

    return statusName
  }

  ownershipStructureName(enumCode) {
    return OwnershipStructureNames[enumCode] || enumCode
  }

  handleApprove = async () => {
    const verification = this.props.verifications.selected
    await API.Verifications.event({
      name: 'accept_business_structure',
      id: verification.id
    })
    this.props.snackbar.actions.show("Verification approved")
    this.props.history.push("/verifications")
  }

  render = () => {
    const {classes} = this.props
    return (
      <PageContainer>
        <Typography variant="h4" gutterBottom>
          Business structure review - {this.verification.name}
        </Typography>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell variant='head'>National Business Number</TableCell>
              <TableCell>{this.verification.nationalBusinessNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Ownership Structure</TableCell>
              <TableCell>{this.ownershipStructureName(this.verification.ownershipStructure || '')}</TableCell>
            </TableRow>
            {this.verification.ownershipStructure === "trust" &&
            <TableRow>
              <TableCell variant='head'>Trust Deed</TableCell>
              <TableCell>
                <LinkButton>
                  <Link to={this.verification.trustDeedUrlPath} target="_blank">{this.verification.trustDeedName}</Link>
                </LinkButton>
              </TableCell>
            </TableRow>
            }
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6" className={classes.tableTitle} gutterBottom>Beneficial Owners:</Typography>
                  {this.beneficialOwners.map((beneficialOwner, idx) => (
                    <Grid container item sm={12} key={idx} className={classes.beneficialOwner}>
                      <Grid item sm={4} xs={12}>
                        {`${beneficialOwner.firstName} ${beneficialOwner.lastName}`}
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        {beneficialOwner.email}
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        {this.ownershipInfo(beneficialOwner)}
                      </Grid>
                    </Grid>
                  ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Box>
                  <RejectReviewButton verificationId={this.verification.id}
                                      eventName="reject_business_structure"
                                      actionName="reject" />
                  <Button color="secondary" variant="contained" startIcon={<CheckIcon />} onClick={this.handleApprove}>
                    Approve
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </PageContainer>
    )
  }
}

const styles = {
  table: {
    maxWidth: 700
  },
  tableTitle: {
    fontSize: 16
  },
  beneficialOwner: {
    marginBottom: 20,
    '@media(min-width: 600px)': {
      marginBottom: 0
    }
  }
}

const fetchDependencies = async ({verifications, match: { params: { id }}, history}) => {
  const verification = await verifications.actions.show(id, {include: 'beneficialOwners'})
  if (verification.status !== "pending_review_business_structure") {
    history.push(`/verifications/${id}`)
  }

  return verification
}

export default compose(
  dependsOn(fetchDependencies),
  withStyles(styles),
  provide(VerificationsContext),
  consume(SnackbarContext),
)(BusinessStructureReview)