import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from 'styles'
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { uuid } from 'utils'


class FileUpload extends Component {
  constructor(props){
    super(props)
    this.id = uuid()
  }

  handleFileDownload = () => {
    // only allow to download files that were previously uploaded
    if (this.props.urlPath) {
      window.open(this.props.urlPath)
    }
  }

  handleFileSelected = ({target: { files: { "0": file }}}) => {
    this.props.onFileSelect(file)
  }

  render() {
    const {
      fileName,
      urlPath,
      fullWidth,
      helperText,
      label,
      placeholder,
      classes,
      style,
      accept,
      error
    } = this.props

    const hasUploadedFile = Boolean(urlPath)
    let inputProps = {}
    if (hasUploadedFile) {
      inputProps = {style: {cursor: 'pointer'}}
    }

    const previewTextField = (
      <TextField
        readOnly
        value={fileName || placeholder || "Select a file to upload"}
        label={label}
        fullWidth={Boolean(fullWidth)}
        onClick={this.handleFileDownload}
        inputProps={inputProps}
        helperText={helperText}
        className={classes.textField}
        style={style}
        error={error}
      />
    )

    let preview
    if (hasUploadedFile) {
      preview = <Tooltip title="Click to download the file">{previewTextField}</Tooltip>
    } else {
      preview = previewTextField
    }

    return (
      <Box>
        <Box display='flex' alignItems="baseline">
          {preview}
          <input
            className={classes.input}
            onChange={this.handleFileSelected}
            id={this.id}
            multiple
            type="file"
            accept={accept}
          />
          <label htmlFor={this.id}>
            <Button className={classes.button} color='secondary' component="span" variant="contained">
              Select
            </Button>
          </label>
        </Box>
      </Box>
    )
  }
}

const styles = {
  input: {
    display: 'none'
  },
  button: {
    margin: "-6px 0 3px 0"
  },
  textField: {}
}

export default withStyles(styles)(FileUpload);