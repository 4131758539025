import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import HttpsIcon from '@material-ui/icons/Https';
import AppsIcon from '@material-ui/icons/Apps';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { provide, consume, SnackbarContext, TenantsContext } from 'contexts'
import Tooltip from "@material-ui/core/Tooltip";

export class List extends Component{

  state = {
    page: 1
  }

  showTenant = id => () => {
    this.props.history.push(`/tenants/${id}`)
  }

  showWebhooks = id => () => {
    this.props.history.push(`/tenants/${id}/webhooks`)
  }

  editTenant = id => event => {
    this.props.history.push(`/tenants/${id}/edit`)
    event.stopPropagation()
  }

  deleteTenant = id => event => {
    this.props.tenants.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get tenants(){
    return this.props.tenants.list
  }

  get errors(){
    let errors = []
    if(this.props.tenants.errors.index){
      errors = errors.concat(this.props.tenants.errors.index)
    }
    if(this.props.tenants.errors.destroy){
      errors = errors.concat(this.props.tenants.errors.destroy)
    }
    return errors
  }

  renderTenantListItem = ({id, name}) =>
    <ListItem button onClick={this.showTenant(id)} key={id}>
      <ListItemIcon>
        <AppsIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      <ListItemSecondaryAction>
        <Tooltip title="Webhooks">
          <IconButton onClick={this.showWebhooks(id)}><HttpsIcon/></IconButton>
        </Tooltip>
        <IconButton onClick={this.editTenant(id)}><EditIcon/></IconButton>
        <IconButton onClick={this.deleteTenant(id)}><DeleteIcon/></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <PageContainer>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.tenants.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.tenants.map(this.renderTenantListItem)}
      </MuiList>
      <Pagination totalPages={this.props.tenants.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <FABFixed color='secondary' onClick={() => this.props.history.push('/tenants/new')}>
        <AddIcon/>
      </FABFixed>
    </PageContainer>
}

const fetchDependencies = ({tenants, page}) => {
  return tenants.actions.index({
    page: page,
    fields: {tenants: 'name'}
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('tenants'),
  provide(TenantsContext),
  consume(SnackbarContext),
)(List)