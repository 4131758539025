import React, { Component } from 'react'
import instanceForm from 'containers/shared/instanceForm'
import dependsOn from 'containers/shared/dependsOn'
import {ControlledForm, ErrorBanner, PageContainer, PromiseButton, Select, Autocomplete} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { compose, Authorization } from 'utils'
import withStyles from 'styles'
import { provide, VerificationsContext, TenantsContext } from 'contexts'
import * as API from 'api'

export class Form extends Component {

  onSuggestionsFetchRequestedCountries =  async (text, callback) => {
    const { data: namesAndIds } = await API.Countries.index({
      options: {
        fields: {asset_type: 'id,name'},
        filter: {name: text},
        page: { number: 1, size: 5 }
      }
    })
    callback(namesAndIds)
  }

  onSuggestionsFetchRequestedUsers =  async (text, callback) => {
    const { data: namesAndIds } = await API.Users.index({
      options: {
        fields: {asset_type: 'id,name'},
        filter: {name: text, role: 'onboarding_specialist'},
        page: { number: 1, size: 5 }
      }
    })
    callback(namesAndIds)
  }

  onSuggestionsFetchRequestedTenants =  async (text, callback) => {
    const { data: namesAndIds } = await API.Tenants.index({
      options: {
        fields: {asset_type: 'id,name'},
        filter: {name: text},
        page: { number: 1, size: 5 }
      }
    })
    callback(namesAndIds)
  }

  get formData() {
    if (Authorization.onboardingSpecialist) {
      return {user: Authorization.user, ...this.props.formData}
    }

    return this.props.formData
  }

  handleSave = () => {
    this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      mapFormData: (data) => {
        if (this.tenant.tokenIssuer !== "laybuy_merchant_portal" && data.relationships.verificationDetails) {
          data.relationships.verificationDetails.data._destroy = true
        }
        return data
      }
    })
  }

  get tenant() {
    if (this.formData.tenant) {
      return this.formData.tenant
    }

    if (Authorization.onboardingSpecialist && this.props.tenants.list.length === 1) {
      return this.props.tenants.list[0]
    }

    return {}
  }

  renderDetailsFields() {
    if (this.tenant.tokenIssuer === "laybuy_merchant_portal") {
      return (
        <>
          <TextField fullWidth name='verificationDetails.commissionRate'/>
          <TextField fullWidth name='verificationDetails.refundCommissionRate'/>
          <TextField fullWidth name='verificationDetails.paymentCurrencyMargin'/>
        </>
      )
    }

    return null
  }

  render = () => {
    const {errors, errorStrings, onFormDataChange, editMode} = this.props
    const formData = this.formData;
    return (
      <PageContainer className={this.props.classes.card}>
        <Typography variant='h5'>{editMode ? `Edit Verification - ${formData.name}` : 'Create a new Verification'}</Typography>
        <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={this.handleSave}>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          <TextField fullWidth name='name' autoComplete="chrome-off"/>
          <Autocomplete
            fullWidth
            name='user'
            label='Onboarding Specialist'
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedUsers}
          />
          <Autocomplete
            fullWidth
            name='country'
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCountries}
          />
          {Authorization.admin &&
            <Autocomplete
              fullWidth
              name='tenant'
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedTenants}
            />
          }
          <Select fullWidth name='ownershipStructure'>
            <MenuItem value='company'>Company</MenuItem>
            <MenuItem value='partnership'>Partnership</MenuItem>
            <MenuItem value='sole_trader_individual'>Sole Trader / Individual</MenuItem>
            <MenuItem value='trust'>Trust</MenuItem>
          </Select>
          <TextField fullWidth name='nationalBusinessNumber' autoComplete="chrome-off"/>
          <TextField fullWidth name='reference' label='Merchant Reference (ID)' autoComplete="chrome-off"/>
          <TextField fullWidth name='primaryContactEmail' type='email' autoComplete="chrome-off"/>
          {this.renderDetailsFields()}
        <PromiseButton color='secondary' fullWidth variant='contained' type='submit'>Save</PromiseButton>
        </ControlledForm>
      </PageContainer>
    )
  }

}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

const fetchDependencies = ({verifications, tenants, id, editMode}) => {
  if (editMode) {
    return verifications.actions.show(id, {include: 'country,user,tenant,verification_details'})
  } else if (Authorization.onboardingSpecialist) {
    return tenants.actions.index()
  }
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('verifications', {linked: ['country', 'user', 'tenant'], nested: ['verificationDetails']}),
  withStyles(styles),
  provide(VerificationsContext, TenantsContext),
)(Form)