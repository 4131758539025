import { ContextsByType } from 'contexts/base/BaseContext'

class AuthorizationProxy{

  get store(){
    return ContextsByType.tokens[0].state
  }

  get userId(){
    if (!this.typeUser) {
      return null
    }

    return this.user.id
  }

  get user(){
    if (!this.typeUser) {
      return null
    }

    const user = this.store.currentActor || {}
    return user
  }

  get typeUser() {
    return this.store.actorType === 'user'
  }

  get typeVerificationUser() {
    return this.store.actorType === 'verification_user'
  }

  get typeBeneficialOwner() {
    return this.store.actorType === 'beneficial_owner'
  }

  get admin(){
    return this.typeUser && (this.user.role === 'admin')
  }

  get onboardingSpecialist(){
    return this.typeUser && (this.user.role === 'onboarding_specialist')
  }
}

export const Authorization = new AuthorizationProxy()
