import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { LinkButton } from 'components'
import { Box } from '@material-ui/core'
import withStyles from 'styles'
import { compose, humanize } from 'utils'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import dependsOn from 'containers/shared/dependsOn'
import { provide, VerificationsContext } from 'contexts'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from "@material-ui/core/Tooltip";
import { BeneficialOwnerFailInfoDialog, BeneficialOwnerManageDialog } from './dialogs';
import {Authorization} from "../../utils";

export class BeneficialOwnersStatus extends Component{

  get verification() {
    return this.props.verifications.selected
  }

  get beneficialOwners() {
    return this.verification.beneficialOwners || []
  }

  ownershipInfo(beneficialOwner) {
    const statusName = humanize(beneficialOwner.ownershipStatus)
    if (beneficialOwner.holdings) {
      return `${statusName} (${beneficialOwner.holdings}%)`
    }

    return statusName
  }

  get prevPage() {
    const verificationId = this.props.match.params.verificationId
    if (verificationId) {
      return `/verifications/status/${verificationId}`
    }

    return "/verification-step/status"
  }

  statusClass = beneficialOwner => {
    const {classes} = this.props
    if (beneficialOwner.status === "done") {
      return classes.statusSuccess
    }

    if (beneficialOwner.status === "partially_verified") {
      return classes.statusPartial
    }

    if (beneficialOwner.status === "failed") {
      return classes.statusError
    }

    return classes.statusNeutral
  }

  statusDescription = beneficialOwner => {
    switch (beneficialOwner.status) {
      case 'draft':
        return 'Waiting for review...'
      case 'invited':
        return 'Waiting for response...'
      case 'in_progress':
        return 'In progress...'
      case 'partially_verified':
        return 'Partially verified'
      case 'done':
        return 'Completed & Passed'
      case 'failed':
        return 'Unable to verify'
      default:
        return beneficialOwner.status
    }
  }

  shouldShowManageDialog = beneficialOwner => {
    if (["invited", "in_progress", "partially_verified"].includes(beneficialOwner.status)) {
      return true
    }

    if (["failed", "done"].includes(beneficialOwner.status) && Authorization.typeUser) {
      return true
    }

    return false
  }

  shouldShowFailInfoDialog = beneficialOwner => {
    return beneficialOwner.status === "failed" && Authorization.typeVerificationUser
  }

  overrideToolTipText = beneficialOwner => {
    const overriddenBy = beneficialOwner.overriddenBy || {}
    const basicText = `Overridden by ${overriddenBy.name || 'onboarding specialist'}`
    if (Authorization.typeUser) {
      return `${basicText}. Reason: ${beneficialOwner.overrideReason}`
    }

    return basicText
  }

  renderStatus = beneficialOwner => {
    if (beneficialOwner.status === "done" && beneficialOwner.overrideReason) {
      return(
        <>
          <Typography variant="subtitle2">
            Completed&nbsp;
          </Typography>
          <Tooltip title={this.overrideToolTipText(beneficialOwner)} className={this.props.classes.statusTooltip}>
            <Typography variant="subtitle2">(overridden)</Typography>
          </Tooltip>
        </>
      )
    }

    return <Typography variant="subtitle2">{this.statusDescription(beneficialOwner)}</Typography>
  }

  render = () => {
    const {classes} = this.props
    return (
      <Card style={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent className={this.props.classes.cardContent}>
          <Box>
            <LinkButton classes={{root: classes.backButton}} onClick={() => this.props.history.push(this.prevPage)}>
              &lt; Back
            </LinkButton>
            <Typography variant='h5'>Verify Identity</Typography>
          </Box>
          <Box className={this.props.classes.description}>
            <Typography variant='body1' className={classes.paragraph}>
              We’ve sent an email inviting each beneficial owner to complete an online identity check.
            </Typography>
            <Typography variant='body1'>
              Everyone needs to complete their check before we can activate your merchant account.
            </Typography>
          </Box>
          <Divider />
          <Grid container spacing={1} className={classes.list}>
            {this.beneficialOwners.map((beneficialOwner, idx) => (
              <Grid container item md={12} spacing={2} key={idx} className={classes.row}>
                <Grid item md={3}>
                  {`${beneficialOwner.firstName} ${beneficialOwner.lastName}`}
                </Grid>
                <Grid item md={3}>
                  {beneficialOwner.email}
                </Grid>
                <Grid item md={2}>
                  {this.ownershipInfo(beneficialOwner)}
                </Grid>
                <Grid item md={3}>
                  <Box display="flex" alignItems="center" className={this.statusClass(beneficialOwner)}>
                    {beneficialOwner.status === "done" && <CheckCircleIcon className={classes.statusIcon} />}
                    {beneficialOwner.status === "failed" && <CancelIcon className={classes.statusIcon} />}
                    {this.renderStatus(beneficialOwner)}
                  </Box>
                </Grid>
                <Grid item md={1}>
                  {this.shouldShowManageDialog(beneficialOwner) &&
                    <BeneficialOwnerManageDialog beneficialOwner={beneficialOwner} onStatusChange={this.props.onDependencyUpdate} />
                  }
                  {this.shouldShowFailInfoDialog(beneficialOwner) &&
                    <BeneficialOwnerFailInfoDialog beneficialOwner={beneficialOwner} />
                  }
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

const styles = theme => ({
  cardContent: {
    padding: '30px 50px 0 50px',
    margin: 0,
    minHeight: 'calc(100vh - 201px)',
  },
  backButton: {
    marginBottom: 10,
    textDecoration: 'unset'
  },
  description: {
    marginBottom: 45,
    marginTop: 30,
    maxWidth: 500
  },
  paragraph: {
    marginBottom: 20
  },
  list: {
    marginTop: 25,
    marginBottom: 50
  },
  statusSuccess: {
    color: theme.palette.success.main
  },
  statusPartial: {
    color: theme.palette.secondary.main
  },
  statusError: {
    color: theme.palette.error.main
  },
  statusNeutral: {
    color: '#9B9B9B'
  },
  statusIcon: {
    marginRight: 10,
  },
  row: {
    marginBottom: 25,
    justifyContent: 'center',
    '@media(min-width: 960px)': {
      marginBottom: 'unset',
      justifyContent: 'unset',
    }
  },
  statusTooltip: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

const fetchDependencies = ({verifications, match: { params: { verificationId }}}) => {
  if (verificationId) {
    return verifications.actions.show(verificationId, {include: 'beneficialOwners,beneficialOwners.onfidoCheck,beneficialOwners.rapidIdentityResponse,beneficialOwners.rapidDocumentResponse,beneficialOwners.rapidAmlResponse,beneficialOwners.overriddenBy'})
  } else {
    return verifications.actions.current({include: 'beneficialOwners'})
  }
}

export default compose(
  dependsOn(fetchDependencies),
  withStyles(styles),
  provide(VerificationsContext),
)(BeneficialOwnersStatus)