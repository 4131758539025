import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import {ControlledForm, PromiseButton, LinkButton} from 'components'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

export class VerificationStep extends Component{

  renderContent() {
    const {title, children, onGoBack, classes} = this.props
    return (
      <CardContent className={this.props.classes.cardContent}>
        <Box className={classes.header}>
          {onGoBack &&
            <LinkButton classes={{root: classes.backButton}} onClick={onGoBack}>&lt; Back</LinkButton>
          }
          <Typography variant='h5'>{title}</Typography>
        </Box>
        {children}
      </CardContent>
    )
  }

  renderActions() {
    const {classes, saveButtonText, formData, onSave, onSaveForLater, saveForLater, saveButtonDisabled} = this.props
    const handleSave = formData ? null : onSave

    return (
      <CardActions className={classes.cardActions}>
        {saveForLater &&
          <PromiseButton
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={onSaveForLater}
          >
            Save for later
          </PromiseButton>
        }
        <PromiseButton
          color='secondary'
          variant='contained'
          type={formData ? 'submit' : null}
          className={classes.button}
          onClick={handleSave}
          disabled={Boolean(saveButtonDisabled)}
        >
          {saveButtonText || 'Next'} →
        </PromiseButton>
      </CardActions>
    )
  }

  render = () => {
    const {formData, errors, onFormDataChange, onSave} = this.props
    return (
      <Card style={{ display: 'flex', flexDirection: 'column' }}>
        {formData
          ? <ControlledForm data={formData} errors={errors} onChange={onFormDataChange} onSubmit={onSave}>
              {this.renderContent()}
              {this.renderActions()}
            </ControlledForm>
          : <>
            {this.renderContent()}
            {this.renderActions()}
            </>
        }
      </Card>
    )
  }
}

const styles = {
  cardContent: {
    padding: '30px 50px 0 50px',
    margin: 0,
    minHeight: 'calc(100vh - 201px)',
  },
  body: {
    maxWidth: 425,
    marginBottom: 45,
  },
  cardActions: {
    padding: '35px 50px 25px 50px',
    backgroundColor: '#e0e7ef',
    marginTop: 30
  },
  button: {
    borderRadius: 25,
    padding: '7px 22px 5px 23px',
    '@media(min-width: 600px)': {
      padding: '8px 36px 6px 35px',
    }
  },
  backButton: {
    marginBottom: 10,
    textDecoration: 'unset'
  },
  header: {}
}

export default compose(
  withStyles(styles),
)(VerificationStep)