import React, { Component } from 'react'
import { Pagination, FABFixed, ErrorBanner, PageContainer } from 'components'
import dependsOn from 'containers/shared/dependsOn'
import AddIcon from '@material-ui/icons/Add'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'
import NotificationIcon from '@material-ui/icons/Message'
import ResendIcon from '@material-ui/icons/Refresh'
import { compose, errorStringsFromError, userFriendlyDate } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import { provide, consume, SnackbarContext, NotificationsContext } from 'contexts'
import withStyles from 'styles'

export class List extends Component{

  state = {
    page: 1
  }

  showNotification = id => () => {
    this.props.history.push(`/notifications/${id}`)
  }

  editNotification = id => event => {
    this.props.history.push(`/notifications/${id}/edit`)
    event.stopPropagation()
  }

  deleteNotification = id => event => {
    this.props.notifications.actions.destroy({id})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get notifications(){
    return this.props.notifications.list
  }

  linkToAction = notification => () => {
    if (notification.linkTo) {
      this.props.history.push('/' + notification.linkTo)
    }
  }

  handlePageSelected = page =>{
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  handleShowResendMenu = resendId => event => {
    this.setState({anchorEl: event.currentTarget, resendId})
    event.stopPropagation()
    event.preventDefault()
  }

  handleResendNotification = (id, delivery) => event => {
    this.props.notifications.actions.resend({id, delivery})
                .then(this.props.onDependencyUpdate)
                .catch(error => this.props.snackbar.actions.show(errorStringsFromError(error).join(', ')))
    this.setState({anchorEl: undefined})
    event.stopPropagation()
  }

  get errors(){
    let errors = []
    if(this.props.notifications.errors.index){
      errors = errors.concat(this.props.notifications.errors.index)
    }
    if(this.props.notifications.errors.destroy){
      errors = errors.concat(this.props.notifications.errors.destroy)
    }
    if(this.props.notifications.errors.resend){
      errors = errors.concat(this.props.notifications.errors.resend)
    }
    return errors
  }

renderNotificationListItem = (notification) => {
  const {id, title, message, createdAt, read, typeDisplayName, recipient: { email }} = notification
  return (
    <ListItem button onClick={this.linkToAction(notification)} key={id} className={this.props.classes({read})}>
      <div className={this.props.classes.listItemIcon}>
        <ListItemIcon className={this.props.classes.notificationIcon}>
          <NotificationIcon/>
        </ListItemIcon>
        <Tooltip title='Resend notification' className={this.props.classes.tooltip}>
          <ListItemIcon onClick={this.handleShowResendMenu(id)} className={this.props.classes.resendIcon}>
            <ResendIcon/>
          </ListItemIcon>
        </Tooltip>
      </div>
      <ListItemText primary={
          <div className={this.props.classes.notifcationTextPrimary}>
            <span className={this.props.classes.notificationContent}>
              To {email}: {title} ({userFriendlyDate(createdAt)})
            </span>
            <span className={this.props.classes.notificationTypeContent}>
              {typeDisplayName}
            </span>
          </div>
        }
        secondary={message}
      />
    </ListItem>
  )
}

renderErrorMessages = () =>
  <ErrorBanner>
    {errorStringsFromError(this.errors)}
  </ErrorBanner>

render = () =>
  <PageContainer>
    {this.renderErrorMessages()}
    <Pagination totalPages={this.props.notifications.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    <MuiList dense>
      {this.notifications.map(this.renderNotificationListItem)}
    </MuiList>
    <Menu
      anchorEl={this.state.anchorEl}
      open={Boolean(this.state.anchorEl)}
      onClose={this.handleCloseResendMenu}
    >
      <MenuItem onClick={this.handleResendNotification(this.state.resendId, ['app'])}>In App</MenuItem>
      <MenuItem onClick={this.handleResendNotification(this.state.resendId, ['email'])}>Email</MenuItem>
      <MenuItem onClick={this.handleResendNotification(this.state.resendId, ['app', 'email'])}>Both</MenuItem>
    </Menu>
    <Pagination totalPages={this.props.notifications.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    <FABFixed color='secondary' onClick={() => this.props.history.push('/notifications/new')}>
      <AddIcon/>
    </FABFixed>
  </PageContainer>
}

const fetchDependencies = ({notifications, page}) => {
  return notifications.actions.index({
    page: page,
    include: "recipient"
  })
}

const styles = {
  read: {
    opacity: 0.6
  },
  tooltip: {
    minWidth: '0px',
  },
  notifcationTextPrimary: {
    display: 'flex',
    flexDirection: 'row'
  },
  notificationContent: {
    flex: 1,
  },
  notificationTypeContent: {
    color: "#283888",
    fontSize: "0.9em",
    textAlign: "right",
    marginLeft: 50,
  },
  resendIcon:{
    display: 'none',
    transition: 'opacity 200ms',
  },
  notificationIcon:{
    opacity: 1,
    transition: 'opacity 200ms',
  },
  listItemIcon: {
    display: "inline-block",
    width: 36,
    height: 26,
    '&:hover': {
      '& $resendIcon': {
        opacity: 1,
        display: 'inline-block'
      },
      '& $notificationIcon': {
        opacity: 0,
        display: 'none'
      }
    }
  }
}


export default compose(
  withStyles(styles),
  dependsOn(fetchDependencies, hasIndexChanged),
  indexView('notifications'),
  provide(NotificationsContext),
  consume(SnackbarContext),
)(List)