import APIContext from 'contexts/base/APIContext'

export default class UsersContext extends APIContext{
  static contextName = 'users'

  show = async(id, {fields=null, include=null }={}) => {
    const { data: {tenants = [], ...selected} } = await this.performRequest('show')({id, options: { ...fields && {fields}, ...include && {include} }})
    // We assume that an onboarding specialist can be assigned to only one tenant for now.
    // If this changes, we'll need to passthrough tenants array and use multiselect in the interface.
    selected.tenant = tenants[0]
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }

  create = async({relationships: {tenant, ...relationships}, ...item}, {...options}={}) => {
    if (tenant && item.role === "onboarding_specialist") {
      item.relationships = {...relationships, tenants: {data: [tenant.data]}}
    }
    const { data:selected } = await this.performRequest('create')({...item, options})
    this.setState({selected, list: [...this.state.list, selected]})
    return selected
  }

  update = async({relationships: {tenant, ...relationships}, ...item}, options={}) => {
    if (tenant && item.role === "onboarding_specialist") {
      item.relationships = {...relationships, tenants: {data: [tenant.data]}}
    } else {
      // remove tenant binding in case role in not onboarding_specialist
      item.relationships = {...relationships, tenants: {data: []}}
    }
    const { data: selected } = await this.performRequest('update')({...item, options})
    this.setState({selected, list: this.replace(this.state.list, selected)})
    return selected
  }
}
