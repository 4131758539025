import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, BeneficialOwnersContext} from 'contexts'
import instanceForm from 'containers/shared/instanceForm'
import { ErrorBanner, IdentityStep, Select } from 'components'
import TextField from '@material-ui/core/TextField'
import { compose } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import MenuItem from "@material-ui/core/MenuItem";
import AuStates from 'constants/AuStates'

export class Address extends Component{

  get beneficialOwner() {
    return this.props.beneficialOwners.selected
  }

  get nextStep() {
    return "/verify-identity/document"
  }

  get prevStep() {
    return "/verify-identity/date-of-birth"
  }

  get regionLabel() {
    if (this.country.code === "AU") {
      return "State"
    }

    return "District"
  }

  get country() {
    if (!this.beneficialOwner.verification || !this.beneficialOwner.verification.country) {
      return {}
    }

    return this.beneficialOwner.verification.country
  }

  handleSave = () => {
    this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      mapFormData: (data) => ({...data, currentStep: "document"})
    })
  }

  renderDistrict = () =>
    <>
      {this.country.code === "AU" ?
        <Select fullWidth name='address.region' label={this.regionLabel}>
          {Object.keys(AuStates).map(code => (
            <MenuItem value={code} key={code}>{AuStates[code]}</MenuItem>
          ))}
        </Select> :
        <TextField fullWidth name='address.region' label={this.regionLabel} />
      }
    </>

  render = () => {
    const {formData, onFormDataChange, errors, errorStrings, classes} = this.props
    const { address = {} } = formData
    return (
      <IdentityStep
        title='Enter your address'
        errors={errors}
        formData={formData}
        onGoBack={() => this.props.history.push(this.prevStep)}
        onSave={this.handleSave}
        onFormDataChange={onFormDataChange}
        saveButtonDisabled={!address.streetAddress || !address.city || !address.postcode || !address.region}
      >
        <Box>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          {this.beneficialOwner.restartComment &&
          <>
            <Typography variant='subtitle1' className={classes.reviewFeedback} gutterBottom>
              Comment from onboarding specialist: {this.beneficialOwner.restartComment}
            </Typography>
          </>
          }
          <TextField fullWidth name='address.streetAddress'/>
          <TextField fullWidth name='address.city'/>
          <TextField fullWidth name='address.postcode'/>
          {this.renderDistrict()}
        </Box>
      </IdentityStep>
    )
  }
}

const styles = {
  reviewFeedback: {
    color: '#ff0800'
  }
}

const fetchDependencies = ({ beneficialOwners }) => {
  return beneficialOwners.actions.current({include: 'address,verification,verification.country'})
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('beneficialOwners', {nested: ['address']}),
  withStyles(styles),
  provide(BeneficialOwnersContext),
)(Address)