import React, { Component } from 'react'
import withStyles from 'styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { compose } from 'utils'
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";


export class ClosableDialogButton extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    buttonComponent: PropTypes.elementType,
    buttonTitle: PropTypes.string,
    buttonProps: PropTypes.object,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  }

  static defaultProps = {
    buttonComponent: Button,
    buttonTitle: "Open",
    buttonProps: {},
  }

  state = {
    open: false
  }

  handleClose = () => {
    this.setState({open: false})
    this.props.onClose && this.props.onClose()
  }

  handleOpen = () => {
    this.setState({open: true})
    this.props.onOpen && this.props.onOpen()
  }

  get open() {
    if (this.props.open !== undefined) {
      return this.props.open
    }

    return this.state.open
  }

  render = () => {
    const {classes, buttonComponent: ButtonComponent, buttonTitle, buttonProps, children, title} = this.props

    return (
      <>
        <ButtonComponent {...buttonProps} onClick={this.handleOpen}>
          {buttonTitle}
        </ButtonComponent>
        <Dialog open={this.open} classes={{paper: classes.paper}} onClose={this.handleClose}>
          <DialogTitle onClose={this.handleClose} classes={classes}>
            {title}
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {children}
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

const DialogTitle = ((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.dialogTitleCloseButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const styles = theme => ({
  paper: {
    flex: 1,
    padding: '5px 5px 35px 20px'
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogTitleCloseButton: {
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: "8px 18px",
  }
})

export default compose(
  withStyles(styles),
)(ClosableDialogButton)