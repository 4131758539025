import React, { Component } from 'react'
import qs from 'qs'
import { GoogleAuthService } from 'services'
import { SessionStatus } from '../../constants'
import { SnackbarContext, consume, TokensContext } from 'contexts'

export class GoogleCallbackSink extends Component{

  componentDidMount = async () => {
    if(this.props.tokens.loginState !== SessionStatus.AUTHENTICATED) {
      let query = qs.parse(window.location.search.substring(1))
      if(window.opener) {
        setTimeout(() => {
          window.opener.postMessage(query, '*')
        }, 2000)
      } else {
        let { state } = query
        let nonce = global.localStorage.googleOAuthNonce

        if(nonce !== state) {
          this.props.snackbar.actions.show('This OAuth redirect state does not match our state.')
          this.props.history.push('/tokens/sign_in')
        }

        let params = {...GoogleAuthService.authHashFromReply(query), auth_type: 'google'}
        await this.signIn(params)
      }
    } else {
      this.props.snackbar.actions.show('Signed in succesfully')
      this.props.history.push('/')
    }
  }

  signIn = async (params) => {
    try{
      await this.props.tokens.actions.create(params)
      // if successful, this will trigger a un-mount/mount of this component under Authenticated Routes
    }catch(err){
      this.props.snackbar.actions.show('Signed in failed')
      this.props.history.push('/tokens/sign_in')
    }
  }

  render = () => {
    return (
      <div>
        <link rel="stylesheet" href="/index.css" />
        <link rel="stylesheet" href="/fonts.css" />
        <link rel="stylesheet" href="/loader.css" />
        <div id='loader'>
          <div className="lds-circle">
            <div></div>
          </div>
          Loading...
        </div>
      </div>
    )
  }
}

export default consume(SnackbarContext, TokensContext)(GoogleCallbackSink)