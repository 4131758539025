import React, {Component} from 'react'
import withStyles from 'styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

export class PageContainer extends Component {

  render = () => {
    const {children, classes: {content: contentClass, ...classes}, ...props} = this.props
    return (
      <Card {...props} classes={classes}>
        <CardContent className={contentClass}>{children}</CardContent>
      </Card>
    )
  }
}

const styles = theme => ({

})

export default withStyles(styles)(PageContainer)
