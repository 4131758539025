export default {
    draft: "Draft",
    invited: "Invited",
    in_progress: "In Progress",
    pending_review_business_structure: "Pending Review – Business Structure",
    pending_id_checks: "Pending ID Checks",
    pending_review_final: "Pending Review – Final",
    pending_verification_details: "Pending Branding and Account info",
    done: "Verified",
    canceled: "Canceled"
}