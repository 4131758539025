import React, { Component } from 'react'
import dependsOn from 'containers/shared/dependsOn'
import Typography from '@material-ui/core/Typography'
import { provide, BeneficialOwnersContext } from 'contexts'
import instanceForm from 'containers/shared/instanceForm'
import {ErrorBanner, IdentityStep, Select, DatePickerSelect} from 'components'
import TextField from '@material-ui/core/TextField'
import { compose, capitalize, snakeCased } from 'utils'
import withStyles from 'styles'
import Box from '@material-ui/core/Box'
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import LicenceIcon from '../../../imgs/icon_licence.png'
import PassportIcon from '../../../imgs/icon_passport.png'
import AuStates from 'constants/AuStates'

const DOCUMENT_TYPES = ['nzDriversLicence', 'auDriversLicence', 'auPassport', 'passport', 'driversLicence']

export class Document extends Component{
  state = {
    selectedDocumentType: null,
  }

  get savedDocumentType() {
    const presentDocTypes = DOCUMENT_TYPES.filter(docType => {
      return Boolean(this.beneficialOwner[docType])
    })

    switch (presentDocTypes[0]) {
      case "auPassport":
      case "passport":
        return "passport"
      case "auDriversLicence":
      case "driversLicence":
      case "nzDriversLicence":
        return  "licence"
      default:
        return null
    }
  }

  get selectedDocumentType() {
    return this.state.selectedDocumentType || this.savedDocumentType || "licence"
  }

  get beneficialOwner() {
    return this.props.beneficialOwners.selected
  }

  get country() {
    if (!this.beneficialOwner.verification) {
      return {}
    }

    return this.beneficialOwner.verification.country
  }

  get nextStep() {
    return "/verify-identity/submit"
  }

  get prevStep() {
    return "/verify-identity/address"
  }

  documentFieldName = fieldName => {
    return `${this.documentType}.${fieldName}`
  }

  get documentType() {
    let docTypeName
    switch (this.selectedDocumentType) {
      case "licence":
        docTypeName = "driversLicence"
        break
      case "passport":
        docTypeName = "passport"
        break
      default:
        docTypeName = ""
    }

    const countryPrefixedName = () => `${(this.country.code).toLowerCase()}${capitalize(docTypeName)}`

    if (this.selectedDocumentType === "passport" && this.country.code === "AU") {
      return countryPrefixedName()
    } else if (this.selectedDocumentType === "licence" && ["AU", "NZ"].includes(this.country.code)) {
      return countryPrefixedName()
    }

    return docTypeName
  }

  get requiredFields() {
    if (this.selectedDocumentType === "passport") {
      const required = ["passportNumber", "expiryDate"]
      if (this.country.code === "AU") {
        required.push("gender")
      }
      return required
    } else {
      const required = ["licenceNumber"]
      if (this.country.code === "AU") {
        required.push("issuingState")
      } else if (this.country.code === "NZ") {
        required.push("versionNumber")
      }
      return required
    }
  }

  handleSave = () => {
    this.props.onSave(null, {
      onSaveRedirect: this.nextStep,
      mapFormData: (data) => {
        const otherDocTypes = DOCUMENT_TYPES.filter(d => d !== this.documentType)
        otherDocTypes.forEach(docType => {
          if (!data.relationships[docType]) {
            return
          }
          data.relationships[docType].data._destroy = true
        })
        return {...data, currentStep: "submit"}
      }
    })
  }

  renderLicence = () => {
    const countryCode = this.country.code
    return (
      <>
        <TextField fullWidth name={this.documentFieldName('licenceNumber')}/>
        {countryCode === "NZ" &&
          <TextField fullWidth name={this.documentFieldName('versionNumber')}/>
        }
        {countryCode === "AU" &&
          <Select fullWidth name={this.documentFieldName('issuingState')}>
            {Object.keys(AuStates).map(code => (
                <MenuItem value={code} key={code}>{AuStates[code]}</MenuItem>
            ))}
          </Select>
        }
      </>
    )
  }

  renderPassport = () => {
    return (
      <>
        <TextField fullWidth name={this.documentFieldName('passportNumber')}/>
        <DatePickerSelect fullWidth name={this.documentFieldName('expiryDate')} minYear={new Date().getFullYear()} />
        {this.country.code === "AU" &&
          <Select fullWidth name={this.documentFieldName('gender')}>
            <MenuItem value='M'>Male</MenuItem>
            <MenuItem value='F'>Female</MenuItem>
            <MenuItem value='X'>Indeterminate / Intersex / Unspecified</MenuItem>
          </Select>
        }
      </>
    )
  }


  renderDocumentTypeToggleButtons = () => {
    const { classes } = this.props
    const selectedDocumentType = this.selectedDocumentType
    return (
      <Box display="flex" alignItems="center" marginTop={2} marginBottom={2}>
        <Button
          size="large"
          startIcon={<img src={LicenceIcon} alt="" />}
          variant="contained"
          color={selectedDocumentType === "licence" ? "secondary": "default" }
          onClick={() => this.setState({selectedDocumentType: 'licence'})}
          className={classes.documentTypeButton}
        >
          Drivers License
        </Button>
        <Typography variant="subtitle1" className={classes.documentTypeSeparator}>
          OR
        </Typography>
        <Button
          size="large"
          startIcon={<img src={PassportIcon} alt="" />}
          variant="contained"
          color={selectedDocumentType === "passport" ? "secondary": "default" }
          onClick={() => this.setState({selectedDocumentType: 'passport'})}
          className={classes.documentTypeButton}
        >
          {this.country.code} Passport
        </Button>
      </Box>
    )
  }

  render = () => {
    const { formData, onFormDataChange, errors, errorStrings, classes } = this.props
    return (
      <IdentityStep
        title='Supporting document'
        errors={errors}
        formData={formData}
        onGoBack={() => this.props.history.push(this.prevStep)}
        onSave={this.handleSave}
        onFormDataChange={onFormDataChange}
        saveButtonDisabled={this.requiredFields.some(field => !formData[this.documentType] || !formData[this.documentType][field])}
      >
        <Box>
          <ErrorBanner>{errorStrings}</ErrorBanner>
          {this.beneficialOwner.restartComment &&
          <>
            <Typography variant='subtitle1' className={classes.reviewFeedback} gutterBottom>
              Comment from onboarding specialist: {this.beneficialOwner.restartComment}
            </Typography>
          </>
          }
          <Typography variant="subtitle2">Choose...</Typography>
          {this.renderDocumentTypeToggleButtons()}
          {this.selectedDocumentType === "licence" && this.renderLicence()}
          {this.selectedDocumentType === "passport" && this.renderPassport()}
        </Box>
      </IdentityStep>
    )
  }
}

const styles = {
  reviewFeedback: {
    color: '#ff0800'
  },
  documentTypeSeparator: {
    margin: '0 15px'
  },
  documentTypeButton: {
    textAlign: "left"
  }
}

const fetchDependencies = ({ beneficialOwners }) => {
  const documentRelationships = DOCUMENT_TYPES.map(snakeCased).join(",")
  return beneficialOwners.actions.current({include: `verification.country,${documentRelationships}`})
}

export default compose(
  dependsOn(fetchDependencies),
  instanceForm('beneficialOwners', {nested: DOCUMENT_TYPES}),
  withStyles(styles),
  provide(BeneficialOwnersContext),
)(Document)