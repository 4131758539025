import React, { Component } from 'react'
import qs from "qs";
import { withRouter } from 'react-router-dom'

const withQueryString = Type => withRouter(
  class extends Component {
    get urlState() {
      const queryString = window.location.search.slice(1)
      return qs.parse(queryString, { allowDots: true })
    }

    setUrlState = (state, callback) => {
      const updatedState = {...this.urlState, ...state}
      const queryString = qs.stringify(updatedState, {arrayFormat: 'comma', allowDots: true})
      this.props.history.replace(`${window.location.pathname}?${queryString}`)
      this.forceUpdate(callback)
    }

    render = () =>
      <Type {...this.props} urlState={this.urlState} setUrlState={this.setUrlState}/>
  }
)

export default withQueryString