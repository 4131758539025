import React, {Component} from 'react'
import {Authorization, deepSet, errorStringsFromError} from 'utils'

const instanceForm = (resourceName, {nested = [], linked = []} = {}) => (Type) =>
  class extends Component {
    state = {
      formAttributes: {}
    }

    get editMode(){
      return !this.createMode
    }

    get createMode(){
      return this.props.match.path.match(/\/new(?:\/:[^/]+)?$/)
    }

    get resource() {
      return this.props[resourceName].selected
    }

    get formData(){
      return {...this.resource, ...this.state.formAttributes}
    }

    get objectId(){
      return this.props.match.params.id
    }

    get attributesToSave(){
      const attributesToSave = {...this.formData, relationships: {}}
      linked.forEach(attribute => {
          const relationship = attributesToSave[attribute]
          delete attributesToSave[attribute]
          if(relationship){
            if(Array.isArray(relationship)){
              attributesToSave.relationships[attribute] = {data: relationship.map(({type, id}) => ({ type, id }))}
            }else{
              attributesToSave.relationships[attribute] = {data: {type: relationship.type, id: relationship.id}}
            }
          }
        }
      )
      nested.forEach(attribute => {
          const relationship = attributesToSave[attribute]
          delete attributesToSave[attribute]
          if(relationship)
            attributesToSave.relationships[attribute] = {data: relationship}
        }
      )
      return attributesToSave
    }

    handleFormObjectChange = (formAttributes, callback) => {
      this.setState({formAttributes}, typeof callback === 'function' ? callback : undefined)
    }

    get error(){
      return this.errors[this.editMode ? 'update' : 'create']
    }

    get errors(){
      return this.props[resourceName].errors
    }

    get actions(){
      return this.props[resourceName].actions
    }

    get formErrors(){
      const {meta} = this.error || {}
      if(meta){
        return Object.entries(meta).reduce((acc, [name, value]) => {
          return deepSet(value.join(', '), name, acc)
        }, {})
      }
      return null
    }

    handleSave = async (saveAction, {afterSave = this.afterSave, onSaveRedirect = this.onSaveRedirect, params = {}, mapFormData, showSnackbarSuccess = true} = {}) => {
      try{
        if(!(saveAction && saveAction instanceof Promise)){
          let attributesToSave = (typeof mapFormData === 'function') ? mapFormData(this.attributesToSave) : this.attributesToSave
          saveAction = this.editMode ?
            this.actions.update(attributesToSave, params) :
            this.actions.create(attributesToSave, params)
        }
        const result = await saveAction

        if(typeof afterSave === 'function') {
          afterSave(result)
        }

        let redirectTo = null
        if(onSaveRedirect) {
          redirectTo = (typeof onSaveRedirect === 'function') ? onSaveRedirect(result) : redirectTo = onSaveRedirect
        }

        if(redirectTo) {
          this.props.history.push(redirectTo)
        } else {
          if(window.location.pathname !== Authorization.store.savedLocation){
            this.props.history.goBack()
          }else{
            this.props.history.push('/')
            this.actions.tokens.clearSavedWindowLocation()
          }
        }

        this.props.snackbar && showSnackbarSuccess && this.props.snackbar.actions.show(`Saved ${this.attributesToSave.name ? `"${this.attributesToSave.name}"` : '' }`)

        return result
      }catch(err){
        this.props.snackbar && this.props.snackbar.actions.show(`Error saving: ${this.attributesToSave.name ? `"${this.attributesToSave.name}"` : '' }`)
        console.log(err)
        return err
      }
    }

    render = () => {
      return (
        <Type {...this.props}
              id={this.objectId}
              editMode={this.editMode}
              formData={this.formData}
              formAttributes={this.state.formAttributes}
              onFormDataChange={this.handleFormObjectChange}
              onSave={this.handleSave}
              errors={this.formErrors}
              errorStrings={errorStringsFromError(this.error)}
        />
      )
    }
  }

export default instanceForm