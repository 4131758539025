import React, { Component } from 'react'
import { compose } from 'utils'
import { consume, TokensContext } from 'contexts'
import { PageContainer } from "components";
import Typography from "@material-ui/core/Typography";
import { SessionStatus } from '../../constants'
import ErrorIcon from '@material-ui/icons/Error';
import withStyles from 'styles'
import Box from "@material-ui/core/Box";

class Auth extends Component {
  state = {
    unableToAuthenticate: false
  }

  async componentDidMount() {
    const token = this.props.match.params.token

    if (!token) {
      this.setState({ unableToAuthenticate: true })
      return
    }

    await this.props.tokens.actions.verify(false, token)
    if (this.props.tokens.loginState === SessionStatus.UNAUTHENTICATED) {
      this.setState({ unableToAuthenticate: true })
    } else {
      const hash = this.props.location.hash
      const newUrl = hash ? hash.substring(1) : "/"
      this.props.history.push(newUrl)
    }

  }

  render() {
    const { classes } = this.props
    if (this.state.unableToAuthenticate) {
      return (
        <PageContainer classes={{ content: classes.container }}>
          <Box className={this.props.classes.wrapper}>
            <ErrorIcon color="error" fontSize="large" />
            <Typography variant="h6" gutterBottom>
              Invalid secret url
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              The secret URL that you used to access this website appears to be expired or invalid.
            </Typography>
            <Typography variant="subtitle2">
              Please contact our onboarding specialist.
            </Typography>
          </Box>
        </PageContainer>
      )
    }

    return (
      <div>Loading...</div>
    )
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center'
  },
  container: {
    height: '90%'
  }
}

export default compose(
  consume(TokensContext),
  withStyles(styles),
)(Auth)

