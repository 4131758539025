import React, { Component } from 'react'
import { PageContainer } from "components"
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography"
import { compose } from "utils"
import withStyles from 'styles'
import TimerIcon from '@material-ui/icons/Timer';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import * as API from 'api'
import {provide, BeneficialOwnersContext} from "contexts"
import dependsOn from 'containers/shared/dependsOn'

class OnfidoWelcome extends Component {
  nextStep = async () => {
    try {
      await API.BeneficialOwners.update({
        id: this.props.beneficialOwners.selected.id,
        currentStep: 'onfido'
      })
    } finally {
      this.props.history.push('/verify-identity/onfido')
    }
  }

  render() {
    const { classes } = this.props
    return (
      <PageContainer classes={ {content: classes.cardContent} }>
        <Box>
          <Box className={classes.header}>
            <Typography variant="h4" gutterBottom>
              Verify your identity
            </Typography>
            <Box display="flex" alignItems="end">
              <TimerIcon className={classes.icon} />
              <Typography variant="subtitle2" gutterBottom>
                It will take less than 2 minutes
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box className={classes.steps}>
            <Box className={classes.step}>
              <Box className={classes.circledNumber}>1</Box>
              <Typography variant="body1">
                Take a picture of an identity document
              </Typography>
            </Box>
            <Box className={classes.step}>
              <Box className={classes.circledNumber}>2</Box>
              <Typography variant="body1">
                Record a selfie video
              </Typography>
            </Box>
          </Box>
        </Box>
        <Button color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
                onClick={this.nextStep}
        >
          Start
        </Button>
      </PageContainer>
    );
  }
}


const styles = {
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  icon: {
    marginRight: 5
  },
  header: {
    marginTop: 120,
    marginBottom: 20,
    '@media(min-width: 900px)': {
      marginTop: 'unset'
    }
  },
  steps: {
    marginTop: 20,
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  circledNumber: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
    border: "solid 1px #464DEB",
    borderRadius: '50%',
    width: 20,
    height: 20,
    marginRight: 10
  },
  button: {
    width: '100%',
  }
}

const fetchDependencies = ({ beneficialOwners }) => {
  return beneficialOwners.actions.current()
}

export default compose(
  withStyles(styles),
  dependsOn(fetchDependencies),
  provide(BeneficialOwnersContext),
)(OnfidoWelcome)

