import React, { Component } from 'react'
import { ControlledForm, ConfirmationDialog } from 'components'
import Button from '@material-ui/core/Button'
import withStyles from 'styles'

export class FilterDialog extends Component {
  static defaultProps = {
    dialogTitle: 'Filters'
  }

  constructor(props) {
    super(props)

    this.state = {
      filter: props.filter
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.setState({ filter: this.props.filter })
    }
  }

  get hasFiltersApplied() {
    return Object.values(this.props.filter).some(x => x && (!Array.isArray(x) || x.length !== 0))
  }

  handleChange = () => {
    this.props.onFilterChange(this.state.filter)
  }

  handleCloseFilters = () => {
    this.props.onClose()
  }

  handleFilterConfirm = () => {
    this.handleChange()
    this.handleCloseFilters()
  }

  handleFilterCancel = () => {
    this.setState({ filter: this.props.filter })
    this.handleCloseFilters()
  }

  handleFilterChanged = (filter) => {
    this.setState({ filter })
  }

  handleResetFilter = () => {
    this.setState({
      filter: Object.entries(this.state.filter).reduce((agg, [key, val]) => ({ ...agg, [key]: Array.isArray(val) ? [] : undefined }), {})
    })
  }

  render = () => {
    const { dialogTitle, classes, children, open } = this.props
    const { filter } = this.state
    return (
      <ConfirmationDialog
        title={dialogTitle}
        open={open}
        onConfirm={this.handleFilterConfirm}
        onCancel={this.handleFilterCancel}
        confirmLabel={'Filter'}
        cancelLabel={'Cancel'}
      >
        <ControlledForm onChange={this.handleFilterChanged} data={filter}>
          <div className={classes.form}>
            {children}
          </div>
          <Button onClick={this.handleResetFilter}>Reset Filters</Button>
        </ControlledForm>
      </ConfirmationDialog>
    )
  }
}

const styles = {
  form: {
    paddingBottom: 20,
    '& h6': {
      marginTop: 20
    }
  }
}

export default (withStyles(styles)(FilterDialog))
