import jwt_decode from 'jwt-decode'

class TokenStore{
  set auth(value){
    global.localStorage.auth = value || ''
  }

  get auth(){
    return global.localStorage.auth
  }

  set refresh(value){
    global.localStorage.refresh = value || ''
  }

  get refresh(){
    return global.localStorage.refresh
  }

  set sessionAuth(value){
    global.sessionStorage.auth = value || ''
  }

  get sessionAuth(){
    return global.sessionStorage.auth
  }

  set sessionRefresh(value){
    global.sessionStorage.refresh = value || ''
  }

  get sessionRefresh(){
    return global.sessionStorage.refresh
  }

  protected = endpoint => {
    endpoint.headers(headers => {
      if(this.sessionAuth || this.auth) {
        headers.Authorization = `Bearer ${this.sessionAuth || this.auth}`
        headers['X-Authorization'] = `Bearer ${this.sessionAuth || this.auth}`
      }
      return headers
    })

    endpoint.resultsHandler(result => {
      if (result && result.data && result.data.type === 'credentials' && result.data.attributes
          && !result.data.attributes.auth && !result.data.attributes.refresh) {
        // Handle logout
        this.sessionAuth = null
        this.sessionRefresh = null
        this.auth = null
        this.refresh = null
      } else if(result && result.data && result.data.type === 'credentials' && result.data.attributes){
        const { actor } = jwt_decode(result.data.attributes.auth)
        if(actor === 'beneficial_owner' || actor === 'verification_user'){
          this.sessionAuth    = result.data.attributes.auth
          this.sessionRefresh = result.data.attributes.refresh
        }else{
          this.auth    = result.data.attributes.auth
          this.refresh = result.data.attributes.refresh
        }
      }
      return result
    })
    return endpoint
  }
}

const TokenStoreSingleton = new TokenStore()
const ProtectResourceDecorator = TokenStoreSingleton.protected.bind(TokenStoreSingleton)
export { TokenStoreSingleton as TokenStore, ProtectResourceDecorator }
