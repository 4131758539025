import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import dependsOn from 'containers/shared/dependsOn'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { PageContainer } from 'components'
import { provide, TenantsContext } from 'contexts'
import { compose } from 'utils'
import withStyles from 'styles'

export class Show extends Component{

  get tenant(){
    return this.props.tenants.selected
  }

  render = () =>
    <PageContainer>
      <Typography variant='h4'>Tenant - {this.tenant.name}</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
            <TableCell>
              <Link to={`/tenants/${this.tenant.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(this.tenant.name || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Token issuer</TableCell>
              <TableCell>{String(this.tenant.tokenIssuer || '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Public key</TableCell>
              <TableCell className={this.props.classes.publicKey}>
                {String(this.tenant.publicKey || '')}
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>
      <br/>
    </PageContainer>
}

const styles = {
  publicKey: {
    whiteSpace: 'pre-line'
  }
}

const fetchDependencies = ({tenants,  match: { params: { id }}}) => {
  return tenants.actions.show(id, {
    include: ''
  })
}

export default compose(
  dependsOn(fetchDependencies),
  withStyles(styles),
  provide(TenantsContext),
)(Show)