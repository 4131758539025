import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { compose, capitalize } from 'utils'
import { consume, SnackbarContext } from "contexts";
import { ControlledForm, ConfirmationDialog } from "components";
import ClearIcon from "@material-ui/icons/Clear";
import * as API from 'api'
import { withRouter } from "react-router-dom";
import withStyles from 'styles'

class RejectReviewButton extends Component {
  state = {
    dialogOpen: false,
    formData: {}
  }

  handleDataChange = ( formData ) => {
    this.setState({ formData })
  }

  handleReject = async () => {
    await API.Verifications.event({
      reason: this.state.formData.reason,
      name: this.props.eventName,
      id: this.props.verificationId
    })
    this.props.snackbar.actions.show(`Verification ${capitalize(this.props.actionName)}ed`)
    this.props.history.push("/verifications")
  }

  render() {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          startIcon={this.props.icon || <ClearIcon />}
          onClick={() => this.setState({dialogOpen: true})}
        >
          {capitalize(this.props.actionName)}
        </Button>
        <ConfirmationDialog
          title={`${capitalize(this.props.actionName)} Verification`}
          open={this.state.dialogOpen}
          onConfirm={this.handleReject}
          onCancel={() => this.setState({dialogOpen: false})}
          confirmLabel={capitalize(this.props.actionName)}
          cancelLabel='Back'
          canConfirm={Boolean(this.state.formData.reason)}
          classes={this.props.classes}
        >
          <ControlledForm data={this.state.formData} onChange={this.handleDataChange}>
            <Typography variant="body1">Please provide a reason:</Typography>
            <TextField name="reason" label="Reason" fullWidth />
          </ControlledForm>
        </ConfirmationDialog>
      </>
    );
  }
}

const styles = {
  content: {
    minWidth: 300
  }
}

export default compose(
  consume(SnackbarContext),
    withStyles(styles),
  withRouter
)(RejectReviewButton)
