import React, { Component } from 'react'
import { provide, consume, SnackbarContext, AuditsContext } from 'contexts'
import TextField from '@material-ui/core/TextField/TextField'
import Typography from '@material-ui/core/Typography'
import { compose, humanize, userFriendlyDate } from 'utils'
import indexView, {hasIndexChanged} from 'containers/shared/indexView'
import dependsOn from 'containers/shared/dependsOn'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import DatePicker from 'components/DatePicker'
import moment from 'moment'

import {
  Pagination,
  SearchCombo,
  MultiSelect,
  LinkButton,
  ConfirmationDialog,
  PageContainer
} from 'components'
import withStyles from 'styles'

export class List extends Component {
  state = {
    page: 1
  }

  get audits() {
    return this.props.audits.list
  }

  get filter() {
    const { filter, filter: { event } } = this.props
    return { ...filter, event: (!event || Array.isArray(event)) ? event : [event] }
  }

  get changeset() {
    const changes = (this.state.showAudit && this.state.showAudit.changeset) || {}
    return Object.keys(changes).map(k => ({ field: k, from: changes[k][0], to: changes[k][1] }))
  }

  handlePageSelected = page => {
    this.props.onPageChange(page, this.props.onDependencyUpdate)
  }

  handleShowAudit = (audit) => () => {
    this.setState({ showAudit: audit })
  }

  humanizeValue(value) {
    if (value && isNaN(value) && moment(value).isValid()) {
      return userFriendlyDate(value)
    }
    else {
      return value
    }
  }

  handleFilterChange = (filter) => {
    this.props.onFilterChange(filter, this.props.onDependencyUpdate)
  }

  renderAudit = audit =>
    <TableRow key={audit.id}>
      <TableCell>{humanize(audit.event)}</TableCell>
      <TableCell >{audit.itemType}</TableCell>
      <TableCell >
        <LinkButton onClick={this.handleShowAudit(audit)} disabled={Object.keys(audit.changeset || {}).length === 0}>
          {audit.itemId}
        </LinkButton>
      </TableCell>
      <TableCell>{userFriendlyDate(audit.createdAt)}</TableCell>
    </TableRow>

  render = () => {
    const everyEvent = ['create', 'update', 'delete']
    const { classes } = this.props
    const searchCombo = (
      <SearchCombo onFilterChange={this.handleFilterChange} filter={this.filter} searchTextMember={'freeText'}>
        <div>
          <TextField label='Entity' name='item_type' fullWidth={false} />
          <TextField label='ID' name='item_id' fullWidth={false} />
        </div>
        <div>
          <DatePicker clearable label='Start Time' name='createdAtDateAfter' />
          <DatePicker clearable label='End Time' name='createdAtDateBefore' />
        </div>
        <div>
          <Typography variant="subtitle1">Events</Typography>
          <MultiSelect name="event" options={everyEvent} defaultOptions={everyEvent} unsetWhenDefault />
        </div>
      </SearchCombo>
    )

    return (
      <PageContainer>
        <Typography variant='h4'>Audit Logs</Typography>
        <br />
        <Pagination totalPages={this.props.audits.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={searchCombo} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Entity</TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Create Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.audits.map(audit => this.renderAudit(audit))}
          </TableBody>
        </Table>
        <ConfirmationDialog title="Changes" onConfirm={this.handleShowAudit(null)} open={!!this.state.showAudit}
          canCancel={false} maxWidth={'lg'}
        >
          <div className={classes.changes}>
            <Table breakpoint={400} className={classes.changes}>
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Old Value</TableCell>
                  <TableCell>New Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.changeset.map(change =>
                  <TableRow key={change.field}>
                    <TableCell >{humanize(change.field)}</TableCell>
                    <TableCell >{this.humanizeValue(change.from)}</TableCell>
                    <TableCell >{this.humanizeValue(change.to)}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </ConfirmationDialog>
      </PageContainer>
    )
  }
}

const styles = (theme) => ({
  changes: {
    overflow: 'auto'
  },
  cardContent: {
    maxWidth: '80vw',
    minWidth: 600
  }
})

const fetchDependencies = ({ audits, filter, page }) => {
  return audits.actions.index({
    page,
    filter
  })
}

export default compose(
  dependsOn(fetchDependencies, hasIndexChanged),
  withStyles(styles),
  indexView('audits'),
  provide(AuditsContext),
  consume(SnackbarContext),
)(List)